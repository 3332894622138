import { createRouter, createWebHistory } from "vue-router";

import NotFoundView from "../views/NotFoundView.vue";
import LoginView from "../views/auth/LoginView.vue";
import HomeView from "../views/HomeView.vue";
// import { initializeApp } from "firebase/app"; //firebase
// import { getMessaging, getToken, onMessage } from "firebase/messaging"; // firebase
// import UserView from "../views/pegawai/UserView.vue";
import TahunAjaranView from "../views/pegawai/TahunAjaranView.vue";
import MataKuliahView from "../views/pegawai/MataKuliahView.vue";
import PegawaiView from "../views/pegawai/PegawaiView.vue";
import DosenView from "../views/pegawai/DosenView.vue";
import MahasiswaView from "../views/pegawai/MahasiswaView.vue";
import MahasiswaWaliView from "../views/pegawai/MahasiswaWaliView.vue";
import AdminView from "../views/pegawai/AdminView.vue";
import TestView from "../views/TestView.vue";
// import TestView2 from "../views/TestView2.vue";
import KurikulumView from "../views/pegawai/KurikulumView.vue";
import FakultasView from "../views/pegawai/FakultasView.vue";
import ProdiView from "../views/pegawai/ProdiView.vue";
import JabatanView from "../views/pegawai/JabatanView.vue";
import RuanganView from "../views/pegawai/RuanganView.vue";
import BidangView from "../views/pegawai/BidangView.vue";
import PenelitianDosenView from "../views/pegawai/PenelitianDosenView.vue";
// import PenelitianPegawaiView from "../views/pegawai/PenelitianPegawaiView.vue";
import JadwalKuliahView from "../views/pegawai/JadwalKuliahView.vue";
import JadwalRemedialView from "../views/pegawai/JadwalRemedialView.vue";
import JadwalSemesterPendekView from "../views/pegawai/JadwalSemesterPendekView.vue";

import JamKuliahView from "../views/pegawai/JamKuliahView.vue";
import waktuKuliahView from "../views/pegawai/WaktuKuliahView.vue";
import kelasView from "../views/pegawai/KelasView.vue";
import semesterView from "../views/pegawai/SemesterView.vue";
import golonganView from "../views/pegawai/GolonganView.vue";
import jenisMataKuliahPilihanView from "../views/pegawai/JenisMataKuliahPilihanView.vue";
import StambukView from "../views/pegawai/StambukView.vue";
import pemetaanKelasView from "../views/pegawai/PemetaanKelasView.vue";
import krsView from "../views/pegawai/KrsView.vue";
import menuAksesView from "../views/pegawai/MenuAksesView.vue";
// import jadwalMengajarView from "../views/pegawai/JadwalMengajarView.vue";
// import jadwalMengajarQRView from "../views/pegawai/JadwalMengajarQRView.vue";
import jadwalMengajarQR2View from "../views/pegawai/JadwalMengajarQR2View.vue";
import penilaianView from "../views/pegawai/PenilaianView.vue";
// import MataKuliahUmumView from "../views/pegawai/MataKuliahViewUmum.vue";
import profileView from "../views/ProfileView.vue";
import kalenderAkademikView from "../views/pegawai/KalenderAkademikView.vue";
import kelolaBeritaView from "../views/pegawai/KelolaBeritaView.vue";
import laporanView from "../views/pegawai/laporan/laporanView.vue";
import tagihanView from "../views/pegawai/TagihanView.vue";
import beritaAcaraDosenTidakHadirView from "../views/pegawai/laporan/beritaAcaraDosenTidakHadirView.vue";
import jadwalUjianView from "../views/pegawai/JadwalUjianView.vue";
import jadwalMengawasView from "../views/pegawai/JadwalMengawasView.vue";
import jadwalMengawasRemedialView from "../views/pegawai/JadwalMengawasRemedialView.vue";

import kuesionerView from "../views/pegawai/KuesionerView.vue";
import isiKuesionerView from "../views/pegawai/IsiKuesionerView.vue";
import pollingView from "../views/pegawai/PollingView.vue";
import pindahWaktuKuliahView from "../views/pegawai/PindahWaktuKuliahView.vue";
import suratAktifKuliahView from "../views/pegawai/SuratAktifKuliahView.vue";
import suratPengunduranDiriView from "../views/pegawai/SuratPengunduranDiriView.vue";
import NotifikasiView from "../views/pegawai/NotifikasiView.vue";
import scoreMahasiswaView from "../views/pegawai/ScoreMahasiswaView.vue";
import InvoieceView from "../views/pegawai/InvoiceView.vue";
import SuratPengajuanCutiView from "@/views/pegawai/SuratPengajuanCutiView.vue";

// import { AlertInfoTopRight } from "@/assets/javascript/function"; //firebase
import Cookies from "js-cookie";

import axios from "axios";
import {
  ErrorConnectionTimeOut,
  AlertPopup,
  base_url,
  ShowLoading,
  CloseLoading,
} from "@/assets/javascript/function";

const routes = [
  //auth
  {
    path: "/",
    name: "login",
    component: LoginView,
  },
  {
    path: "/mhs/dashboard",
    name: "mhsDashboard",
  },
  {
    path: "/notFound",
    name: "notFound",
    component: NotFoundView,
  },
  {
    path: "/home",
    name: "Home",
    meta: { requiresAuth: true },
    component: HomeView,
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { requiresAuth: true },
    component: profileView,
  },

  {
    path: "/tahun_ajaran",
    name: "tahun_ajaran",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: TahunAjaranView,
  },
  {
    path: "/stambuk",
    name: "stambuk",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: StambukView,
  },
  {
    path: "/mata_kuliah",
    name: "mata_kuliah",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: MataKuliahView,
  },

  // {
  //   path: "/mata_kuliah_umum",
  //   name: "mata_kuliah_umum",
  //   meta: { requiresAuth: true, requiresMenuAkses: true },
  //   component: MataKuliahUmumView,
  // },
  {
    path: "/pegawai_pegawai",
    name: "pegawai",
    meta: { requiresAuth: true },
    component: PegawaiView,
  },
  {
    path: "/dosen",
    name: "dosen",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: DosenView,
  },
  {
    path: "/mahasiswa",
    name: "mahasiswa",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: MahasiswaView,
  },
  {
    path: "/mahasiswa_wali",
    name: "mahasiswa_wali",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: MahasiswaWaliView,
  },
  {
    path: "/admin",
    name: "admin",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: AdminView,
  },
  {
    path: "/kurikulum",
    name: "kurikulum",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: KurikulumView,
  },
  {
    path: "/fakultas",
    name: "fakultas",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: FakultasView,
  },
  {
    path: "/prodi",
    name: "prodi",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: ProdiView,
  },
  {
    path: "/jabatan",
    name: "jabatan",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: JabatanView,
  },
  {
    path: "/ruangan",
    name: "ruangan",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: RuanganView,
  },
  {
    path: "/bidang",
    name: "bidang",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: BidangView,
  },
  {
    path: "/pemetaan_kelas",
    name: "pemetaan_kelas",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: pemetaanKelasView,
  },
  {
    path: "/jadwal_mengajar",
    name: "jadwal_mengajar",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: jadwalMengajarQR2View,
  },
  {
    path: "/jadwal_mengajar_qr",
    name: "jadwal_mengajar_qr",
    meta: { requiresAuth: true, requiresMenuAkses: false },
    component: jadwalMengajarQR2View,
  },
  {
    path: "/jadwal_mengajar_qr_2",
    name: "jadwal_mengajar_qr_2",
    meta: { requiresAuth: true, requiresMenuAkses: false },
    component: jadwalMengajarQR2View,
  },
  {
    path: "/penilaian",
    name: "penilaian",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: penilaianView,
  },
  {
    path: "/test",
    name: "test",
    component: TestView,
  },
  {
    path: "/penelitian_dosen",
    name: "penelitian_dosen",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: PenelitianDosenView,
  },
  {
    path: "/jadwal_kuliah",
    name: "jadwal_kuliah",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: JadwalKuliahView,
  },
  {
    path: "/jam_kuliah",
    name: "jam_kuliah",
    meta: { requiresAuth: true },
    component: JamKuliahView,
  },
  {
    path: "/waktu_kuliah",
    name: "waktu_kuliah",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: waktuKuliahView,
  },
  {
    path: "/kelas",
    name: "kelas",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: kelasView,
  },
  {
    path: "/semester",
    name: "semester",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: semesterView,
  },

  {
    path: "/golongan",
    name: "golongan",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: golonganView,
  },
  {
    path: "/jenis_mata_kuliah_pilihan",
    name: "jenis_mata_kuliah_pilihan",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: jenisMataKuliahPilihanView,
  },
  {
    path: "/krs",
    name: "krs",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: krsView,
  },
  {
    path: "/tagihan",
    name: "tagihan",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: tagihanView,
  },
  {
    path: "/view_invoice",
    name: "view_invoice",
    meta: { requiresAuth: true, requiresMenuAkses: false },
    component: InvoieceView,
  },
  {
    path: "/menu_akses",
    name: "menu_akses",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: menuAksesView,
  },
  {
    path: "/kalender_akademik",
    name: "kalender_akademik",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: kalenderAkademikView,
  },
  {
    path: "/kelola_berita",
    name: "kelola_berita",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: kelolaBeritaView,
  },
  {
    path: "/laporan",
    name: "laporan",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: laporanView,
  },
  {
    path: "/berita_acara_dosen_tidak_hadir",
    name: "berita_acara_dosen_tidak_hadir",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: beritaAcaraDosenTidakHadirView,
  },
  {
    path: "/jadwal_ujian",
    name: "jadwal_ujian",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: jadwalUjianView,
  },
  {
    path: "/jadwal_remedial",
    name: "jadwal_remedial",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: JadwalRemedialView,
  },
  {
    path: "/semester_pendek",
    name: "semester_pendek",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: JadwalSemesterPendekView,
  },
  {
    path: "/jadwal_mengawas",
    name: "jadwal_mengawas",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: jadwalMengawasView,
  },
  {
    path: "/jadwal_mengawas_remedial",
    name: "jadwal_mengawas_remedial",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: jadwalMengawasRemedialView,
  },
  {
    path: "/kuesioner",
    name: "kuesioner",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: kuesionerView,
  },
  {
    path: "/isi_kuesioner",
    name: "isi_kuesioner",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: isiKuesionerView,
  },
  {
    path: "/polling",
    name: "polling",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: pollingView,
  },
  {
    path: "/pindah_waktu_kuliah",
    name: "pindah_waktu_kuliah",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: pindahWaktuKuliahView,
  },
  {
    path: "/notifikasi",
    name: "notifikasi",
    meta: { requiresAuth: true },
    component: NotifikasiView,
  },
  {
    path: "/score_mahasiswa",
    name: "score_mahasiswa",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: scoreMahasiswaView,
  },
  {
    path: "/surat_aktif_kuliah",
    name: "surat_aktif_kuliah",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: suratAktifKuliahView,
  },
  {
    path: "/surat_pengunduran_diri",
    name: "surat_pengunduran_diri",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: suratPengunduranDiriView,
  },
  {
    path: "/surat_pengajuan_cuti",
    name: "surat_pengajuan_cuti",
    meta: { requiresAuth: true, requiresMenuAkses: true },
    component: SuratPengajuanCutiView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: "history",
  // base: "/portal_akademik/",
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  ShowLoading();
  localStorage.setItem("tipe_user", "");
  localStorage.setItem("nama_user", "");
  localStorage.setItem("profile_picture", "");
  const token = Cookies.get("token");

  if (to.name == "login") {
    // -- firebase
    // const firebaseConfig = {
    //   apiKey: "AIzaSyA25t0zJZI9Nm-pgSX2c-edgazqLa7A2So",
    //   authDomain: "stbhinneka-3078f.firebaseapp.com",
    //   projectId: "stbhinneka-3078f",
    //   storageBucket: "stbhinneka-3078f.appspot.com",
    //   messagingSenderId: "420158893086",
    //   appId: "1:420158893086:web:8bfe5097840ee0e2d4d20f",
    //   measurementId: "G-S5DB0XPB6H",
    // };
    // const messaging = getMessaging(initializeApp(firebaseConfig));
    // Notification.requestPermission().then((permission) => {
    //   if (permission === "granted") {
    //     console.log("Notification permission granted.");
    //   } else {
    //     console.warn("Izin notifikasi tidak diberikan.");
    //   }
    // });
    // onMessage(messaging, (payload) => {
    //   // console.log("Message received. ", payload);
    //   AlertInfoTopRight(payload.notification.title, payload.notification.body);
    //   localStorage.setItem(
    //     "notifSum",
    //     parseInt(localStorage.getItem("notifSum")) + 1
    //   );
    // });
    // getToken(messaging, {
    //   vapidKey:
    //     "BEw-dDrNI77W-KJswnV_z54YhWrFkMNCiuxXiZjaWqP9B3fe_KEOL-EMU5ZohJAxJoO9jBalJy4jTFUQclTQzoY",
    // })
    //   .then((currentToken) => {
    //     if (currentToken) {
    //       // Send the token to your server and update the UI if necessary
    //       console.log("token is :" + currentToken);
    //       localStorage.setItem("token_firebase", currentToken);
    //       // ...
    //     } else {
    //       // Show permission request UI
    //       console.log(
    //         "No registration token available. Request permission to generate one."
    //       );
    //       // ...
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("An error occurred while retrieving token. ", err);
    //     // window.location.reload();
    //     // ...
    //   });
  } else {
    // -- firebase
    // const firebaseConfig = {
    //   apiKey: "AIzaSyA25t0zJZI9Nm-pgSX2c-edgazqLa7A2So",
    //   authDomain: "stbhinneka-3078f.firebaseapp.com",
    //   projectId: "stbhinneka-3078f",
    //   storageBucket: "stbhinneka-3078f.appspot.com",
    //   messagingSenderId: "420158893086",
    //   appId: "1:420158893086:web:8bfe5097840ee0e2d4d20f",
    //   measurementId: "G-S5DB0XPB6H",
    // };
    // const messaging = getMessaging(initializeApp(firebaseConfig));
    // Notification.requestPermission().then((permission) => {
    //   if (permission === "granted") {
    //     console.log("Notification permission granted.");
    //   } else {
    //     console.warn("Izin notifikasi tidak diberikan.");
    //   }
    // });
    // onMessage(messaging, (payload) => {
    //   AlertInfoTopRight(payload.notification.title, payload.notification.body);
    //   localStorage.setItem(
    //     "notifSum",
    //     parseInt(localStorage.getItem("notifSum")) + 1
    //   );
    // });
  }

  if (
    !to.matched.some((route) => route.meta.requiresAuth) ||
    token == "" ||
    token == undefined
  ) {
    CloseLoading();
    if (!to.matched.some((route) => route.meta.requiresAuth)) {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    let formData = new FormData();
    const config = {
      headers: { Authorization: `Bearer ` + Cookies.get("token") },
      timeout: 0,
    };

    if (Cookies.get("token") == "") {
      CloseLoading();
      next();
    } else {
      const api = base_url + "profile_detail";
      axios
        .post(api, formData, config)
        .then((response) => {
          if (response.data.status == true) {
            let qr = response.data.status_qr;
            localStorage.setItem("tipe_user", response.data.tipe);
            localStorage.setItem("nama_user", response.data.data);
            localStorage.setItem(
              "profile_picture",
              response.data.profile_picture
            );
            localStorage.setItem(
              "menu",
              JSON.stringify(response.data.menu.map((obj) => obj.menu))
            );
            localStorage.setItem(
              "menu_akses",
              JSON.stringify(response.data.menu)
            );
            if (
              response.data.tipe == "dosen" ||
              response.data.tipe == "pegawai"
            ) {
              if (to.matched.some((route) => route.meta.requiresMenuAkses)) {
                if (
                  JSON.parse(localStorage.getItem("menu")).includes(to.name) ==
                  true
                ) {
                  if (to.fullPath == "/jadwal_mengajar" && qr == 1) {
                    next({ name: "jadwal_mengajar_qr" });
                  } else if (to.fullPath == "/jadwal_mengajar" && qr == 2) {
                    next({ name: "jadwal_mengajar_qr_2" });
                  } else {
                    next();
                  }
                } else {
                  if (
                    to.fullPath == "/berita_acara_dosen_tidak_hadir" &&
                    localStorage.getItem("menu").includes("laporan")
                  ) {
                    next();
                  } else if (to.fullPath == "/jadwal_mengajar_qr" && qr == 1) {
                    next({ name: "jadwal_mengajar_qr" });
                  } else if (to.fullPath == "/jadwal_mengajar_qr" && qr == 2) {
                    next({ name: "jadwal_mengajar_qr_2" });
                  } else {
                    next({ name: "Home" });
                  }
                }
              } else {
                if (to.fullPath == "/jadwal_mengajar_qr" && qr == 0) {
                  next({ name: "jadwal_mengajar" });
                } else {
                  next();
                }
              }
            } else {
              next();
            }
            CloseLoading();
          } else {
            if (response.data.message == "No Authorization") {
              next({ name: "login" });
            } else {
              AlertPopup("error", "", response.data.message, 1500, false);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          ErrorConnectionTimeOut(error);
        });
    }
  }
});

export default router;
