<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                Kelola Berita
            </div>
            <div class="d-flex flex-column gap-2 mt-2">
                <!-- <div class="fs-8 fw-bold font-custom-2 font-gray-custom">
                        >Desktop/Pengguna
                    </div> -->

                <div class="row me-0">
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="ditujukan_kepada_filter"
                            id="ditujukan_kepada_filter" v-model="ditujukanKepadaFilter" @change="index()">
                            <option value="" selected disabled>- Pilih Ditujukan Kepada</option>
                            <option v-for="list in ditujukanKepadaList" :key="list.nama" :value="list.nama">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-8 text-center">
                        <div class="d-flex justify-content-center  align-items-center">
                            <div>
                                <i class='bx bx-chevron-left fs-7' @click="index_before_page()"
                                    style="cursor: pointer;"></i>
                            </div>
                            <div class="fs-7">
                                {{ numberPage }}/{{ totalPages }}
                            </div>
                            <div>
                                <i class='bx bx-chevron-right fs-7' @click="index_next_page(previousLastId, NextLastId)"
                                    style="cursor: pointer;"></i>
                            </div>
                        </div>
                    </div>

                    <div class="col-2 text-end ">
                        <button v-if="create_akses == true"
                            class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 " style="border-radius: 5px;"
                            @click="showModal2('modal_tambah')">
                            <img src="@/assets/icon/data_plus.png" alt="" width="15">
                            Tambah Berita
                        </button>
                    </div>
                </div>

                <div class="border mt-1 rounded-3 me-2 font-custom-1">
                    <table class="table mb-0 bg-transparent table-borderless " style="">
                        <thead>
                            <tr>
                                <th scope="col" class="bg-transparent fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7">Judul</th>
                                <th scope="col" class="font-custom-1 fs-7">Dibuat Oleh</th>
                                <th scope="col" class="font-custom-1 fs-7">Tanggal dibuat</th>
                                <th scope="col" class="bg-transparent  fs-7">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in records" :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ record.judul }}</td>
                                <td>{{ record.nama_lengkap }} ({{ record.tipe }})</td>
                                <td>{{ DateFormat(record.created_at) }}</td>
                                <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                            v-on:click="editRecord(record)"><i
                                                class='bx bxs-message-square-edit fs-8'></i></button>
                                        <button v-if="delete_akses == true" class="btn btn-danger btn-sm fs-8"
                                            v-on:click="deleteRecord(record.id)"><i
                                                class='bx bxs-trash-alt fs-8'></i></button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>

    </div>
    <form @submit.prevent="insert()">
        <div class="modal fade" id="modal_tambah" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_tambah">

            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah Berita</h5>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_tambah')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nama" class="form-label">Judul</label>
                            <input type="text" class="form-control" v-model="newRecord.judul" required />
                        </div>
                        <div class="mb-3">
                            <label for="nama" class="form-label">Gambar</label>
                            <input type="file" accept="image/png, image/jpeg" class="form-control" ref="gambar" />
                        </div>
                        <div class="mb-3">
                            <label for="fakultas" class="form-label">Ditujukan kepada</label>
                            <select class="form-select" aria-label="Default select example" name="ditujukan_kepada"
                                id="ditujukan_kepada" v-model="newRecord.ditujukan_kepada" required>
                                <option value="" selected disabled>- Pilih ditujukan kepada</option>
                                <option v-for="list in ditujukanKepadaList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="nama" class="form-label">Isi</label>
                            <Editor api-key="01hjrmvp8caessqt3d5sgjy7o603r4a2yhxwux7tj3ri9095" :init="{
                                plugins: 'lists link image table code help wordcount',
                            }" v-model="newRecord.isi" />
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan</button>
                    </div>

                </div>

            </div>

        </div>
    </form>
    <form @submit.prevent="update">
        <div class="modal fade" id="modal_edit" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_edit">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Berita</h5>
                        <!-- <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_edit"></button> -->
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_edit')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nama" class="form-label">Judul</label>
                            <input type="text" class="form-control" v-model="currentRecord.judul" required />
                        </div>
                        <div class="" v-if="currentRecord.gambar != null || currentRecord.gambar != ''">
                            <img :src="currentRecord.gambar" alt=""
                                style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                class=" mb-2 border border-3" @click="openInNewTab(currentRecord.gambar)">
                        </div>
                        <div class="mb-3">
                            <label for="nama" class="form-label">Gambar</label>
                            <input type="file" accept="image/png, image/jpeg" class="form-control"
                                ref="gambar_update" />
                        </div>
                        <div class="mb-3">
                            <label for="fakultas" class="form-label">Ditujukan kepada</label>
                            <select class="form-select" aria-label="Default select example" name="ditujukan_kepada"
                                id="ditujukan_kepada" v-model="currentRecord.ditujukan_kepada" required>
                                <option value="" selected disabled>- Pilih ditujukan kepada</option>
                                <option v-for="    list in ditujukanKepadaList    " :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="nama" class="form-label">Isi</label>
                            <Editor api-key="01hjrmvp8caessqt3d5sgjy7o603r4a2yhxwux7tj3ri9095" :init="{
                                plugins: 'lists link image table code help wordcount',
                            }
                                " v-model="currentRecord.isi" />
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_edit', 'close_modal_edit')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>

                    </div>


                </div>

            </div>
        </div>
    </form>
    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <!-- <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete"></button> -->
                    <button type="button " class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideModal2('modal_delete')">Tidak, tetap
                        simpan
                        disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.tox .tox-statusbar__branding svg {
    display: none !important;
}



.tox-notifications-container {
    display: none !important;
}

.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, base_url, CloseLoading, ShowLoading, menu_akses_cek, ErrorConnectionTimeOut, AlertBottom, CharAndNumberOnly, DateFormat } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";
import Editor from '@tinymce/tinymce-vue';

export default {
    data() {
        return {
            //data
            url: 'kelola_berita',
            api: "",

            //pages
            limit: "15",
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,

            ditujukanKepadaList: [
                {
                    nama: "dosen"
                },
                {
                    nama: "pegawai"
                },
                {
                    nama: "mahasiswa"
                }
            ],
            ditujukanKepadaFilter: "",

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            records: [],
            newRecord: { judul: "", isi: "", ditujukan_kepada: "", gambar: "", },
            currentRecord: {},

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            intervalId: "",
            intervalId2: "",

            editorConfig: {
                plugins: 'lists link image table code help wordcount',
                toolbar: 'undo redo | formatselect | bold italic link image | table | code | help',
                // Additional configuration options can be added as needed.
            },
        }
    },
    components: {
        SideNavbar, Editor
    },

    methods: {
        CharAndNumberOnly,
        DateFormat,
        index() {
            this.records = [];
            this.prodiList = [];
            ShowLoading();
            let formData = new FormData();

            formData.append("previous_id", '');
            formData.append("last_id", '');
            formData.append("limit", this.limit);
            formData.append("ditujukan_kepada", this.ditujukanKepadaFilter);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status == true) {

                        if (response.data.data != undefined) {
                            this.records = response.data.data[1];

                            this.totalPages = response.data.data[0][0].number_page;
                            this.previousLastId = response.data.data[1].id;
                            this.previousLastIdOld = response.data.data[1].id;
                            let last_index = Object.keys(response.data.data[0]).pop();

                            this.NextLastId = response.data.data[1][last_index].id;
                            this.NextLastIdOld = response.data.data[1][last_index].id;
                            this.arrayLastIdOld.push(response.data.data[1][last_index].id);
                            // this.arrayPreviousIdOld.push(response.data.data[1][0].id);

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        index_next_page(param1, param2) {

            ShowLoading();
            let formData = new FormData();
            formData.append("previous_id", "");
            formData.append("last_id", param2);
            formData.append("limit", this.limit);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.totalPages = response.data.data[0][0].number_page;
                        this.arrayLastIdOld.push(param2);
                        this.arrayPreviousIdOld.push(param1);

                        this.records = response.data.data[1];
                        let last_index = Object.keys(response.data.data[0]).pop();

                        this.NextLastId = response.data.data[1][last_index].id; // id 33
                        this.previousLastId = response.data.data[1][0].id; // 7
                        this.numberPage = this.numberPage + 1;
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        index_before_page() {

            let last_index = this.arrayLastIdOld.length;
            last_index = last_index - 1;
            ShowLoading();
            let formData = new FormData();
            formData.append("url", "ra_admin_verifikasi_page");
            formData.append("previous_id", this.arrayPreviousIdOld[last_index]);
            formData.append("last_id", "");
            formData.append("limit", this.limit);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.totalPages = response.data.data[0][0].number_page;

                        this.records = response.data.data[1];
                        let last_index = Object.keys(response.data.data[1]).pop();
                        this.NextLastId = response.data.data[1][last_index].id;
                        this.previousLastId = response.data.data[1][0].id;

                        if (this.arrayLastIdOld.length != 1) {
                            this.arrayLastIdOld.pop();
                            this.arrayPreviousIdOld.pop();
                            this.numberPage = this.numberPage - 1;
                        }

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        insert() {
            ShowLoading();
            const formData = new FormData();
            formData.append("gambar", this.$refs.gambar.files[0]);

            Object.entries(this.newRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_insert";
            } else {
                this.api = base_url + this.url + "_insert";
            }



            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.records.unshift(response.data.data);
                        // Clear the form inputs
                        this.newRecord = {

                        };
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_tambah')
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.showModal2('modal_edit');

            // this.setRecordwithId('prodi', this.prodiList, 'nama', 'id');
            // this.setRecordwithId('dosen_wali', this.dosenWaliList, 'nama', 'id');
            // this.setRecordwithId('stambuk', this.stambukList, 'nama', 'id');
            // this.setRecordwithId('waktu_kuliah', this.waktuKuliahList, 'nama', 'id');

        },
        setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd) {
            if (this.currentRecord[field] != null) {
                const index = arraylist.findIndex((record) => record[fieldNameArray] == this.currentRecord[field]);
                this.currentRecord[field] = arraylist[index][valueWantAdd];
            } else {
                this.currentRecord[field] = "";
            }
        },
        update() {
            ShowLoading();
            const formData = new FormData();

            if (this.$refs.gambar_update.files[0] != undefined) {
                formData.append("link_gambar", this.currentRecord.gambar);
                formData.append("gambar", this.$refs.gambar_update.files[0]);
                delete this.currentRecord.gambar;

            } else {
                formData.append("gambar", "");
                formData.append("link_gambar", this.currentRecord.gambar);
                delete this.currentRecord.gambar;
            }

            Object.entries(this.currentRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });
            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_update";
            } else {
                this.api = base_url + this.url + "_update";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response);
                    if (response.data.status == true) {
                        const index = this.records.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.currentRecord = {}
                        // this.$set(this.records, index, response.data.data);
                        this.records.splice(index, 1, response.data.data);

                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_edit');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        deleteData() {

            ShowLoading();
            const formData = new FormData();
            formData.append("id", this.temporaryId);
            console.log(this.temporaryId)
            formData.append("url", "ra_rekomendasi_delete");
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_delete";
            } else {
                this.api = base_url + this.url + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)

                .then((response) => {
                    console.log(response);
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.records.findIndex((record) => record.id === this.temporaryId);
                        this.records.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },


        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
            this.deleteIconTinyMce();


            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')

            setTimeout(() => {
                this.$refs[modal_name].classList.remove('show');
                this.$refs[modal_name].style.display = 'none';
            }, 150)
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')
            this.intervalId2 = setInterval(() => {
                this.deleteIconTinyMce();
            }, 100);

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        deleteRecord(id) {

            this.temporaryId = id;
            console.log(this.temporaryId)
            this.showModal2('modal_delete');
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        },
        deleteNotifTinyMce() {
            const elements = document.querySelectorAll('.tox-notifications-container');

            if (elements.length == 2) {
                elements.forEach((element) => {
                    // Add or remove classes, or perform other operations
                    element.classList.add('d-none');
                });
                clearInterval(this.intervalId);
            }

        },
        deleteIconTinyMce() {
            const elements = document.querySelectorAll('.tox-statusbar__branding');

            if (elements.length == 2) {
                elements.forEach((element) => {
                    // Add or remove classes, or perform other operations
                    element.classList.add('d-none');
                });
                clearInterval(this.intervalId2);
            }
        }
    },

    // mounted() {

    // },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }

        this.intervalId = setInterval(() => {
            this.deleteNotifTinyMce();

        }, 100);

        //akses
        this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');
        this.delete_akses = menu_akses_cek(this.url, 'delete');




        this.index();




    }



}
</script>