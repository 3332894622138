<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                {{ Capitalize(tipe) }} Dosen
            </div>
            <div class="d-flex flex-column gap-2 mt-2">
                <!-- <div class="fs-8 fw-bold font-custom-2 font-gray-custom">
                        >Desktop/Pengguna
                    </div> -->

                <div class="row me-0">

                    <div class="col-4">
                        <div class="d-flex align-items-center gap-5">
                            <div class="">
                                <select class="form-select fs-8 font-custom-2 py-1 fw-bold"
                                    aria-label="Default select example" v-model="tipe" @change="changeTipe">
                                    <option selected disabled>Pilih Tipe:</option>
                                    <option value="PENELITIAN">PENELITIAN</option>
                                    <option value="PENGABDIAN">PENGABDIAN</option>
                                    <option value="PUBLIKASI">PUBLIKASI</option>
                                    <option value="HKI">HKI</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center">
                        <div class="d-flex justify-content-center  align-items-center">
                            <div>
                                <i class='bx bx-chevron-left fs-7' @click="index_before_page()"
                                    style="cursor: pointer;"></i>
                            </div>
                            <div class="fs-7">
                                {{ numberPage }}/{{ totalPages }}
                            </div>
                            <div>
                                <i class='bx bx-chevron-right fs-7' @click="index_next_page(previousLastId, NextLastId)"
                                    style="cursor: pointer;"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-end ">
                        <button v-if="create_akses == true" class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                            style="border-radius: 5px;" data-bs-toggle="modal" data-bs-target="#modal_tambah"
                            @click="showModal('modal_tambah')">
                            <img src="@/assets/icon/data_plus.png" alt="" width="15">
                            Tambah {{ Capitalize(tipe) }}
                        </button>

                    </div>

                </div>


                <div class="border mt-1 rounded-3 me-2 font-custom-1">
                    <table class="table mb-0 bg-transparent table-borderless " style="">
                        <thead>
                            <tr>
                                <th scope="col" class="bg-transparent fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7">Dosen </th>
                                <th scope="col" class="font-custom-1 fs-7">judul </th>
                                <th scope="col" class="font-custom-1 fs-7">Semester</th>
                                <th scope="col" class="font-custom-1 fs-7">Tahun Ajaran</th>
                                <th scope="col" class="font-custom-1 fs-7">Tahun Penulisan</th>
                                <th scope="col" class="font-custom-1 fs-7">Jenis Penelitian</th>
                                <th scope="col" class="font-custom-1 fs-7">Jumlah dana </th>
                                <th scope="col" class="font-custom-1 fs-7">Sumber dana </th>
                                <th scope="col" class="font-custom-1 fs-7" v-if="tipe == 'PUBLIKASI'">Link Publikasi
                                </th>
                                <!-- <th scope="col" class="font-custom-1 fs-7" v-if="tipe == 'HKI'">Sertifikat
                                </th> -->
                                <th scope="col" class="font-custom-1 fs-7"
                                    v-if="tipe == 'PENELITIAN' || tipe == 'PENGABDIAN'">Masa pengerjaan </th>

                                <th scope="col" class="bg-transparent  fs-7">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in records" :key="record.id" :ref="record.id" class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ record.dosen }}</td>
                                <td>{{ record.judul }}</td>
                                <td>{{ record.semester }}</td>
                                <td>{{ record.tahun_ajaran }}</td>
                                <td>{{ record.tahun_penulisan }}</td>
                                <td>{{ record.jenis_penelitian }}</td>
                                <td>{{ rupiah(record.jumlah_dana) }}</td>
                                <td>{{ record.sumber_dana }}</td>
                                <td v-if="tipe == 'PUBLIKASI'">{{ record.link_publikasi }}</td>
                                <!-- <td v-if="tipe == 'HKI'"><img :src="record.sertifikat" alt=""
                                        style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                        @click="openInNewTab(record.sertifikat)">
                                </td> -->
                                <td v-if="tipe == 'PENELITIAN' || tipe == 'PENGABDIAN'">{{ record.masa_pengerjaan }}</td>

                                <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                            v-on:click="editRecord(record)" data-bs-toggle="modal"
                                            data-bs-target="#modal_edit"><i
                                                class='bx bxs-message-square-edit fs-8'></i></button>
                                        <button v-if="delete_akses == true" class="btn btn-danger btn-sm fs-8"
                                            data-bs-toggle="modal" data-bs-target="#modal_delete"
                                            v-on:click="deleteRecord(record.id)"><i
                                                class='bx bxs-trash-alt fs-8'></i></button>
                                    </div>

                                </td>


                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>

    </div>
    <form @submit.prevent="insert()">
        <div class="modal fade" id="modal_tambah" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
            aria-hidden="true" ref="modal_tambah">

            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah data {{ Capitalize(tipe) }}</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_tambah"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_tambah', 'close_modal_tambah')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Dosen</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="selectedDosen" required>
                                <option value="" selected disabled>- Pilih dosen</option>
                                <option v-for="list in dosenList" :key="list.nama"
                                    :value="{ id: list.id, kode_unik: list.kode_unik }">
                                    {{ list.nama + ' - ' + list.nip }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="kode" class="form-label">Judul</label>
                            <input type="text" class="form-control" v-model="newRecord.judul" required />
                        </div>
                        <div class="mb-3">
                            <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                            <input type="text" class="form-control" v-model="newRecord.tahun_ajaran" required />
                        </div>
                        <div class="mb-3" v-if="tipe == 'PENELITIAN' || tipe == 'PENGABDIAN'">
                            <label for="masa_pengerjaan" class="form-label">Masa Pengerjaan</label>
                            <input type="text" class="form-control" v-model="newRecord.masa_pengerjaan" required />
                        </div>
                        <div class="mb-3">
                            <label for="kode" class="form-label">Semester</label>
                            <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                                v-model="newRecord.semester" required>
                                <option value="" selected disabled>- Pilih Semester</option>
                                <option value="GANJIL">GANJIL</option>
                                <option value="GENAP">GENAP</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="tanggal_mulai" class="form-label"> Tahun penulisan</label>
                            <input type="number" :min="currentYear - 40" :max="currentYear" step="1" class=" form-control"
                                v-model="newRecord.tahun_penulisan" required />
                        </div>
                        <div class="mb-3">
                            <label for="jenis_penelitian" class="form-label"> Jenis Penelitian</label>
                            <input type="text" class="form-control" v-model="newRecord.jenis_penelitian" required />
                        </div>
                        <div class="mb-3">
                            <label for="jenis_penelitian" class="form-label"> Jumlah dana</label>
                            <input type="text" class="form-control"
                                @input="changerupiahInputInsert($event.target.value, 'jumlah_dana', 'insert')"
                                v-model="newRecord.jumlah_dana" />
                        </div>
                        <div class="mb-3">
                            <label for="jenis_penelitian" class="form-label"> Sumber dana</label>
                            <input type="text" class="form-control" v-model="newRecord.sumber_dana" required />
                        </div>
                        <div class="mb-3" v-if="tipe == 'PUBLIKASI'">
                            <label for="jenis_penelitian" class="form-label"> Link Publikasi</label>
                            <input type="text" class="form-control" v-model="newRecord.link_publikasi" />
                        </div>
                        <div class="mb-3" v-if="tipe == 'HKI'">
                            <label for="jenis_penelitian" class="form-label"> Sertifikat</label>
                            <input type="file" accept="image/png, image/jpeg" class="form-control" ref="sertifikat" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_tambah', 'close_modal_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan</button>
                    </div>

                </div>

            </div>

        </div>
    </form>
    <form @submit.prevent="update">
        <div class="modal fade" id="modal_edit" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
            aria-hidden="true" ref="modal_edit">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Data {{ Capitalize(tipe) }}</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_edit"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_edit', 'close_modal_edit')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Dosen</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="selectedDosen" required>
                                <option value="" selected disabled>- Pilih dosen</option>
                                <option v-for="list in dosenList" :key="list.nama"
                                    :value="{ id: list.id, kode_unik: list.kode_unik }">
                                    {{ list.nama + ' - ' + list.nip }}
                                </option>
                            </select>

                        </div>
                        <div class="mb-3">
                            <label for="kode" class="form-label">Judul</label>
                            <input type="text" class="form-control" v-model="currentRecord.judul" required />
                        </div>

                        <div class="mb-3">
                            <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                            <input type="text" class="form-control" v-model="currentRecord.tahun_ajaran" required />
                        </div>
                        <div class="mb-3" v-if="tipe == 'PENELITIAN' || tipe == 'PENGABDIAN'">
                            <label for="masa_pengerjaan" class="form-label">Masa Pengerjaan</label>
                            <input type="text" class="form-control" v-model="currentRecord.masa_pengerjaan" required />
                        </div>
                        <div class="mb-3">
                            <label for="kode" class="form-label">Semester</label>
                            <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                                v-model="currentRecord.semester" required>
                                <option value="" selected disabled>- Pilih Semester</option>
                                <option value="GANJIL">GANJIL</option>
                                <option value="GENAP">GENAP</option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="tanggal_mulai" class="form-label"> Tahun penulisan</label>
                            <input type="number" :min="currentYear - 40" :max="currentYear" step="1" class=" form-control"
                                v-model="currentRecord.tahun_penulisan" required />
                        </div>
                        <div class="mb-3">
                            <label for="jenis_penelitian" class="form-label"> Jenis Penelitian</label>
                            <input type="text" class="form-control" v-model="currentRecord.jenis_penelitian" required />
                        </div>
                        <div class="mb-3">
                            <label for="jenis_penelitian" class="form-label"> Jumlah dana</label>
                            <input type="text" class="form-control"
                                @input="changerupiahInputInsert($event.target.value, 'jumlah_dana', 'update')"
                                v-model="currentRecord.jumlah_dana" />

                        </div>
                        <div class="mb-3">
                            <label for="jenis_penelitian" class="form-label"> Sumber dana</label>
                            <input type="text" class="form-control" v-model="currentRecord.sumber_dana" required />
                        </div>
                        <div class="mb-3" v-if="tipe == 'PUBLIKASI'">
                            <label for="jenis_penelitian" class="form-label"> Link Publikasi</label>
                            <input type="text" class="form-control" v-model="currentRecord.link_publikasi" required />
                        </div>
                        <div v-if="tipe == 'HKI'">
                            <div class="" v-if="currentRecord.sertifikat != null || currentRecord.sertifikat != ''">
                                <img :src="currentRecord.sertifikat" alt=""
                                    style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                    class=" mb-2 border border-3" @click="openInNewTab(currentRecord.sertifikat)">
                            </div>
                            <div class="mb-3">
                                <label for="sertifikat_update" class="form-label"> Sertifikat</label>
                                <input type="file" accept="image/png, image/jpeg" class="form-control"
                                    ref="sertifikat_update" />
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_edit', 'close_modal_edit')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>

                    </div>


                </div>

            </div>
        </div>
    </form>

    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete"></button>
                    <button type="button " class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_delete', 'close_modal_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_delete', 'close_modal_delete')">Tidak, tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, base_url, menu_akses_cek, CloseLoading, ShowLoading, ErrorConnectionTimeOut, AlertBottom, openInNewTab, JustYearNow, NumberOnly, rupiah, rupiahInput, rupiahoutput, Capitalize } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";

export default {

    data() {
        return {
            //data
            url: 'penelitian_dosen',
            api: "",
            tipe: "PENELITIAN",

            //format
            currentYear: "",

            //dosne
            dosenList: [],
            selectedDosen: "",

            //pages
            limit: "15",
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,
            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            records: [],
            newRecord: { dosen: "", judul: "", tahun_penulisan: "", jenis_penelitian: "", jumlah_dana: "", sumber_dana: "", link_publikasi: "", sertifikat: "", link_sertifikat: "", semester: "", tahun_ajaran: "", masa_pengerjaan: "" },
            currentRecord: {},
        }
    },

    components: {
        SideNavbar,
    },


    methods: {
        //data
        rupiah,
        openInNewTab,
        NumberOnly,
        rupiahInput,
        Capitalize,
        changerupiahInputInsert(value, pilihan, status) {
            if (status == 'insert') {
                if (value.length == 5 && value.slice(-1) == ",") {
                    value = "Rp 0,-";
                    this.newRecord[pilihan] = rupiahInput(value);
                }
                this.newRecord[pilihan] = rupiahInput(value);

            } else {
                this.currentRecord[pilihan] = rupiahInput(value);

            }

        },
        index() {
            this.records = []
            ShowLoading();
            let formData = new FormData();

            formData.append("previous_id", '');
            formData.append("last_id", '');
            formData.append("limit", this.limit);
            formData.append("tipe", this.tipe);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        this.dosenList = response.data.dosen
                        if (response.data.data != undefined) {

                            this.records = response.data.data[1];
                            // this.prodiList = response.data.prodi.map(item => ({
                            //     nama: item.prodi_pt,
                            //     value: item.prodi_pt,
                            //     label: item.prodi_pt,
                            // }));
                            // this.kecamatanList = response.data.kecamatan.map((item) => ({
                            //     nama: item.kecamatan,
                            //     value: item.kecamatan,
                            //     label: item.kecamatan,
                            // }));
                            // this.kabupatenList = response.data.kabupaten.map((item) => ({
                            //     nama: item.kabupaten,
                            //     value: item.kabupaten,
                            //     label: item.kabupaten,
                            // }));

                            this.totalPages = response.data.data[0][0].number_page;
                            this.previousLastId = response.data.data[1].id;
                            this.previousLastIdOld = response.data.data[1].id;
                            let last_index = Object.keys(response.data.data[0]).pop();

                            this.NextLastId = response.data.data[1][last_index].id;
                            this.NextLastIdOld = response.data.data[1][last_index].id;
                            this.arrayLastIdOld.push(response.data.data[1][last_index].id);
                            // this.arrayPreviousIdOld.push(response.data.data[1][0].id);

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        index_next_page(param1, param2) {

            ShowLoading();
            let formData = new FormData();
            formData.append("previous_id", "");
            formData.append("last_id", param2);
            formData.append("limit", this.limit);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.totalPages = response.data.data[0][0].number_page;
                        this.arrayLastIdOld.push(param2);
                        this.arrayPreviousIdOld.push(param1);

                        this.records = response.data.data[1];
                        let last_index = Object.keys(response.data.data[0]).pop();

                        this.NextLastId = response.data.data[1][last_index].id; // id 33
                        this.previousLastId = response.data.data[1][0].id; // 7
                        this.numberPage = this.numberPage + 1;

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }


                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        index_before_page() {

            let last_index = this.arrayLastIdOld.length;
            last_index = last_index - 1;
            ShowLoading();
            let formData = new FormData();
            formData.append("url", "ra_admin_verifikasi_page");
            formData.append("previous_id", this.arrayPreviousIdOld[last_index]);
            formData.append("last_id", "");
            formData.append("limit", this.limit);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.totalPages = response.data.data[0][0].number_page;

                        this.records = response.data.data[1];
                        let last_index = Object.keys(response.data.data[1]).pop();
                        this.NextLastId = response.data.data[1][last_index].id;
                        this.previousLastId = response.data.data[1][0].id;

                        if (this.arrayLastIdOld.length != 1) {
                            this.arrayLastIdOld.pop();
                            this.arrayPreviousIdOld.pop();
                            this.numberPage = this.numberPage - 1;
                        }

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        insert() {

            ShowLoading();
            const formData = new FormData();
            this.newRecord.dosen = this.selectedDosen.id;
            this.newRecord.kode_unik = this.selectedDosen.kode_unik;
            this.newRecord.jumlah_dana = rupiahoutput(this.newRecord.jumlah_dana);

            Object.entries(this.newRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });
            formData.append('tipe', this.tipe);

            if (this.tipe == 'HKI') {
                formData.append("sertifikat", this.$refs.sertifikat.files[0]);

            }
            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_insert";
            } else {
                this.api = base_url + this.url + "_insert";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.records.unshift(response.data.data);

                        // Clear the form inputs
                        this.newRecord = {

                        };
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_tambah', 'close_modal_tambah')
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            const index = this.dosenList.findIndex((record) => record.nama == this.currentRecord.dosen);

            //set dosen
            this.currentRecord['dosen'] = this.dosenList[index].id;
            this.selectedDosen = { id: this.dosenList[index].id, kode_unik: this.dosenList[index].kode_unik };


            //set jumlah dana
            this.currentRecord.jumlah_dana = this.rupiahInput(this.currentRecord.jumlah_dana);
            this.showModal('modal_edit');

        },
        update() {
            ShowLoading();

            const formData = new FormData();
            this.currentRecord.jumlah_dana = rupiahoutput(this.currentRecord.jumlah_dana);
            this.currentRecord.tipe = this.tipe;
            this.currentRecord.dosen = this.selectedDosen.id;
            this.currentRecord.kode_unik = this.selectedDosen.kode_unik;

            if (this.tipe == "HKI") {
                if (this.$refs.sertifikat_update.files[0] != undefined) {
                    formData.append("link_sertifikat", this.currentRecord.sertifikat);
                    formData.append("sertifikat", this.$refs.sertifikat_update.files[0]);
                    delete this.currentRecord.sertifikat;

                } else {
                    formData.append("sertifikat", "");
                    formData.append("link_sertifikat", this.currentRecord.sertifikat);
                    delete this.currentRecord.sertifikat;
                }

            }

            Object.entries(this.currentRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_update";
            } else {
                this.api = base_url + this.url + "_update";
            }



            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.records.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.currentRecord = {}

                        this.records.splice(index, 1, response.data.data);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_edit', 'close_modal_edit');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        deleteData() {

            ShowLoading();
            const formData = new FormData();
            formData.append("id", this.temporaryId);
            console.log(this.temporaryId)
            formData.append("url", "ra_rekomendasi_delete");
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_delete";
            } else {
                this.api = base_url + this.url + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)

                .then((response) => {
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.records.findIndex((record) => record.id === this.temporaryId);
                        this.records.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_delete', 'close_modal_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        updateFormatted(value) {
            // Remove non-digit characters and convert to a number
            const unformattedValue = parseFloat(value.replace(/[^0-9]/g, ""));
            // Check if the value is a valid number
            if (!isNaN(unformattedValue)) {
                // Update the currentRecord object with the unformatted value
                this.newRecord.jumlah_dana = unformattedValue;
            }
        },

        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        deleteRecord(id) {

            this.temporaryId = id;
            this.showModal('modal_delete');
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        }, changeTipe() {
            this.index();
            this.numberPage = 1;
        }


    },
    computed: {

        formattedrupiah() {
            let angka = this.newRecord.jumlah_dana || 0;
            angka = "Rp " + angka.toLocaleString("id-ID");
            angka = angka + ',-'
            return angka;
        },
    },

    mounted() {
        this.newRecord.tahun_penulisan = JustYearNow();
        // const value = "12345";
        // const transformedValue = this.rupiahInput(value);
        // console.log(transformedValue);
    },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }

        //akses
        this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');
        this.delete_akses = menu_akses_cek(this.url, 'delete');

        this.index();

    }



}
</script>