<template>
  <div class="container-fluid" style="height: 100vh !important">
    <div class="row font-custom-1" style="height: 85% !important">
      <div class="col-6 d-none d-xl-block bg-image-login"></div>
      <div class="col-xl-6 col-12 d-flex align-items-center justify-content-center">
        <div class="">
          <div class="text-center fs-7">
            <div class="fs-2 fw-bold">Selamat Datang</div>
            <div class="">di Pustaka</div>
            <div class="">Satya Terra Bhinneka</div>
            <div class="">Mohon masukkan detail login Anda</div>
          </div>

          <div class="mt-2 fs-7">
            <form @submit.prevent="login">
              <div>
                <label for="email" class="form-label text-start">
                  <div class="d-flex align-items-center text-secondary">
                    NIP/NIM/Username
                  </div>
                </label>
                <input type="text" name="email" class="form-control mb-2 input-form-login fs-7"
                  placeholder="Masukan NIP/NIM/Username" id="email" v-model="username" required />
              </div>
              <div>
                <label for="email" class="form-label text-start">
                  <div class="d-flex align-items-center text-secondary">
                    Password
                  </div>
                </label>
                <input type="password" name="pass" class="form-control mb-2 input-form-login fs-7" id="password"
                  v-model="pass" placeholder="Masukan password" required />
              </div>
              <div>
                <button class="bg-warning w-100 border text-center rounded text-white py-2 bg-yellow-custom"
                  type="submit" v-html="isi_button"></button>
              </div>
              <div class="text-center mt-2" @click="showModal2('modal_masukkan_username')">
                <a style="cursor: pointer; text-decoration: underline" class="text-primary">
                  Lupa password?
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row bg-green-custom" style="height: 10%">
      <div class="d-flex justify-content-end align-items-center pe-5">
        <div class="">
          <div>
            <img src="@/assets/img/logo_st_bhinneka.png" alt="" height="25" />
          </div>
          <div class="d-flex justify-content-center text-light mt-1 fs-7 gap-2">
            <a href="https://instagram.com/univ_stbhinneka?igshid=NjIwNzIyMDk2Mg==" style="text-decoration: none"
              class="text-light">
              <i class="bx bxl-instagram-alt" style="cursor: pointer"></i>
            </a>
            <a href="https://www.facebook.com/univstbhinneka?mibextid=ZbWKwL" style="text-decoration: none"
              class="text-light">
              <i class="bx bxl-facebook-square" style="cursor: pointer"></i>
            </a>
            <!-- <a href="" style="text-decoration: none;" class="text-light">
                            <i class="bx bxl-twitter" style="cursor: pointer"> </i>
                        </a> -->
            <a href="https://www.tiktok.com/@stbhinneka?_t=8fe5SaAVEk3&_r=1" style="text-decoration: none"
              class="text-light">
              <i class="bx bxl-tiktok" style="cursor: pointer"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row bg-blue-custom font-custom-3" style="height: 5%">
      <div class="ps-5 d-flex align-items-center">
        <div class="ps-5 d-flex gap-5 fs-8">
          <a href="" style="color: white !important"> Terms & privacy </a>
          <a href="" style="color: white !important"> Accessibility </a>
          <a href="" style="color: white !important"> Emergency </a>
          <a href="" style="color: white !important"> Privacy </a>
        </div>
      </div>
    </div>

    <div class="modal fade" id="modal_confirm_otp" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_confirm_otp">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-green-custom text-light">
            <h5 class="modal-title" id="exampleModalScrollableTitle">
              Konfirmasi Kode OTP
            </h5>
            <button hidden type="button" ref="close_modal_confirm_otp"></button>
            <button type="button" class="btn bg-transparent text-light border-0">
              <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_confirm_otp')" />
            </button>
          </div>
          <div class="modal-body">
            <label for="kode_otp" class="form-label">Masukan kode OTP :</label>
            <input type="text" class="form-control" id="kode_otp" @change="CharOnly" v-model="kode_otp" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideModal2('modal_confirm_otp')">
              Batal
            </button>
            <button type="button" class="btn bg-danger text-light" @click="confirm_otp">
              Cek
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="modal_masukkan_username" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_masukkan_username">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-green-custom text-light">
            <h5 class="modal-title" id="exampleModalScrollableTitle">
              Masukkan Username
            </h5>
            <button hidden type="button" ref="close_modal_masukkan_username"></button>
            <button type="button" class="btn bg-transparent text-light border-0">
              <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_masukkan_username')" />
            </button>
          </div>
          <div class="modal-body">
            <label for="kode_otp" class="form-label">Masukan Username :</label>
            <input type="text" class="form-control" id="kode_otp" v-model="username" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideModal2('modal_masukkan_username')">
              Batal
            </button>
            <button type="button" class="btn bg-danger text-light" @click="cek_username">
              Cek
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="modal_change_password" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_change_password">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-green-custom text-light">
            <h5 class="modal-title" id="exampleModalScrollableTitle">
              Konfirmasi password baru
            </h5>
            <button hidden type="button" aria-label="Close" ref="close_modal_change_password"></button>
            <button type="button " class="btn bg-transparent text-light border-0">
              <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_change_password')" />
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="password_1" class="form-label">Masukan password baru :</label>
              <input type="password" class="form-control" id="password_1" v-model="password_1" />
            </div>
            <div class="mb-3">
              <label for="password_2" class="form-label">Konfirmasi password :</label>
              <input type="password" class="form-control" id="password_2" v-model="password_2" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideModal2('modal_change_password')">
              Batal
            </button>
            <button type="button" class="btn bg-danger text-light" @click="change_password_user">
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-image-login {
  background-image: url("@/assets/img/login.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px -60px;
}
</style>

<script>
import {
  goPage,
  base_url,
  AlertPopup,
  ErrorConnectionTimeOut,
  ShowLoading,
  CloseLoading,
  timeout,
} from "@/assets/javascript/function";
import axios from "axios";
import Cookies from "js-cookie";

// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

// const firebaseConfig = {
//     apiKey: "AIzaSyA25t0zJZI9Nm-pgSX2c-edgazqLa7A2So",
//     authDomain: "stbhinneka-3078f.firebaseapp.com",
//     projectId: "stbhinneka-3078f",
//     storageBucket: "stbhinneka-3078f.appspot.com",
//     messagingSenderId: "420158893086",
//     appId: "1:420158893086:web:8bfe5097840ee0e2d4d20f",
//     measurementId: "G-S5DB0XPB6H"
// };

// initializeApp(firebaseConfig);
// const messaging = getMessaging(initializeApp(firebaseConfig));

// console.log('Requesting permission...');
// Notification.requestPermission().then((permission) => {
//   if (permission === 'granted') {
//     console.log('Notification permission granted.');
//   } else {
//     console.warn("Izin notifikasi tidak diberikan.");
//   }
// })

// onMessage(messaging, (payload) => {
//   console.log('Message received. ', payload);
// })

// getToken(messaging, { vapidKey: 'BEw-dDrNI77W-KJswnV_z54YhWrFkMNCiuxXiZjaWqP9B3fe_KEOL-EMU5ZohJAxJoO9jBalJy4jTFUQclTQzoY' }).then((currentToken) => {
//   if (currentToken) {
//     // Send the token to your server and update the UI if necessary
//     console.log('token is :' + currentToken);
//     localStorage.setItem('token_firebase', currentToken);

//     // ...
//   } else {
//     // Show permission request UI
//     console.log('No registration token available. Request permission to generate one.');
//     // ...
//   }
// }).catch((err) => {
//   console.log('An error occurred while retrieving token. ', err);
//   // ...
// });

export default {
  data() {
    return {
      //url
      url: "login",
      //data
      username: "",
      pass: "",
      config: "",

      kode_otp: "",

      id: "",
      id_user: "",
      id_profile: "",
      password_1: "",
      password_2: "",

      //tampilan
      isi_button: `<div class="fs-7">Login</div>`,
    };
  },
  methods: {
    //antimation
    goPage,
    cek_username() {
      ShowLoading();
      let formData = new FormData();

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "cek_username";
      } else {
        this.api = base_url + "cek_username";
      }

      formData.append("username", this.username);
      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            this.records = response.data.data;
            this.id_profile = response.data.id_profile;
            this.id_user = response.data.id_user;
            AlertPopup("success", "", response.data.message, 1500, false);
            this.hideModal2("modal_masukkan_username");
            this.showModal2("modal_confirm_otp");
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    confirm_otp() {
      ShowLoading();
      let formData = new FormData();

      formData.append("otp", this.kode_otp);
      formData.append("id_profile", this.id_profile);
      formData.append("id_user", this.id_user);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "confirm_otp_username";
      } else {
        this.api = base_url + "confirm_otp_username";
      }

      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            this.records = response.data.data;
            CloseLoading();
            this.hideModal2("modal_confirm_otp");
            this.showModal2("modal_change_password");
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    change_password_user() {
      ShowLoading();
      let formData = new FormData();

      if (this.password_1 != this.password_2) {
        AlertPopup(
          "error",
          "",
          "Password tidak sama silahkan coba kembali",
          1500,
          false
        );
        return true;
      }

      formData.append("password", this.password_1);
      formData.append("id_user", this.id_user);
      formData.append("id_profile", this.id_profile);
      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "change_password_user_username";
      } else {
        this.api = base_url + "change_password_user_username";
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            this.records = response.data.data;
            AlertPopup("success", "", response.data.message, 1500, false);
            this.hideModal2("modal_change_password");
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    showModal2(modal_name) {
      this.$refs[modal_name].classList.remove("animation-hide-modal");
      this.$refs[modal_name].classList.add("animation-show-modal");
      this.$refs[modal_name].classList.add("show");
      this.$refs[modal_name].style.display = "block";
      // this.$refs[modal_name].classList.remove('animation-hide-modal')
      // this.$refs[modal_name].classList.add('animation-show-modal')
    },
    hideModal2(modal_name) {
      this.$refs[modal_name].classList.remove("animation-show-modal");
      this.$refs[modal_name].classList.add("animation-hide-modal");

      setTimeout(() => {
        this.$refs[modal_name].classList.remove("show");
        this.$refs[modal_name].style.display = "none";
      }, 150);
      // this.$refs[modal_name].classList.remove('animation-hide-modal')
      // this.$refs[modal_name].classList.add('animation-show-modal')
    },
    login() {
      this.isi_button = `<div class="fs-7 d-flex align-items-center justify-content-center"><div class="spinner-border me-3" role="status">
              <span class="sr-only"></span>
            </div>
                               <div>Mohon Tunggu</div></div>`;
      let formData = new FormData();

      formData.append("username", this.username);
      formData.append("password", this.pass);
      formData.append("token_firebase", localStorage.getItem("token_firebase"));

      formData.append("url", this.url);
      if (process.env.NODE_ENV === "development") {
        this.api = base_url + this.url;
      } else {
        this.api = base_url + this.url;
      }

      axios
        .post(this.api, formData, { timeout: timeout })
        .then((response) => {
          if (response.data.status == true) {
            if (response.data.tipe == "mahasiswa") {
              const urlString = window.location.href;
              const cleanString = urlString.split("?")[0];
              const url = new URL(cleanString);
              url.pathname = "/";

              const modifiedString = url.toString();
              Cookies.set("X-Header-Token", response.data.token_refresh);
              window.location.href = modifiedString + "mhs/dashboard";
            } else {

              // AlertPopup("success", "Selamat Datang", "", 1500, false);
              Cookies.set("token", response.data.token_refresh);
              if (
                response.data.statuspass == 1 ||
                response.data.tipe == "admin"
              ) {
                goPage("home");
                localStorage.setItem("statuspass", "1");
              } else {
                goPage("profile");
                localStorage.setItem("statuspass", response.data.statuspass);
              }
            }
          } else {
            // this.showAlert(response.data.message);
            // this.email = "0" + this.email.substring(2);
            this.isi_button = `<div class="fs-7">Login</div>`;
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          this.isi_button = `<div class="fs-7">Login</div>`;
          ErrorConnectionTimeOut(error);
        });
    },

  },
};
</script>
