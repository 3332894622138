<template>
  <div class="font-custom-2 fw-bold fs-7 animation-duration-item-navbar cursor-pointer w-100" :ref="refName"
    @mouseover="hoverItemNavbar(refName)" @mouseleave="leaveItemNavbar(refName)" v-on:click="redirectPage()">
    <div class="py-2 d-flex align-items-center gap-2" :class="{
      'ps-3 pe-2': isProfile && isShowNavbar,
      'ps-2': isProfile && !isShowNavbar,
      'ps-4': !isProfile,
    }">
      <div class="h-100">
        <div class="position-relative">
          <img :src="iconPath2" alt="" :width="widthIcon" :class="{ 'rounded rounded-circle': isProfile }"
            v-if="isProfile" height="30" />
          <img :src="iconPath" alt="" :width="widthIcon" :class="{ 'rounded rounded-circle': isProfile }" v-else />
          <span v-if="isNotifikasi == 'true'" v-show="!isShowNavbar && notifSum > 0"
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            <span v-show="notifSum <= 99">{{ notifSum }}</span>
            <span v-show="notifSum > 99">99+</span>
            <span class="visually-hidden">unread messages</span>
          </span>
        </div>
      </div>
      <div v-show="isShowNavbar" class="ps-1 pe-2 w-100" :class="{ 'fw-light': tittleBold }">
        <div class="w-100" :class="{
          'd-flex align-items-center justify-content-between':
            isNotifikasi == 'true' && notifSum > 0,
          '': isNotifikasi != 'true',
        }">
          <div>
            {{ title }}
          </div>
          <div v-if="isNotifikasi == 'true'" v-show="isShowNavbar && notifSum > 0" class="badge bg-danger">
            <span v-show="notifSum <= 99">{{ notifSum }}</span>
            <span v-show="notifSum > 99">99+</span>
          </div>
        </div>
        <div class="fw-light text-light">
          {{ nim }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.animation-duration-item-navbar {
  transition: all 0.3s ease !important;
}

.item-navbar {
  width: 70px;
}
</style>

<script>
import { goPage } from "@/assets/javascript/function";
import defaultImage from "@/assets/img/default.png";

export default {
  data() {
    return {
      //tampilan
      isHovered: false,
      notifSum: localStorage.getItem("notifSum"),
      defaultImage,
    };
  },

  props: {
    //for ui
    title: String,
    refName: String,
    iconUrl: {
      type: String,
      required: true,
    },

    isShowNavbar: Boolean,
    widthIcon: String,

    //condition if profile
    isProfile: String,
    nim: String,
    tittleBold: {
      type: String,
    },

    isLogout: String,
    isNotifikasi: String,
  },

  async mounted() {
    this.ActiveItem(window.location.pathname.substring(1));

    if (this.isNotifikasi == "true") {
      this.getNotifSum();

      if ((localStorage.getItem == "notifSum") == null) {
        localStorage.setItem("lastReadNotif", "");
        localStorage.setItem("notifSum", 0);
      }
    }
  },

  methods: {
    goPage,

    hoverItemNavbar(param) {
      //add blue except profile
      if (this.isNotifikasi) {
        this.$refs[param].classList.remove("bg-warning");
        this.$refs[param].classList.add("bg-warning");
      }
      if (!this.isProfile && !this.isNotifikasi) {
        this.$refs[param].classList.add("bg-blue-custom");
      }

      //add red if logout
      if (this.isLogout) {
        this.$refs[param].classList.remove("bg-blue-custom");
        this.$refs[param].classList.add("bg-danger");
      }
    },
    ActiveItem(param) {
      if (!this.isProfile && this.$refs[param] && !this.isNotifikasi) {
        this.$refs[param].classList.add("bg-blue-custom");
      }
    },
    leaveItemNavbar(param) {
      //add blue except profile
      if (this.isNotifikasi) {
        this.$refs[param].classList.contains("bg-warning");
        this.$refs[param].classList.remove("bg-warning");
      }

      if (!this.isProfile && !this.isNotifikasi) {
        if (this.$refs[param].classList.contains("bg-blue-custom")) {
          this.$refs[param].classList.remove("bg-blue-custom");
        }
      }

      if (this.isLogout) {
        this.$refs[param].classList.contains("bg-danger");
        this.$refs[param].classList.remove("bg-danger");
      }
      this.ActiveItem(window.location.pathname.substring(1));
    },
    redirectPage() {
      if (this.isLogout == undefined) {
        goPage(this.refName);
        return true;
      }
      if (JSON.parse(this.isLogout)) {
        goPage("");
        return true;
      }
    },
    getNotifSum() {
      setInterval(() => {
        this.notifSum = localStorage.getItem("notifSum");
      }, 1000);
    },
  },

  computed: {
    iconPath() {
      return require("@/assets/" + this.iconUrl);
    },
    iconPath2() {
      return this.iconUrl;
    },
  },

  // computed: {
  //     backgroundHover() {
  //         return {
  //             'bg-blue-custom': this.isHoveredItemNavbar,
  //         }
  //     }
  // }
};
</script>
