<template>
    <main id="sample">
        <Editor api-key="01hjrmvp8caessqt3d5sgjy7o603r4a2yhxwux7tj3ri9095" :init="{
            plugins: 'lists link image table code help wordcount',
        }" v-model="editorContent" />
        <div>
            <h2>Editor Content:</h2>
            <div>{{ editorContent }}</div>
        </div>
    </main>
</template>

<style scoped>
.logo {
    display: block;
    margin: 0 auto 2rem;
}

@media (min-width: 1024px) {
    #sample {
        display: flex;
        flex-direction: column;
        place-items: center;
        width: 1000px;
    }
}
</style>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
    components: {
        Editor
    },
    data() {
        return {
            editorContent: ''
        };
    }
};
</script>
