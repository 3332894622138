<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                Menu Akses
            </div>
            <div class="d-flex flex-column gap-2 mt-2">
                <!-- <div class="fs-8 fw-bold font-custom-2 font-gray-custom">
                        >Desktop/Pengguna
                    </div> -->
                <div class="row me-0">
                    <div class="col-3">
                        <input type="text" v-model="query" @change="index()" class="form-control"
                            placeholder="Cari nama atau username">
                    </div>

                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tipeFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tipe</option>
                            <option v-for="list in tipeList" :key="list.tipe" :value="list.tipe">
                                {{ list.tipe }}
                            </option>
                        </select>
                    </div>

                    <!-- <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Prodi</option>
                            <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="kurikulumFilter" @change="index()" :disabled="prodiFilter == ''" required>
                            <option value="" selected disabled>- Pilih Kurikulum</option>
                            <option v-for="list in kurikulumList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div> -->

                    <div class="col-8 text-center">
                        <div class="d-flex justify-content-center  align-items-center">
                            <div>
                                <i class='bx bx-chevron-left fs-7' @click="index_before_page()"
                                    style="cursor: pointer;"></i>
                            </div>
                            <div class="fs-7">
                                {{ numberPage }}/{{ totalPages }}
                            </div>
                            <div>
                                <i class='bx bx-chevron-right fs-7' @click="index_next_page(previousLastId, NextLastId)"
                                    style="cursor: pointer;"></i>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="border mt-1 rounded-3 mb-5 me-2 font-custom-1">
                    <table class="table mb-0 bg-transparent table-borderless " style="">
                        <thead>
                            <tr>
                                <th scope="col" class="bg-transparent fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7">Nama lengkap</th>
                                <!-- <th scope="col" class="font-custom-1 fs-7">Prodi</th>
                                <th scope="col" class="font-custom-1 fs-7">Tipe</th> -->
                                <th scope="col" class="font-custom-1 fs-7">Username</th>
                                <th scope="col" class="font-custom-1 fs-7">Tipe</th>
                                <th scope="col" class="bg-transparent  fs-7">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in records" :key="record.id" :ref="record.id" class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ record.nama_lengkap }}</td>
                                <!-- <td>{{ record.prodi }}</td>
                                <td>{{ record.kurikulum }}</td>
                                <td>{{ record.tipe }}</td> -->
                                <td>{{ record.username }}</td>
                                <td>{{ record.tipe }}</td>
                                <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <button class="btn btn-warning btn-sm fs-8" v-on:click="getAksesIndex(record.id)"
                                            data-bs-toggle="modal" data-bs-target="#modal_edit"
                                            v-if="update_akses == true"><i
                                                class='bx bxs-message-square-edit fs-8'></i></button>
                                        <!-- <button class="btn btn-danger btn-sm fs-8" data-bs-toggle="modal"
                                            data-bs-target="#modal_delete" v-on:click="deleteRecord(record.id)"><i
                                                class='bx bxs-trash-alt fs-8'></i></button> -->
                                    </div>

                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>

    </div>


    <div class="modal fade" id="modal_edit" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_edit">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Menu Akses</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close" ref="close_modal_edit"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_edit', 'close_modal_edit')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="border mt-1 rounded-3 mb-5 me-2 font-custom-1">
                        <table class="table mb-0 bg-transparent table-borderless " style="">
                            <thead class="sticky-top" style="
                                position: sticky !important;
                                top: -20px !important;
                                background-color: white;
                            ">
                                <tr>
                                    <th scope="col" class="bg-transparent fs-7">No</th>
                                    <th scope="col" class="font-custom-1 fs-7">Menu</th>
                                    <th scope="col" class="font-custom-1 fs-7">Tambah</th>
                                    <th scope="col" class="font-custom-1 fs-7">Edit</th>
                                    <th scope="col" class="font-custom-1 fs-7">Hapus</th>
                                    <th scope="col" class="font-custom-1 fs-7">lihat</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(record, index) in aksesRecords" :key="record.id" :ref="record.id"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td>{{ record.menu }}</td>

                                    <!-- <td>
                                        <input class="form-check-input bg-green-custom-checked bg-border-green-custom"
                                            type="checkbox" role="switch" v-model="record.akses" :ref="record.menu"
                                            @click="grantAkses(record.menu, record.akses, index)" />
                                    </td> -->
                                    <td>
                                        <span v-if="record.menu == 'penilaian'">
                                            Import nilai
                                        </span>
                                        <input class="form-check-input bg-green-custom-checked bg-border-green-custom"
                                            type="checkbox" role="switch" v-model="record.akses_1" :ref="record.menu"
                                            @click="grantAkses(record.menu, record.akses, record.akses_1, 'create', index)" />

                                    </td>
                                    <td>
                                        <span v-if="record.menu == 'penilaian'">
                                            Finalisasi Nilai
                                        </span>
                                        <input class="form-check-input bg-green-custom-checked bg-border-green-custom"
                                            type="checkbox" role="switch" v-model="record.akses_2" :ref="record.menu"
                                            @click="grantAkses(record.menu, record.akses, record.akses_2, 'update', index)" />
                                    </td>
                                    <td>
                                        <input class="form-check-input bg-green-custom-checked bg-border-green-custom"
                                            type="checkbox" role="switch" v-model="record.akses_3" :ref="record.menu"
                                            @click="grantAkses(record.menu, record.akses, record.akses_3, 'delete', index)" />
                                    </td>
                                    <td>
                                        <input class="form-check-input bg-green-custom-checked bg-border-green-custom"
                                            type="checkbox" role="switch" v-model="record.akses_4" :ref="record.menu"
                                            @click="grantAkses(record.menu, record.akses, record.akses_4, 'view', index)" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete"></button>
                    <button type="button " class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_delete', 'close_modal_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_delete', 'close_modal_delete')">Tidak, tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, base_url, CloseLoading, ShowLoading, menu_akses_cek, ErrorConnectionTimeOut, AlertBottom, DateNow } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";

export default {
    data() {
        return {
            //data
            url: 'menu_akses',
            api: "",
            query: '',

            //pages
            limit: "10",
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,
            id_user: "",



            //filter
            // kurikulumList: [],
            tipeList: [{ tipe: "pegawai" }, { tipe: "dosen" }],

            // prodiList: [],
            // kurikulumFilter: "",
            tipeFilter: "",
            // prodiFilter: "",
            aksesRecords: [],

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            records: [],
            newRecord: { nama: "", tahun_ajaran: "", prodi: "", kurikulum: "" },
            currentRecord: {},
        }
    },
    components: {
        SideNavbar,
    },

    methods: {
        DateNow,
        getAksesIndex(id) {
            this.showModal('modal_edit');
            this.id_user = 0;
            this.id_user = id;
            this.aksesRecords = []
            ShowLoading();
            let formData = new FormData();

            formData.append("user", id);

            // formData.append("kurikulum", this.kurikulumFilter);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_get';
            } else {
                this.api = base_url + this.url + '_get';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (response.data.data != undefined) {
                            console.log(response.data);
                            // this.aksesRecords = response.data.data[0];
                            response.data.data[0].forEach(element => {
                                if (element.akses != null && element.akses.includes('create')) {
                                    element.akses_1 = true;
                                } else {
                                    element.akses_1 = false;
                                }

                                if (element.akses != null && element.akses.includes('update')) {
                                    element.akses_2 = true;
                                } else {
                                    element.akses_2 = false;
                                }

                                if (element.akses != null && element.akses.includes('delete')) {
                                    element.akses_3 = true;
                                } else {
                                    element.akses_3 = false;
                                }

                                if (element.akses != null && element.akses.includes('view')) {
                                    element.akses_4 = true;
                                } else {
                                    element.akses_4 = false;
                                }

                                if (element.akses == null) {
                                    element.akses = '0';
                                }

                                this.aksesRecords.push(element);
                            });

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        grantAkses(menu, akses, aksesNumber, jeniasAkses, index) {
            ShowLoading();
            let akses_value;
            let aksesArray = [];


            if (akses.includes(jeniasAkses)) {
                aksesArray = akses.split(',');
                const index = aksesArray.indexOf(jeniasAkses);
                if (index !== -1) {
                    // Number 3 is found in the array
                    aksesArray.splice(index, 1);
                }
                const index_2 = aksesArray.indexOf('0');
                if (index_2 !== -1) {
                    aksesArray.splice(index_2, 1);
                }
                const index_3 = aksesArray.indexOf('*');
                if (index_3 !== -1) {
                    aksesArray.splice(index_3, 1);
                }


                akses_value = aksesArray.join(',');
            } else {
                aksesArray = akses.split(',');
                const index_2 = aksesArray.indexOf('0');
                if (index_2 !== -1) {
                    aksesArray.splice(index_2, 1);
                }
                const index_3 = aksesArray.indexOf('*');
                if (index_3 !== -1) {
                    aksesArray.splice(index_3, 1);
                }

                aksesArray.push(jeniasAkses);

                akses_value = aksesArray.join(',');
            }
            if (aksesArray.length == 0) {
                akses_value = "0"
            }



            let formData = new FormData();


            formData.append("menu", menu);
            formData.append("akses", akses_value);
            formData.append("user", this.id_user);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_grant';
            } else {
                this.api = base_url + this.url + '_grant';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (response.data.data != undefined) {
                            this.aksesRecords[index].akses = response.data.data.akses;
                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        if (aksesNumber == false) {
                            if (jeniasAkses == 'create') {
                                this.aksesRecords[index].akses_1 = false;
                            } else if (jeniasAkses == 'update') {
                                this.aksesRecords[index].akses_2 = false;
                            } else if (jeniasAkses == 'delete') {
                                this.aksesRecords[index].akses_3 = false;
                            } else {
                                this.aksesRecords[index].akses_4 = false;
                            }

                        } else {
                            if (jeniasAkses == 'create') {
                                this.aksesRecords[index].akses_1 = true;
                            } else if (jeniasAkses == 'update') {
                                this.aksesRecords[index].akses_2 = true;
                            } else if (jeniasAkses == 'delete') {
                                this.aksesRecords[index].akses_3 = true;
                            } else {
                                this.aksesRecords[index].akses_4 = true;
                            }

                        }
                    }
                })
                .catch((error) => {
                    if (aksesNumber == false) {
                        if (jeniasAkses == 'create') {
                            this.aksesRecords[index].akses_1 = false;
                        } else if (jeniasAkses == 'update') {
                            this.aksesRecords[index].akses_2 = false;
                        } else if (jeniasAkses == 'delete') {
                            this.aksesRecords[index].akses_3 = false;
                        } else {
                            this.aksesRecords[index].akses_4 = false;
                        }

                    } else {
                        if (jeniasAkses == 'create') {
                            this.aksesRecords[index].akses_1 = true;
                        } else if (jeniasAkses == 'update') {
                            this.aksesRecords[index].akses_2 = true;
                        } else if (jeniasAkses == 'delete') {
                            this.aksesRecords[index].akses_3 = true;
                        } else {
                            this.aksesRecords[index].akses_4 = true;
                        }

                    }
                    ErrorConnectionTimeOut(error);
                });
        },
        index() {
            this.records = []
            ShowLoading();
            let formData = new FormData();

            formData.append("previous_id", '');
            formData.append("last_id", '');
            formData.append("limit", this.limit);
            formData.append("tipe", this.tipeFilter);
            formData.append('query', this.query);


            // formData.append("kurikulum", this.kurikulumFilter);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // this.kurikulumList = response.data.kurikulum;


                        // this.prodiList = response.data.prodi;

                        if (response.data.data != undefined) {
                            this.records = response.data.data[1];

                            this.totalPages = response.data.data[0][0].number_page;
                            this.previousLastId = response.data.data[1][0].id;

                            let last_index = Object.keys(response.data.data[1]).length - 1;
                            this.NextLastId = response.data.data[1][last_index].id;

                            if (this.query != '') {
                                this.totalPages = 1;
                                this.numberPage = 1;
                            }

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        index_next_page(param1, param2) {


            ShowLoading();
            let formData = new FormData();
            formData.append("previous_id", "0");
            formData.append("last_id", param2);
            formData.append("limit", this.limit);
            formData.append("tipe", this.tipeFilter);
            formData.append('query', this.query);
            // formData.append("kurikulum", this.kurikulumFilter);
            // formData.append("prodi", this.prodiFilter);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        this.records = response.data.data[1];

                        this.totalPages = response.data.data[0][0].number_page;
                        this.previousLastId = response.data.data[1][0].id;

                        let last_index = Object.keys(response.data.data[1]).length - 1;
                        this.NextLastId = response.data.data[1][last_index].id;

                        this.numberPage = this.numberPage + 1;


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        index_before_page() {

            if (this.numberPage == 1) {
                return true
            }


            ShowLoading();
            let formData = new FormData();
            formData.append("url", "ra_admin_verifikasi_page");
            formData.append("previous_id", this.previousLastId);
            formData.append("last_id", "0");
            formData.append("limit", this.limit);
            formData.append("tipe", this.tipeFilter);
            formData.append('query', this.query);
            // formData.append("kurikulum", this.kurikulumFilter);
            // formData.append("prodi", this.prodiFilter);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.records = response.data.data[1];

                        this.totalPages = response.data.data[0][0].number_page;
                        this.previousLastId = response.data.data[1][0].id;

                        let last_index = Object.keys(response.data.data[1]).length - 1;
                        this.NextLastId = response.data.data[1][last_index].id;

                        this.numberPage = this.numberPage - 1;

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },


        insert() {
            ShowLoading();
            const formData = new FormData();


            Object.entries(this.newRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });

            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_insert";
            } else {
                this.api = base_url + this.url + "_insert";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response.data)
                    if (response.data.status == true) {


                        this.records.unshift(response.data.data);

                        // Clear the form inputs
                        this.newRecord = { nama: "", prodi: "", kurikulum: "", tahun_ajaran: "" };
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_tambah', 'close_modal_tambah')
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.showModal('modal_edit');

            this.setRecordwithId('tahun_ajaran', this.tahunAjaranList, 'tahun_ajaran', 'id');
            // const id_prodi = this.setRecordwithId('prodi', this.prodiList, 'nama', 'id');
            // this.setRecordwithId('kurikulum', this.kurikulumList, 'nama', 'id');
            // this.index(id_prodi);


        },


        update() {
            ShowLoading();
            const formData = new FormData();

            Object.entries(this.currentRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });
            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_update";
            } else {
                this.api = base_url + this.url + "_update";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response);
                    if (response.data.status == true) {
                        const index = this.records.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.currentRecord = {}
                        // this.$set(this.records, index, response.data.data);
                        this.records.splice(index, 1, response.data.data);

                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_edit', 'close_modal_edit');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd,) {
            if (this.currentRecord[field] != null || arraylist.length != 0) {
                const index = arraylist.findIndex((record) => record[fieldNameArray] == this.currentRecord[field]);
                this.currentRecord[field] = arraylist[index][valueWantAdd];
                return arraylist[index].id;
            } else {
                this.currentRecord[field] = "";
            }
        },
        deleteData() {

            ShowLoading();
            const formData = new FormData();
            formData.append("id", this.temporaryId);
            console.log(this.temporaryId)
            formData.append("url", "ra_rekomendasi_delete");
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_delete";
            } else {
                this.api = base_url + this.url + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)

                .then((response) => {
                    console.log(response);
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.records.findIndex((record) => record.id === this.temporaryId);
                        this.records.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_delete', 'close_modal_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        deleteRecord(id) {

            this.temporaryId = id;
            console.log(this.temporaryId)
            this.showModal('modal_delete');
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        }
    },

    mounted() {
        this.newRecord.tanggal_mulai = DateNow();
        this.newRecord.tanggal_selesai = DateNow();
    },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }

        //akses
        this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');
        this.delete_akses = menu_akses_cek(this.url, 'delete');

        this.index();

    }



}
</script>