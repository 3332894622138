<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar "
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="sticky-top bg-white">
                <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                    Isi Kuesioner
                </div>
                <div class="row mt-2 mb-2 me-0  bg-white">
                    <!-- <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                            id="tahuna_ajaran" v-model="tahunAjaranFilter" @change="pageActive = 1; offset = 0; index();">
                            <option value="" selected disabled>- Pilih Tahun ajaran</option>
                            <option v-for=" list  in  tahunAjaranList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="pageActive = 1; offset = 0; index();" required>
                            <option value="" selected disabled>- Pilih prodi</option>
                            <option v-for=" list  in  prodiList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="pageActive = 1; offset = 0; index()" required
                            :disabled="prodiFilter == ''">
                            <option value="" selected>- Pilih Semester</option>
                            <option v-for=" list  in  semesterList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div> -->

                    <div class="col-4">
                        <input type="text" v-model="query" @change="pageActive = 1; offset = 0; index()"
                            class="form-control" placeholder="Cari nama">
                    </div>
                    <!-- <div class="col-6 text-end ">
                        <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 " style="border-radius: 5px;"
                            @click="showModal2('modal_kuesioner_tambah')">
                            <img src="@/assets/icon/data_plus.png" alt="" width="15">
                            Tambah Kuesioner
                        </button>
                    </div> -->

                    <div class="d-flex align-items-start justify-content-start mt-2 gap-2"
                        v-if="paginglist.length != 0">
                        <div>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="limit" @change="pageActive = 1; offset = 0; index()" required>
                                <option v-for=" list  in  limitList " :key="list.value" :value="list.value"
                                    @change=index()>
                                    {{ list.value }}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center  align-items-center">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination" ref="pagi">
                                    <li class="page-item">
                                        <span class="page-link"
                                            @click="if (pageActive != 1) { pageActive--; offset = offset - limit; index('previous') };"
                                            aria-label="Previous" style="cursor:pointer;">
                                            <span aria-hidden="true">&laquo;</span>
                                        </span>
                                    </li>
                                    <li v-for=" list  in  paginglist " :key="list.nama" :value="list.value"
                                        class="page-item"
                                        :class="{ 'active': list.nama == pageActive, 'd-none': list.show == false }">
                                        <span class="page-link text-black "
                                            @click="if (list.disabled != true) pageActive = list.nama; offset = list.value; index()"
                                            style="cursor: pointer;">{{
                list.nama }}</span>
                                    </li>
                                    <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                                    <li class="page-item">
                                        <span class="page-link"
                                            @click="if (pageActive != totalPages) { pageActive++; offset = offset + limit; index('next') };"
                                            aria-label="Next" style="cursor:pointer;">
                                            <span aria-hidden="true">&raquo;</span>
                                        </span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>



                </div>
            </div>

            <div class="d-flex flex-column gap-2  ">
                <!-- <div class="fs-8 fw-bold font-custom-2 font-gray-custom">
                        >Desktop/Pengguna
                    </div> -->
                <div class="border  rounded-3 me-2 font-custom-1 mb-5 "
                    style="overflow-x:scroll; z-index: 0;  overflow-y: scroll; max-height: 65vh!important; ">
                    <table class="table mb-0 bg-transparent table-borderless" style="">
                        <thead class="sticky-top " style="top: 0px;">
                            <tr>
                                <th scope="col" class="fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7">Nama Kuesioner</th>
                                <th scope="col" class="font-custom-1 fs-7">Ditujukan</th>
                                <!-- <th scope="col" class="font-custom-1 fs-7">Terikat KRS</th> -->

                                <!-- <th scope="col" class="font-custom-1 fs-7">Status</th> -->
                                <th scope="col" class="font-custom-1 fs-7">Tanggal Mulai</th>
                                <th scope="col" class="font-custom-1 fs-7">Tanggal Selesai</th>
                                <!-- <th scope="col" class="font-custom-1 fs-7">Tahun Ajaran </th>
                                <th scope="col" class="font-custom-1 fs-7">Semester</th> -->
                                <th scope="col" class="font-custom-1 fs-7">Keterangan</th>
                                <th scope="col" class=" fs-7">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="( record, index ) in  records " :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ record.nama }}</td>
                                <td>{{ record.ditujukan }}</td>
                                <!-- <td>{{ record.terikat_krs }}</td> -->
                                <!-- <td>{{ record.keterangan }}</td> -->
                                <!-- <td>{{ record.status_kuisioner }}</td> -->
                                <td>{{ DateFormat(record.tanggal_mulai) }}</td>
                                <td>{{ DateFormat(record.tanggal_selesai) }}</td>
                                <!-- <td>{{ record.tahun_ajaran }}</td>
                                <td>{{ record.semester }}</td> -->
                                <td>{{ record.keterangan_kuesioner }}</td>
                                <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <button v-if="record.keterangan_kuesioner != 'Sudah Diisi'"
                                            class="btn btn-primary btn-sm fs-8"
                                            v-on:click="id_result_kuesioner = record.id; getSoalJawaban(record.id); editRecord(record); showModal2('modal_isi_kuesioner')"><i
                                                class='bx bxs-show fs-8'></i></button>
                                        <!-- 
                                            <button class="btn bg-blue-custom btn-sm fs-8 text-light"
                                            @click="editRecord(record); getSoalJawaban(record.id); showModal2('modal_list_soal')"
                                            v-if="update_akses == true"><i class='bx bx-list-ol fs-8'></i></button>

                                        <button class="btn bg-blue-custom btn-sm fs-8 text-light"
                                            @click="editRecord(record); getSoalJawaban(record.id); showModal2('modal_list_soal')"
                                            v-if="update_akses == true"><i class='bx bx-list-ol fs-8'></i></button>
                                        <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                            @click="editRecord(record); showModal2('modal_kuesioner_update')"><i
                                                class='bx bxs-edit fs-8'></i></button>
                                        <button v-if="delete_akses == true" class="btn btn-danger btn-sm fs-8"
                                            v-on:click="deleteRecord(record.id); showModal2('modal_kuisioner_delete')"><i
                                                class='bx bxs-trash-alt fs-8'></i></button> -->
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <form @submit.prevent="submitKuesioner">
            <div class="modal fade" id="modal_isi_kuesioner" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_isi_kuesioner">
                <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                    <div class="modal-content ">
                        <div class="modal-header bg-green-custom text-light">
                            <h5 class="modal-title" id="exampleModalScrollableTitle">List Soal Kuesioner {{
                    currentRecord.nama
                }}</h5>
                            <button type="button" class="btn bg-transparent text-light border-0">
                                <img src="@/assets/icon/close.png" alt="" width="30"
                                    @click="hideModal2('modal_isi_kuesioner')">
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row" v-if="currentRecord.terikat_krs == 'YA'">
                                <div class="col-3">
                                    <select class="form-select" aria-label="Default select example" name="prodi"
                                        id="prodi" v-model="dosenTargetFilterKuesioner"
                                        @change="getIsiKuesioner(currentRecord.id)" required>
                                        <option value="" selected disabled>- Pilih Target Dosen</option>
                                        <option v-for=" list  in  dosenTargetListKuesioner " :key="list.nama"
                                            :value="list.id">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-3">
                                    <select class="form-select" aria-label="Default select example" name="prodi"
                                        id="prodi" v-model="prodiFilterKuesioner"
                                        @change="getIsiKuesioner(currentRecord.id)"
                                        :disabled="dosenTargetFilterKuesioner == ''" required>
                                        <option value="" selected disabled>- Pilih Prodi</option>
                                        <option v-for=" list  in  prodiListKuesioner " :key="list.nama"
                                            :value="list.id">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>


                                <div class="col-3">
                                    <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                        id="mata_kuliah" v-model="MataKuliahFilterKuesioner"
                                        @change="getIsiKuesioner(currentRecord.id)"
                                        :disabled="prodiFilterKuesioner == ''" equired>
                                        <option value="" selected disabled>- Pilih Mata Kuliah</option>
                                        <option v-for=" list  in  mataKuliahListKuesioner " :key="list.nama"
                                            :value="list.id">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-3">
                                    <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                        id="kelas" v-model="kelasFilterKuesioner"
                                        @change="getIsiKuesioner(currentRecord.id)"
                                        :disabled="MataKuliahFilterKuesioner == ''" required>
                                        <option value="" selected disabled>- Pilih Kelas</option>
                                        <option v-for=" list  in  kelasListKuesioner " :key="list.nama"
                                            :value="list.id">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>

                            </div>
                            <div>
                                <div v-for="(list, index)  in  soalList " :key="list.id"
                                    class="mt-3 d-flex align-items-center gap-2 ">
                                    <div class="w-100 border px-2 py-2 rounded" v-if="list.status == 'AKTIF'">
                                        <div class="">
                                            <!-- <div class="mb-3">
                                            <select class="form-select" aria-label="Default select example" name="tipe"
                                                id="jenis_soal" v-model="list.tipe" @change="changeTipeSoal(list.id)"
                                                required :disabled="currentRecord.status_kuisioner == 'VERIFY'">
                                                <option value="" disabled>- Pilih Tipe</option>
                                                <option value="pg">Pilihan ganda</option>
                                                <option value="essay">Essay</option>
                                            </select>
                                        </div> -->
                                            <div class="w-100">
                                                <Label class="mb-3">{{ index + 1 }}. {{ list.nama }} </Label>
                                                <!-- <textarea class="form-control" style="overflow: hidden;"
                                                @input="adjustTextarea()" v-model="list.nama" :ref="list.id"
                                                :disabled="currentRecord.status_kuisioner == 'VERIFY'"></textarea> -->
                                            </div>
                                        </div>
                                        <div v-if="list.tipe == 'pg'">
                                            <div v-for="list2  in  list.jawaban " :key="list2.id">
                                                <div class="d-flex align-items-center  gap-2 mt-1"
                                                    v-if="list2.status == 'AKTIF'">
                                                    <div class="d-flex align-items-center w-100">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio"
                                                                :name="'jawaban' + list.id" :id="list2.id"
                                                                :value="list2.id" v-model="list.isiJawaban" required>
                                                            <label class="form-check-label" :for="list2.id">
                                                                {{ list2.nama }}
                                                            </label>
                                                        </div>

                                                        <!-- <textarea class="form-control" style="overflow: hidden;"
                                                        v-model="list2.nama" :key="list2.id"
                                                        :disabled="currentRecord.status_kuisioner == 'VERIFY'"></textarea> -->
                                                    </div>



                                                </div>

                                            </div>
                                        </div>
                                        <div v-if="list.tipe == 'essay'">
                                            <textarea class="form-control" required v-model="list.isiJawaban" />
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary"
                                @click="hideModal2('modal_isi_kuesioner')">Tutup</button>

                            <button type="submit" class="btn bg-green-custom text-light">
                                Simpan </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<style scoped>
.pagination {
    --bs-pagination-color: black !important;
}

.active>.page-link,
.page-link.active {
    background-color: #0D9044 !important;
    border-color: #0D9044 !important;
    color: white !important;
}

.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}

@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}

::-webkit-scrollbar {
    width: 0.6rem;
    border-radius: 0.5rem;
    background-color: rgba(63, 63, 63, 0.656);
    height: 0.8rem;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.473);
    border-radius: 0.5rem;
    width: 0.3rem;
}

::-webkit-scrollbar-thumb:hover {
    width: 0.3rem;
    background-color: rgba(255, 255, 255, 0.797);
}

.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>


import { AlertPopup, hariList, base_url, DateFormat, CloseLoading, ShowLoading, menu_akses_cek, ErrorConnectionTimeOut, AlertBottom, CharAndNumberOnly, DateNow, addNumberToObjects } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";

// import XLSX from "xlsx/dist/xlsx.full.min.js";

export default {
    data() {
        return {
            //data
            url: 'isi_kuesioner',
            api: "",

            //pages
            limit: 5,
            offset: 0,
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,
            currentPageActive: 1,
            pageActive: 1,


            limitList: [{
                value: 5
            }, {
                value: 10
            }, {
                value: 25
            }
            ],

            // prodiFilter: "",
            // semesterFilter: "",
            // tahunAjaranFilter: "",
            // hariFilter: "",
            // ditujukanFilter: "",
            semesterFilter: "",
            tahunAjaranFilter: "",
            query: "",


            records: [],
            newRecord: { nama: "", keterangan: "", tanggal_mulai: "", tanggal_selesai: "", ditujukan: "", status_kuisioner: "", terikat_krs: "TIDAK" },
            currentRecord: { nama: "", keterangan: "", tanggal_mulai: "", tanggal_selesai: "", ditujukan: "", status_kuisioner: "" },
            // beritaAcaraRecords: [],
            // mahasiswaRecords: [],
            // attendanceRecords: [],
            // penggantiList: [],
            paginglist: [],
            isLoad: 0,


            //modal berita acara
            batas_page_awal: 0,
            batas_page_akhir: 10,

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            temporaryStatusDosen: "",

            finalisasi: "",
            setujui: "",
            tipe: "",

            //SOAL KUESIONER
            soalList: [],
            jawabanList: [],

            // index result Kuesioner 
            numberPageKuesioner: 1,
            totalPagesKuesioner: 0,
            currentPageActiveKuesioner: 1,
            pageActiveKuesioner: 1,
            recordsKuesioner: [],
            currentRecordKuesioner: { nim_mahasiswa: "", nama_lengkap: "", },
            limitKuesioner: 5,
            offsetKuesioner: 0,
            batas_page_awalKuesioner: 0,
            batas_page_akhirKuesioner: 10,

            //
            prodiListKuesioner: [],
            waktuKuliahListKuesioner: [],
            dosenWaliListKuesioner: [],
            semesterListKuesioner: [],
            tahunAjaranListKuesioner: [],
            hariJadwalListKuesioner: [],

            kurikulumListKuesioner: [],

            //
            prodiFilterKuesioner: "",
            semesterFilterKuesioner: "",
            tahunAjaranFilterKuesioner: "",
            kurikulumFilterKuesioner: "",
            tipeFilterKuesioner: "",
            MataKuliahFilterKuesioner: "",
            dosenTargetFilterKuesioner: "",
            kelasFilterKuesioner: "",

            queryKuesioner: "",
            status_terima_Kuesioner: "",
            headerResultKuesioner: [],

            paginglistKuesioner: [],
            isLoadKuesioner: 0,


            //list mata kuliah kelas 
            dosenTargetListKuesioner: [],

            mataKuliahListKuesioner: [],
            kelasListKuesioner: [],
            id_kelas: "",
            id_mata_kuliah: "",


        }
    },
    components: {
        SideNavbar,

    },
    computed: {
        returnValueStatusDosen() {
            return this.currentRecordBeritaAcara.status_dosen ? 'TIDAK' : 'HADIR';
        }
    },

    methods: {
        addNumberToObjects,
        AlertBottom,


        // Soal 
        // adjustTextarea() {
        //     const textarea = event.target;
        //     textarea.style.height = 'auto'; // Reset the height to auto to calculate the new height
        //     textarea.style.height = `${textarea.scrollHeight}px`; // Set the new height based on scrollHeight


        // },
        getIsiKuesioner(id) {
            ShowLoading();

            let formData = new FormData();
            formData.append("id", id);
            formData.append("id_prodi", this.prodiFilterKuesioner);
            formData.append("id_kelas", this.kelasFilterKuesioner);
            formData.append("id_mata_kuliah", this.MataKuliahFilterKuesioner);
            formData.append("id_target_dosen", this.dosenTargetFilterKuesioner);

            // formData.append("id_mata_kuliah", this.id_mata_kuliah);


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'isi_kuesioner' + '_get';
            } else {
                this.api = base_url + 'isi_kuesioner' + '_get';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {

                        if (response.data.data != undefined) {
                            this.dosenTargetListKuesioner = response.data.data[0];
                            this.prodiListKuesioner = response.data.data[1];
                            this.mataKuliahListKuesioner = response.data.data[2];
                            this.kelasListKuesioner = response.data.data[3];
                        }

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        this.hideModal2('modal_isi_kuesioner');
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        submitKuesioner() {
            // console.log(this.soalList);
            // console.log(this.currentRecord);

            if (this.soalList.length == 0) {
                AlertPopup("error", "", "soal masih kosong", 1500, false);
                return true;
            }


            if (this.currentRecord.terikat_krs == 'TIDAK') {
                this.id_kelas = "";
                this.id_mata_kuliah = "";
            }


            ShowLoading();
            const formData = new FormData();

            formData.append('data', JSON.stringify(this.soalList));
            formData.append('id_kelas', this.kelasFilterKuesioner);
            formData.append('id_mata_kuliah', this.MataKuliahFilterKuesioner);
            formData.append('id_dosen', this.dosenTargetFilterKuesioner);
            formData.append('id', this.currentRecord.id);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'hasil_kuesioner' + "_insert";
            } else {
                this.api = base_url + 'hasil_kuesioner' + "_insert";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.id_kelas = "";
                        this.id_mata_kuliah = "";
                        // Clear the form inputs
                        CloseLoading();
                        // AlertBottom(response.data.message);
                        const index = this.records.findIndex(
                            (record) => record.id == response.data.data[0].id
                        );

                        this.currentRecord = {}
                        // this.$set(this.records, index, response.data.data);
                        this.records.splice(index, 1, response.data.data[0]);
                        this.hideModal2('modal_isi_kuesioner');
                        AlertBottom(response.data.message);
                        // this.hideModal2('modal_list_soal');
                    }
                    if (response.data.status == false) {

                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        capitalizeWords(str) {
            return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        },
        CharAndNumberOnly,
        DateFormat,
        DateNow,

        async index(status) {
            this.paginglist = [];
            ShowLoading();
            this.records = [];


            let formData = new FormData();

            // formData.append("ditujukan", this.ditujukanFilter);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("semester", this.semesterFilter);
            formData.append("limit", this.limit);
            formData.append("offset", this.offset);
            formData.append("query", this.query);


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (response.data.data != undefined) {

                            this.records = response.data.data[1];
                            this.totalPages = response.data.data[0][0].number_page;

                            this.tahunAjaranListKuesioner = response.data.data[2];
                            this.semesterListKuesioner = response.data.data[3];
                            let limit = this.limit;

                            this.pageActive;
                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limit;
                                }

                                if (i <= this.batas_page_akhir && i > this.batas_page_awal) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);
                            }


                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhir] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActive - 1].key) {

                                    page_list_array[this.batas_page_awal].show = false;
                                    page_list_array[this.batas_page_akhir].show = true;

                                    this.batas_page_awal++;
                                    this.batas_page_akhir++;

                                } else if (this.pageActive != 1) {

                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActive - 1].key) {
                                        this.batas_page_awal--;
                                        this.batas_page_akhir--;
                                        page_list_array[this.batas_page_awal].show = true;
                                        page_list_array[this.batas_page_akhir].show = false;
                                    }

                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActive - 1].key) && page_list_array[this.batas_page_akhir] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActive != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    this.paginglist.push(page_list_array[i]);
                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPages,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }
                                // console.log(page_list_array[index_last_true]);
                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglist.push(pemisahPages);
                                    this.paginglist.push(totalPages);
                                }

                            }

                            // this.previousLastId = response.data.data[1][0].id;

                            // let last_index = Object.keys(response.data.data[1]).length - 1;
                            // this.NextLastId = response.data.data[1][last_index].id;

                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        editRecord(record) {
            // Set the current record to edit mode
            this.prodiFilterKuesioner = "";
            this.MataKuliahFilterKuesioner = "";
            this.kelasFilterKuesioner = "";
            this.dosenTargetFilterKuesioner = "";
            this.currentRecord = Object.assign({}, record);
            // console.log(record);
            if (this.currentRecord.terikat_krs == 'YA') {
                this.getIsiKuesioner(record.id)
            }

        },
        setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd) {
            if (this.currentRecord[field] != null) {
                const index = arraylist.findIndex((record) => record[fieldNameArray] == this.currentRecord[field]);
                this.currentRecord[field] = arraylist[index][valueWantAdd];
            } else {
                this.currentRecord[field] = "";
            }
        },
        async getSoalJawaban(id) {
            ShowLoading();
            this.soalList = [];

            let formData = new FormData();

            formData.append("limit", 100);
            formData.append("offset", 0);
            formData.append("query", "");
            formData.append("id", id);


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'kuesioner_soal_jawaban' + '_get';
            } else {
                this.api = base_url + 'kuesioner_soal_jawaban' + '_get';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {


                        if (response.data.data != undefined) {



                            (JSON.parse(response.data.data)).forEach(item => {
                                const prepare_element = { ...item, isiJawaban: "" }
                                this.soalList.push(prepare_element);


                            });


                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        this.hideModal2('modal_isi_kuesioner');
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },

        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        showModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';


            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')

            setTimeout(() => {
                this.$refs[modal_name].classList.remove('show');
                this.$refs[modal_name].style.display = 'none';
            }, 150)
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        deleteRecord(id, status_dosen) {

            this.temporaryId = id;
            this.temporaryStatusDosen = status_dosen;
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        }
    },

    // mounted() {

    // },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
        //akses
        this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');

        this.delete_akses = menu_akses_cek(this.url, 'delete');

        this.index();
        this.hariJadwalList = hariList;
        this.newRecord.tanggal_mulai = DateNow();
        this.newRecord.tanggal_selesai = DateNow();
        this.tipe = localStorage.getItem('tipe_user');

    }



}
</script>