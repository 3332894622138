<template>
  <router-view />
</template>

<script>
// import { initializeApp } from "firebase/app";
// import { , } from "firebase/messaging/sw";
// import { getMessaging,getToken, onMessage } from "firebase/messaging";

// const firebaseConfig = {
//   apiKey: "AIzaSyA25t0zJZI9Nm-pgSX2c-edgazqLa7A2So",
//   authDomain: "stbhinneka-3078f.firebaseapp.com",
//   projectId: "stbhinneka-3078f",
//   storageBucket: "stbhinneka-3078f.appspot.com",
//   messagingSenderId: "420158893086",
//   appId: "1:420158893086:web:8bfe5097840ee0e2d4d20f",
//   measurementId: "G-S5DB0XPB6H"
// };

// initializeApp(firebaseConfig);
// const messaging = getMessaging(initializeApp(firebaseConfig));

// console.log('Requesting permission...');
// Notification.requestPermission().then((permission) => {
//   if (permission === 'granted') {
//     console.log('Notification permission granted.');
//   } else {
//     console.warn("Izin notifikasi tidak diberikan.");
//   }
// })

// onMessage(messaging, (payload) => {
//   console.log('Message received. ', payload);
// })

// getToken(messaging, { vapidKey: 'BEw-dDrNI77W-KJswnV_z54YhWrFkMNCiuxXiZjaWqP9B3fe_KEOL-EMU5ZohJAxJoO9jBalJy4jTFUQclTQzoY' }).then((currentToken) => {
//   if (currentToken) {
//     // Send the token to your server and update the UI if necessary
//     console.log('token is :' + currentToken);
//     localStorage.setItem('token_firebase', currentToken);

//     // ...
//   } else {
//     // Show permission request UI
//     console.log('No registration token available. Request permission to generate one.');
//     // ...
//   }
// }).catch((err) => {
//   console.log('An error occurred while retrieving token. ', err);
//   // ...
// });

export default {
  name: "PortalAkademik",
  components: {},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Montserrat:wght@400;700&family=Raleway&family=Roboto&display=swap");

/* font-family: 'Inter', sans-serif;
font-family: 'Montserrat', sans-serif;
 */
/* inter, monserrat, raleway */
.font-custom-1 {
  font-family: "Inter", sans-serif;
}

.font-custom-2 {
  font-family: "Montserrat", sans-serif;
}

.font-custom-3 {
  font-family: "Raleway", sans-serif;
}

.font-custom-4 {
  font-family: "Roboto", sans-serif !important;
}

.fs-17-px {
  font-size: 17px !important;
}

.fs-7 {
  font-size: 14px !important;
}

.fs-8 {
  font-size: 12px !important;
}

.fs-9 {
  font-size: 10px !important;
}

.font-green-custom {
  color: #0d9044 !important;
}

.bg-green-custom {
  background-color: #0d9044 !important;
}

.bg-green-custom-checked:checked {
  background-color: #0d9044 !important;
}

.bg-border-green-custom {
  border-color: #0d9044 !important;
}

.bg-green-2-custom {
  background-color: #2db684;
}

.bg-blue-custom {
  background-color: #2e4495 !important;
}

.bg-custom-gray {
  background-color: #00000096 !important;
}

.bg-blue-hover-custom {
  background-color: #63744e;
}

.bg-yellow-custom {
  background-color: #eab543;
}

.font-gray-custom {
  color: #aea7a7 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.animation-duration-item-navbar {
  transition: all 0.3s ease !important;
}

::-webkit-scrollbar {
  width: 0.4rem;
  border-radius: 0.5rem;
  background-color: rgba(63, 63, 63, 0.656);
  height: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.473);
  border-radius: 0.5rem;
  width: 0.3rem;
}

::-webkit-scrollbar-thumb:hover {
  width: 0.3rem;
  background-color: rgba(255, 255, 255, 0.797);
}
</style>
