<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                Profile
            </div>
        </div>
        <div class="row mx-2">
            <div class="col-2  pt-2">
                <div class="d-flex justify-content-center align-items-center">
                    <img :src="records.profile_picture" alt="" width="164" height="164" class="rounded rounded-circle">
                </div>
                <div class="text-center pt-3">
                    <div class="font-custom-2 fs-6 fw-bold">
                        {{ nama_lengkap }}
                    </div>
                    <div class="font-custom-2 fs-7">
                        NIP - {{ records.nip }}
                    </div>
                    <div class="font-custom-2 fs-7">
                        NIDN - {{ records.nidn }}
                    </div>
                </div>
                <div class="text-center pt-2">
                    <button class="btn btn-sm btn-warning fs-8 py-1 text-light font-custom-4 mb-2"
                        @click="showModal2('modal_edit_profile')"><i class='bx bx-edit-alt'></i>
                        Edit Data </button>
                    <button class="btn btn-sm btn-secondary fs-8 py-1 text-light font-custom-4"
                        @click="change_password()"><i class='bx bxs-lock-alt' data></i> Ganti
                        Password</button>
                </div>
            </div>
            <div class="col-10 ">
                <div class="border mt-1 rounded-3 me-2 font-custom-1">
                    <table class="table mb-0 bg-transparent table-borderless " style="">
                        <thead>
                            <tr class="border-bottom">
                                <th scope="col" class="bg-transparent font-custom-1 fs-7">DATA PRIBADI</th>
                                <th scope="col" class="bg-transparent  fs-7"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-bottom ">
                                <th scope="row" class="bg-transparent font-custom-1 fw-light fs-7 font-gray-custom">
                                    Tanggal
                                    lahir</th>
                                <td class="font-custom-1 fw-light fs-7 font-gray-custom"> {{
                                    this.DateFormat(records.tanggal_lahir) }} </td>
                            </tr>
                            <tr class="border-bottom ">
                                <th scope="row" class="bg-transparent font-custom-1 fw-light fs-7 font-gray-custom">
                                    Email / Whatsapp</th>
                                <td class="font-custom-1 fw-light fs-7 font-gray-custom">{{ records.email }} / {{
                                    records.whatsapp }}</td>
                            </tr>
                            <tr class="border-bottom ">
                                <th scope="row" class="bg-transparent font-custom-1 fw-light fs-7 font-gray-custom">
                                    Jenis Kelamin</th>
                                <td class="font-custom-1 fw-light fs-7 font-gray-custom">{{ records.jenis_kelamin }}
                                </td>
                            </tr>
                            <tr class="border-bottom ">
                                <th scope="row" class="bg-transparent font-custom-1 fw-light fs-7 font-gray-custom">
                                    Agama</th>
                                <td class="font-custom-1 fw-light fs-7 font-gray-custom">{{ records.agama }}</td>
                            </tr>
                            <tr class="border-bottom " v-if="tipe == 'dosen'">
                                <th scope="row" class="bg-transparent font-custom-1 fw-light fs-7 font-gray-custom">
                                    Prodi</th>
                                <td class="font-custom-1 fw-light fs-7 font-gray-custom">{{ records.prodi }}</td>
                            </tr>
                            <tr class="border-bottom " v-if="tipe == 'dosen'">
                                <th scope="row" class="bg-transparent font-custom-1 fw-light fs-7 font-gray-custom">
                                    Bidang</th>
                                <td class="font-custom-1 fw-light fs-7 font-gray-custom">{{ records.bidang }}</td>
                            </tr>
                            <tr class="border-bottom ">
                                <th scope="row" class="bg-transparent font-custom-1 fw-light fs-7 font-gray-custom">
                                    Status {{ tipe }}</th>
                                <td class="font-custom-1 fw-light fs-7 font-gray-custom">{{ records.status_dosen }}</td>
                            </tr>
                            <tr class="border-bottom " v-if="tipe == 'dosen'">
                                <th scope="row" class="bg-transparent font-custom-1 fw-light fs-7 font-gray-custom">
                                    Jabatan
                                    Jabatan Struktural</th>
                                <td class="font-custom-1 fw-light fs-7 font-gray-custom">{{ records.jabatan_struktural
                                    }}
                                </td>
                            </tr>
                            <tr class="border-bottom " v-if="tipe == 'dosen'">
                                <th scope="row" class="bg-transparent font-custom-1 fw-light fs-7 font-gray-custom">
                                    Jabatan Fungsional</th>
                                <td class="font-custom-1 fw-light fs-7 font-gray-custom">{{ records.jabatan_fungsional
                                    }}
                                </td>
                            </tr>
                            <tr class="border-bottom " v-if="tipe == 'dosen'">
                                <th scope="row" class="bg-transparent font-custom-1 fw-light fs-7 font-gray-custom">
                                    Golongan</th>
                                <td class="font-custom-1 fw-light fs-7 font-gray-custom">{{ records.golongan }}</td>
                            </tr>
                            <tr class="border-bottom " v-if="tipe == 'dosen'">
                                <th scope="row" class="bg-transparent font-custom-1 fw-light fs-7 font-gray-custom">
                                    No Hp Darurat 1/ Pemilik</th>
                                <td class="font-custom-1 fw-light fs-7 font-gray-custom">{{ records.no_darurat }}/ {{
                                    records.pemilik_no_darurat }}</td>
                            </tr>
                            <tr class="border-bottom " v-if="tipe == 'dosen'">
                                <th scope="row" class="bg-transparent font-custom-1 fw-light fs-7 font-gray-custom">
                                    No Hp Darurat 2/ Pemilik</th>
                                <td class="font-custom-1 fw-light fs-7 font-gray-custom">{{ records.no_darurat_2 }}/ {{
                                    records.pemilik_no_darurat_2 }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <form @submit.prevent="update">
            <div class="modal fade" id="modal_edit_profile" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_edit_profile">
                <div class="modal-dialog modal-fullscreen" role="document">
                    <div class="modal-content ">
                        <div class="modal-header bg-green-custom text-light">
                            <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Data Profile</h5>
                            <button hidden type="button" aria-label="Close" ref="close_modal_edit_profile"></button>
                            <button type="button" class="btn bg-transparent text-light border-0">
                                <img src="@/assets/icon/close.png" alt="" width="30"
                                    @click="hideModal2('modal_edit_profile')">
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="mb-3" v-if="tipe == 'dosen' || tipe == 'pegawai'">
                                <label for="tanggal_lahir" class="form-label">Tanggal Lahir</label>
                                <input type="date" class="form-control" id="tanggal_lahir"
                                    v-model="records.tanggal_lahir">
                            </div>
                            <div class="mb-3" v-if="tipe == 'dosen' || tipe == 'pegawai'">
                                <label for="email" class="form-label">Email</label>
                                <input type="email" class="form-control" id="email" v-model="records.email">
                            </div>
                            <div class="mb-3" v-if="tipe == 'dosen' || tipe == 'pegawai'">
                                <label for="whatsapp" class="form-label">Whatsapp</label>
                                <input type="phone" class="form-control" id="whatsapp" v-model="records.whatsapp">
                            </div>
                            <div class="mb-3" v-if="tipe == 'dosen' || tipe == 'pegawai'">
                                <label for="jenis_kelamin" class="form-label"> Jenis Kelamin<span
                                        class="text-danger">*</span></label>
                                <select class="form-select" aria-label="Default select example" name="jenis_kelamin"
                                    id="jenis_kelamin" v-model="records.jenis_kelamin" required>
                                    <option value="" selected disabled>- Pilih Jenis Kelamin</option>
                                    <option v-for="list in jenisKelaminList" :key="list.nama" :value="list.nama">
                                        {{ list.nama }}
                                    </option>
                                </select>
                            </div>
                            <div class="mb-3" v-if="tipe == 'dosen' || tipe == 'pegawai'">

                                <label for="agama" class="form-label"> Agama<span class="text-danger">*</span></label>
                                <select class="form-select" aria-label="Default select example" name="agama" id="agama"
                                    v-model="records.agama" required>
                                    <option value="" selected disabled>- Pilih Agama</option>
                                    <option v-for="list in agamaList" :key="list.nama" :value="list.nama">
                                        {{ list.nama }}
                                    </option>
                                </select>

                                <!-- <label for="agama" class="form-label">Agama</label>
                                <input type="jenis_kelamin" class="form-control" id="jenis_kelamin" v-model="records.agama"> -->
                            </div>
                            <div class="mb-3" v-if="tipe == 'dosen'">
                                <label for="whatsapp" class="form-label">Pemilik No Darurat</label>
                                <input type="text" class="form-control" id="whatsapp"
                                    v-model="records.pemilik_no_darurat">
                            </div>
                            <div class="mb-3" v-if="tipe == 'dosen'">
                                <label for="whatsapp" class="form-label">No Hp Darurat</label>
                                <input type="phone" class="form-control" id="whatsapp" v-model="records.no_darurat">
                            </div>

                            <div class="mb-3" v-if="tipe == 'dosen'">
                                <label for="whatsapp" class="form-label">Pemilik No Darurat 2</label>
                                <input type="text" class="form-control" id="whatsapp"
                                    v-model="records.pemilik_no_darurat_2">
                            </div>
                            <div class="mb-3" v-if="tipe == 'dosen'">
                                <label for="whatsapp" class="form-label">No Hp Darurat 2</label>
                                <input type="phone" class="form-control" id="whatsapp" v-model="records.no_darurat_2">
                            </div>
                            <div class="mb-3">
                                <label for="gambar_update" class="form-label">Gambar Profil</label>
                                <input type="file" class="form-control" id="gambar_update" ref="gambar_update"
                                    accept="image/*">
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary"
                                @click="hideModal2('modal_edit_profile')">Batal
                            </button>
                            <button type="submit" class="btn bg-danger text-light">
                                Simpan</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="modal fade" id="modal_change_password" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_change_password">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Konfirmasi password baru</h5>
                        <button hidden type="button" aria-label="Close" ref="close_modal_change_password"></button>
                        <button type="button " class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_change_password')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="password_1" class="form-label">Masukan password baru :</label>
                            <input type="password" class="form-control" id="password_1" v-model="password_1">
                        </div>
                        <div class="mb-3">
                            <label for="password_2" class="form-label">Konfirmasi password :</label>
                            <input type="password" class="form-control" id="password_2" v-model="password_2">
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_change_password')">Batal
                        </button>
                        <button type="button" class="btn bg-danger text-light" @click="change_password_user">
                            Simpan</button>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="modal_confirm_otp" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_confirm_otp">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Konfirmasi Kode OTP</h5>
                        <button hidden type="button" ref="close_modal_confirm_otp"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_confirm_otp')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <label for="kode_otp" class="form-label">Masukan kode OTP :</label>
                        <input type="text" class="form-control" id="kode_otp" @change="CharOnly" v-model="kode_otp">

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_confirm_otp')">Batal</button>
                        <button type="button" class="btn bg-danger text-light" @click="confirm_otp">
                            Cek</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s linear;
}

@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(0.9);
        opacity: 0.5;
    }

    100% {
        transform: scale(0.5);
        opacity: 0;
    }
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import SideNavbar from "@/components/SideNavbar.vue";
import { ShowLoading, ErrorConnectionTimeOut, DateFormat, AlertPopup, CloseLoading, base_url, CharOnly, jenisKelaminList, agamaList, refreshPage } from "@/assets/javascript/function";
import axios from "axios";
import Cookies from "js-cookie";


export default {
    data() {
        return {
            isShowNavbar: true,
            temporaryId: "",
            nama_lengkap: "",
            api: "",
            kode_otp: "",
            password_1: "",
            password_2: "",
            records: [],
            tipe: "",

            jenisKelaminList: "",
            agamaList: "",
        }

    },
    components: {
        SideNavbar
    },

    methods: {
        CharOnly,
        DateFormat,
        change_password_user() {
            ShowLoading();
            let formData = new FormData();

            if (this.password_1 != this.password_2) {
                AlertPopup("error", "", "Password tidak sama silahkan coba kembali", 1500, false);
                return true;
            }

            formData.append('password', this.password_1);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "change_password_user";
            } else {
                this.api = base_url + "change_password_user";
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        AlertPopup("success", "", response.data.message, 1500, false);
                        this.hideModal2('modal_change_password');
                        localStorage.setItem('statuspass', '1')
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        change_password() {
            ShowLoading();
            let formData = new FormData();

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "change_password";
            } else {
                this.api = base_url + "change_password";
            }

            formData.append('status', 'kirim_otp_lagi');
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        AlertPopup("success", "", response.data.message, 1500, false);
                        this.showModal2('modal_confirm_otp');
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        confirm_otp() {
            ShowLoading();
            let formData = new FormData();

            formData.append("otp", this.kode_otp);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "confirm_otp";
            } else {
                this.api = base_url + "confirm_otp";
            }



            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        CloseLoading();
                        this.hideModal2('modal_confirm_otp');
                        this.showModal2('modal_change_password');
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        showModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
            console.log(this.$refs[modal_name]);
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')

            setTimeout(() => {
                this.$refs[modal_name].classList.remove('show');
                this.$refs[modal_name].style.display = 'none';
            }, 150)
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        update() {
            ShowLoading();
            let formData = new FormData();
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "profile_update";
            } else {
                this.api = base_url + "profile_update";
            }
            if (this.$refs.gambar_update.files[0] == undefined) {
                formData.append("gambar", localStorage.getItem('profile_picture'));
            } else {
                formData.append("gambar_update", this.$refs.gambar_update.files[0]);
            }


            Object.entries(this.records).forEach(([key, value]) => {
                formData.append(key, value);
            });

            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        this.records = response.data.data;
                        // AlertBottom(response.data.message);
                        refreshPage();


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        get_profile() {
            ShowLoading();
            let formData = new FormData();

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "profile_get";
            } else {
                this.api = base_url + "profile_get";
            }



            formData.append('status', 'kirim_otp_lagi');
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        this.records = response.data.data;
                        this.tipe = response.data.tipe;
                        if (localStorage.getItem('statuspass') == '0') {
                            AlertPopup('info', 'Silahkan ganti password Anda!', 'klik tombol ganti password', 0, true);
                        } else {
                            CloseLoading();
                        }
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 250)

        },
        deleteRecord(id) {
            this.temporaryId = id
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        }
    },

    mounted() {
        this.nama_lengkap = localStorage.getItem('nama_user');
        this.jenisKelaminList = jenisKelaminList;
        this.agamaList = agamaList;
    },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
        this.get_profile();



        // this.index();
        // if (localStorage.getItem('id_progress_krs') != null) {
        //     this.interval = setInterval(() => {
        //         this.getProgresKrs(localStorage.getItem('id_progress_krs'));
        //     }, 1000);
        // }
    }
}
</script>