<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold sticky-top">
                Kalender Akademik
            </div>
            <div class="d-flex flex-column gap-2 mt-2">
                <div class="row me-0">
                    <div class="col-12 text-end mb-1">
                        <button v-if="update_akses == true"
                            class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 " style="border-radius: 5px;"
                            data-bs-toggle="modal" data-bs-target="#modal_edit" @click="showModal('modal_edit')">
                            <img src="@/assets/icon/kalender_akademik.png" alt="" width="15">
                            Edit
                        </button>
                    </div>
                </div>
            </div>

            <div class="border mt-1 rounded-3 me-2 font-custom-1 mb-5 overflow-y-scroll table-v-html"
                style="max-height: 77vh;" v-html="records.isi">
            </div>
        </div>
    </div>



    <div class="modal fade" id="modal_edit" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_edit">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Kalender Akademik</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_edit"></button>
                    <button type="button " class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_edit', 'close_modal_edit')">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <Editor api-key="01hjrmvp8caessqt3d5sgjy7o603r4a2yhxwux7tj3ri9095" :init="{
                            plugins: 'lists link image table code help wordcount',
                        }" v-model="kalender_akademik" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_edit', 'close_modal_edit')">Tutup</button>
                    <button type="button" class="btn bg-green-custom text-light" @click="update()">
                        Simpan</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.padding-body-show-navbar {
    padding-left: 190px !important;
}

.tox .tox-statusbar__branding svg {
    display: none !important;
}



.tox-notifications-container {
    display: none !important;
}



.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s linear;
}

@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(0.9);
        opacity: 0.5;
    }

    100% {
        transform: scale(0.5);
        opacity: 0;
    }
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


/* .table tbody tr:last-child {
    border: 0px white !important;
} */

.table-v-html :deep(table) {
    margin-bottom: 0;
    background-color: white !important;
    font-family: 'Montserrat', sans-serif;
}

.table-v-html :deep(table tr:first-child td) {
    border-bottom: 1px solid #dee2e6 !important;
    border-top: 1px solid #dee2e6 !important;
    position: sticky !important;
    top: -2px !important;
    background-color: white !important;
    font-family: 'Inter', sans-serif;
    font-size: 16px !important;
    font-weight: 600 !important;

}

.table-v-html :deep(tr) {
    border: 1px solid #ccc;
    padding: 10px;
}

.table-v-html :deep(td) {
    height: 20px;
    padding: 10px;
}
</style>

<script>
import SideNavbar from "@/components/SideNavbar.vue";
import { CloseLoading, AlertPopup, ErrorConnectionTimeOut, menu_akses_cek, AlertBottom, base_url, ShowLoading } from "@/assets/javascript/function";
import axios from "axios";
import Cookies from "js-cookie";
import Editor from '@tinymce/tinymce-vue';

export default {
    data() {
        return {
            isShowNavbar: true,
            temporaryId: "",
            records: [{
                id: 1,
                nama: "1",
                email: "1",
                eIDN: "1",
                noHandphone: "1",
                keahlian: "1",
                tipe: "1",
            }, {
                id: 2,
                nama: "2",
                email: "2",
                eIDN: "2",
                noHandphone: "2",
                keahlian: "2",
                tipe: "2",
            }],
            kalender_akademik: "",
            kalender_akademik_convert: "",
            url: "kalender_akademik",

            create_akses: false,
            update_akses: false,
            delete_akases: false,
            //tinymce
            editorContent: "",
            intervalId: "",
            intervalId2: "",
        }
    },
    components: {
        SideNavbar, Editor
    },

    methods: {
        //antimation
        index() {

            ShowLoading();
            let formData = new FormData();

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_get';
            } else {
                this.api = base_url + this.url + '_get';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (response.data.data != undefined) {
                            this.records = response.data.data;
                            this.kalender_akademik = response.data.data.isi
                        }

                        CloseLoading();

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')
            this.intervalId2 = setInterval(() => {
                this.deleteIconTinyMce();
            }, 100);




        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 250)
        },
        deleteRecord(id) {
            this.temporaryId = id
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        },
        // ExcelToHTMLTable() {
        //     const rows = this.kalender_akademik.split('\n');
        //     let tableHTML = '<table border="1">\n';
        //     let isFirstRow = true; // Flag to check if it's the first row

        //     rows.forEach((row) => {
        //         if (isFirstRow) {
        //             tableHTML += '<thead>\n';
        //             isFirstRow = false; // Mark the first row as processed
        //         } else {
        //             tableHTML += '<tbody>\n';
        //         }

        //         tableHTML += '<tr>\n';
        //         const cells = row.split('\t');

        //         cells.forEach((cell) => {
        //             tableHTML += `<td>${cell}</td>`;
        //         });

        //         tableHTML += '</tr>\n';

        //         if (isFirstRow) {
        //             tableHTML += '</thead>\n';
        //         } else {
        //             tableHTML += '</tbody>\n';
        //         }
        //     });

        //     tableHTML += '</table>';
        //     return tableHTML;
        // },

        update() {

            ShowLoading();
            let formData = new FormData();
            formData.append('kalender_akademik', this.kalender_akademik);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_update';
            } else {
                this.api = base_url + this.url + '_update';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (response.data.data != undefined) {
                            this.records = response.data.data;
                            // this.kalender_akademik = this.records.isi;
                        }
                        CloseLoading();
                        AlertBottom(response.data.message);
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        deleteNotifTinyMce() {
            const elements = document.querySelector('.tox-notifications-container');
            if (elements != undefined) {
                elements.classList.add('d-none');
                clearInterval(this.intervalId);
            }
        },
        deleteIconTinyMce() {
            const elements = document.querySelector('.tox-statusbar__branding');
            if (elements != undefined) {
                elements.classList.add('d-none');
                clearInterval(this.intervalId2);
            }
        }

    },

    created() {
        this.token = Cookies.get("token");

        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }

        //akses
        // this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');
        // this.delete_akses = menu_akses_cek(this.url, 'delete');

        this.intervalId = setInterval(() => {
            this.deleteNotifTinyMce();
        }, 100);



        this.index();

    },


}
</script>