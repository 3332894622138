<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold sticky-top ">
                Home
            </div>
            <div class="d-flex flex-column gap-2 mt-2 ">
                <!-- <div class="fs-8 fw-bold font-custom-2 font-gray-custom">
                        >Desktop/Pengguna
                    </div> -->
                <!-- paging berita -->
                <div class="row me-0" v-if="seePage == false">
                    <div class="col-12 text-center">
                        <div class="d-flex justify-content-center  align-items-center">
                            <div>
                                <i class='bx bx-chevron-left fs-7' @click="index_before_page()"
                                    style="cursor: pointer;"></i>
                            </div>
                            <div class="fs-7">
                                {{ numberPage }}/{{ totalPages }}
                            </div>
                            <div>
                                <i class='bx bx-chevron-right fs-7' @click="index_next_page(previousLastId, NextLastId)"
                                    style="cursor: pointer;"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" mt-1  me-2 font-custom-1 row hidden-md-up" v-if="seePage == false">
                    <div class="col-3 text-white font-custom-1 " v-for="(record) in records" :key="record.id"
                        :ref="record.id">
                        <div class="h-100">
                            <div class="overflow-hidden w-100" style="height:  100px;" v-if="record.gambar != ''">
                                <img :src="record.gambar" alt="" class="w-100 h-100" style="object-fit: cover;">
                            </div>
                            <div class="bg-green-2-custom px-2 py-2 rounded-bottom-1 d-flex flex-column justify-content-between"
                                style="min-height: 170px;">
                                <div>
                                    <div class=" truncate-lines fs-6 fw-bold mb-1 pt-1">
                                        {{ record.judul }}
                                    </div>
                                    <div class="truncate-lines fs-8 mb-1">
                                        {{ removeHtmlTags(record.isi) }}
                                    </div>
                                </div>


                                <div class="row ">
                                    <div class=" fs-8 mb-1">
                                        {{ DateFormat(record.created_at) }}
                                    </div>
                                    <div class="col-6 d-flex align-items-center justify-content-start fs-7">
                                        {{ record.nama_lengkap }}
                                    </div>
                                    <div class="col-6 d-flex justify-content-end align-items-center"
                                        style="cursor: pointer;" @click="getBerita(record.id);">
                                        <div class="bg-black py-2  d-flex align-items-center justify-content-center fs-8"
                                            style="width: 80%;">
                                            Lihat
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class=" sticky-top" v-if="seePage == true" style="top: 50px;">
                    <div class=" d-flex ">
                        <div class="bg-white rounded-1 px-1 " style="cursor:  pointer;" @click="seePage = false">
                            <i class='bx bx-arrow-back fs-4'></i>
                        </div>
                    </div>
                </div>

                <!-- see one page berita -->
                <div v-if="seePage == true" class="me-3 ">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="overflow-hidden " style="height:  200px; width: 25%;" v-if="currentRecord.gambar != ''">
                            <img :src="currentRecord.gambar" alt="" class="w-100 h-100" style="object-fit: cover;">
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between  mt-3">
                        <div class="fs-4 fw-bold font-custom-1">
                            {{ currentRecord.judul }}
                        </div>
                        <div class="d-flex flex-column">
                            <div class="fs-6 fw-bold">
                                {{ currentRecord.nama_lengkap }} ({{ currentRecord.tipe }})
                            </div>
                            <div class="fs-8">
                                {{ DateFormat(currentRecord.created_at) }}
                            </div>
                        </div>

                    </div>
                    <div class="d-flex align-items-center justify-content-center fs-7  font-custom-1 mt-3 mb-5">
                        <div v-html="currentRecord.isi"></div>
                    </div>


                </div>


            </div>

        </div>

    </div>
</template>

<style scoped>
.truncate-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, base_url, CloseLoading, ShowLoading, removeHtmlTags, ErrorConnectionTimeOut, CharAndNumberOnly, DateFormat } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";

export default {
    data() {
        return {
            //data
            url: 'kelola_berita',
            url2: 'berita',
            api: "",

            //pages
            limit: "15",
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,

            ditujukanKepadaList: [
                {
                    nama: "dosen"
                },
                {
                    nama: "pegawai"
                },
                {
                    nama: "mahasiswa"
                }
            ],
            ditujukanKepadaFilter: "",

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            records: [],
            newRecord: { judul: "", isi: "", ditujukan_kepada: "", gambar: "", },
            currentRecord: {},

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            seePage: false,
        }
    },
    components: {
        SideNavbar,
    },

    methods: {
        CharAndNumberOnly,
        removeHtmlTags,
        DateFormat,
        index() {
            this.records = [];
            this.prodiList = [];
            ShowLoading();
            let formData = new FormData();

            formData.append("previous_id", '');
            formData.append("last_id", '');
            formData.append("limit", this.limit);
            formData.append("ditujukan_kepada", localStorage.getItem('tipe_user'));
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'berita_index';
            } else {
                this.api = base_url + 'berita_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {

                        if (response.data.data != undefined) {
                            this.records = response.data.data[1];


                            this.totalPages = response.data.data[0][0].number_page;
                            this.previousLastId = response.data.data[1].id;
                            this.previousLastIdOld = response.data.data[1].id;
                            let last_index = Object.keys(response.data.data[0]).pop();

                            this.NextLastId = response.data.data[1][last_index].id;
                            this.NextLastIdOld = response.data.data[1][last_index].id;
                            this.arrayLastIdOld.push(response.data.data[1][last_index].id);


                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        index_next_page(param1, param2) {

            if (this.numberPage == this.totalPages) {
                return true;
            }


            ShowLoading();
            let formData = new FormData();
            formData.append("previous_id", "");
            formData.append("last_id", param2);
            formData.append("limit", this.limit);
            formData.append("ditujukan_kepada", localStorage.getItem('tipe_user'));

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.totalPages = response.data.data[0][0].number_page;
                        this.arrayLastIdOld.push(param2);
                        this.arrayPreviousIdOld.push(param1);

                        this.records = response.data.data[1];
                        let last_index = Object.keys(response.data.data[0]).pop();

                        this.NextLastId = response.data.data[1][last_index].id; // id 33
                        this.previousLastId = response.data.data[1][0].id; // 7
                        this.numberPage = this.numberPage + 1;
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        index_before_page() {
            if (this.numberPage == 1) {
                return true;
            }
            let last_index = this.arrayLastIdOld.length;
            last_index = last_index - 1;
            ShowLoading();
            let formData = new FormData();

            formData.append("previous_id", this.arrayPreviousIdOld[last_index]);
            formData.append("last_id", "");
            formData.append("limit", this.limit);
            formData.append("ditujukan_kepada", localStorage.getItem('tipe_user'));

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.totalPages = response.data.data[0][0].number_page;

                        this.records = response.data.data[1];
                        let last_index = Object.keys(response.data.data[1]).pop();
                        this.NextLastId = response.data.data[1][last_index].id;
                        this.previousLastId = response.data.data[1][0].id;

                        if (this.arrayLastIdOld.length != 1) {
                            this.arrayLastIdOld.pop();
                            this.arrayPreviousIdOld.pop();
                            this.numberPage = this.numberPage - 1;
                        }

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        getBerita(id) {
            this.seePage = true;
            this.currentRecord = {};
            ShowLoading();
            let formData = new FormData();

            formData.append('id', id);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_get';
            } else {
                this.api = base_url + this.url + '_get';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.currentRecord = response.data.data;
                        console.log(response.data.data);
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.showModal('modal_edit');

            // this.setRecordwithId('prodi', this.prodiList, 'nama', 'id');
            // this.setRecordwithId('dosen_wali', this.dosenWaliList, 'nama', 'id');
            // this.setRecordwithId('stambuk', this.stambukList, 'nama', 'id');
            // this.setRecordwithId('waktu_kuliah', this.waktuKuliahList, 'nama', 'id');

        },
        setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd) {
            if (this.currentRecord[field] != null) {
                const index = arraylist.findIndex((record) => record[fieldNameArray] == this.currentRecord[field]);
                this.currentRecord[field] = arraylist[index][valueWantAdd];
            } else {
                this.currentRecord[field] = "";
            }
        },

        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },


        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        deleteRecord(id) {

            this.temporaryId = id;
            id;
            this.showModal('modal_delete');
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        }
    },

    // mounted() {

    // },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }

        //akses
        // this.create_akses = menu_akses_cek(this.url2, 'create');
        // this.update_akses = menu_akses_cek(this.url2, 'update');
        // this.delete_akses = menu_akses_cek(this.url2, 'delete');

        this.index();

    }



}
</script>