<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                Semester
            </div>
            <div class="d-flex flex-column gap-2 mt-2">
                <!-- <div class="fs-8 fw-bold font-custom-2 font-gray-custom">
                        >Desktop/Pengguna
                    </div> -->
                <div class="row me-0">
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.tahun_ajaran" :value="list.id">
                                {{ list.tahun_ajaran }}
                            </option>
                        </select>
                    </div>
                    <!-- <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Prodi</option>
                            <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="kurikulumFilter" @change="index()" :disabled="prodiFilter == ''" required>
                            <option value="" selected disabled>- Pilih Kurikulum</option>
                            <option v-for="list in kurikulumList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div> -->

                    <div class="col-8 text-center">
                        <div class="d-flex justify-content-center  align-items-center">
                            <div>
                                <i class='bx bx-chevron-left fs-7' @click="index_before_page()"
                                    style="cursor: pointer;"></i>
                            </div>
                            <div class="fs-7">
                                {{ numberPage }}/{{ totalPages }}
                            </div>
                            <div>
                                <i class='bx bx-chevron-right fs-7' @click="index_next_page(previousLastId, NextLastId)"
                                    style="cursor: pointer;"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 text-end ">
                        <button v-if="create_akses == true" class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                            style="border-radius: 5px;" data-bs-toggle="modal" data-bs-target="#modal_tambah"
                            @click="showModal('modal_tambah')">
                            <img src="@/assets/icon/data_plus.png" alt="" width="15">
                            Tambah Semester
                        </button>

                    </div>

                </div>


                <div class="border mt-1 rounded-3 mb-5 me-2 font-custom-1">
                    <table class="table mb-0 bg-transparent table-borderless " style="">
                        <thead>
                            <tr>
                                <th scope="col" class="bg-transparent fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7">Semester</th>
                                <!-- <th scope="col" class="font-custom-1 fs-7">Prodi</th>
                                <th scope="col" class="font-custom-1 fs-7">Kurikulum</th>
                                <th scope="col" class="font-custom-1 fs-7">Tipe</th> -->
                                <th scope="col" class="font-custom-1 fs-7">Tahun Ajaran</th>
                                <th scope="col" class="font-custom-1 fs-7">Tanggal Mulai</th>
                                <th scope="col" class="font-custom-1 fs-7">Tanggal Selesai</th>

                                <th scope="col" class="bg-transparent  fs-7">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in records" :key="record.id" :ref="record.id" class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ record.nama }}</td>
                                <!-- <td>{{ record.prodi }}</td>
                                <td>{{ record.kurikulum }}</td>
                                <td>{{ record.tipe }}</td> -->
                                <td>{{ record.tahun_ajaran }}</td>
                                <td>{{ record.tanggal_mulai }}</td>
                                <td>{{ record.tanggal_selesai }}</td>
                                <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                            v-on:click="editRecord(record)" data-bs-toggle="modal"
                                            data-bs-target="#modal_edit"><i
                                                class='bx bxs-message-square-edit fs-8'></i></button>
                                        <button v-if="delete_akses == true" class="btn btn-danger btn-sm fs-8"
                                            data-bs-toggle="modal" data-bs-target="#modal_delete"
                                            v-on:click="deleteRecord(record.id)"><i
                                                class='bx bxs-trash-alt fs-8'></i></button>
                                    </div>

                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>

    </div>
    <form @submit.prevent="insert()">
        <div class="modal fade" id="modal_tambah" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
            aria-hidden="true" ref="modal_tambah">

            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah data semester</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_tambah"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_tambah', 'close_modal_tambah')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nama" class="form-label">Nama Semester</label>
                            <!-- <input type="text" min="1" class="form-control" v-model="newRecord.nama" required /> -->
                            <select class="form-select" aria-label="Default select example" name="nama" id="nama"
                                v-model="newRecord.nama" required>
                                <option value="" selected disabled>- Pilih Tipe Semester</option>
                                <option value="GANJIL" selected>GANJIL</option>
                                <option value="GENAP" selected>GENAP</option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahun_ajaran" v-model="newRecord.tahun_ajaran" required>
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for="list in tahunAjaranList" :key="list.tahun_ajaran" :value="list.id">
                                    {{ list.tahun_ajaran }}
                                </option>
                            </select>
                        </div>


                        <!-- <div class="mb-3">
                            <label for="prodi" class="form-label">Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="newRecord.prodi" @change="index(newRecord.prodi)" required>
                                <option value="" selected disabled>- Pilih Prodi</option>
                                <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="kurikulum" class="form-label">Kurikulum</label>
                            <select class="form-select" aria-label="Default select example" name="kurikulum" id="kurikulum"
                                v-model="newRecord.kurikulum" required :disabled="newRecord.prodi == ''">
                                <option value="" selected disabled>- Pilih Kurikulum</option>
                                <option v-for="list in kurikulumList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->


                        <div class="mb-3">
                            <label for="tanggal_mulai" class="form-label"> Tanggal Mulai</label>
                            <input type="date" class="form-control" v-model="newRecord.tanggal_mulai" required />
                        </div>
                        <div class="mb-3">
                            <label for="tanggal_selesai" class="form-label"> Tanggal Selesai</label>
                            <input type="date" class="form-control" v-model="newRecord.tanggal_selesai" required />
                        </div>


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_tambah', 'close_modal_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan</button>
                    </div>

                </div>

            </div>

        </div>
    </form>
    <form @submit.prevent="update">
        <div class="modal fade" id="modal_edit" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
            aria-hidden="true" ref="modal_edit">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Data semester</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_edit"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_edit', 'close_modal_edit')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nama" class="form-label">Nama Semester</label>
                            <!-- <input type="text" min="1" class="form-control" v-model="newRecord.nama" required /> -->
                            <select class="form-select" aria-label="Default select example" name="nama" id="nama"
                                v-model="currentRecord.nama" required>
                                <option value="" selected disabled>- Pilih Tipe Semester</option>
                                <option value="GANJIL" selected>GANJIL</option>
                                <option value="GENAP" selected>GENAP</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahun_ajaran" v-model="currentRecord.tahun_ajaran" required>
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for="list in tahunAjaranList" :key="list.tahun_ajaran" :value="list.id">
                                    {{ list.tahun_ajaran }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="tanggal_mulai" class="form-label"> Tanggal Mulai</label>
                            <input type="date" class="form-control" v-model="currentRecord.tanggal_mulai" required />
                        </div>
                        <div class="mb-3">
                            <label for="tanggal_selesai" class="form-label"> Tanggal Selesai</label>
                            <input type="date" class="form-control" v-model="currentRecord.tanggal_selesai" required />
                        </div>

                        <!-- <div class="mb-3">
                            <label for="prodi" class="form-label">Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="currentRecord.prodi" @change="index(currentRecord.prodi)" required>
                                <option value="" selected disabled>- Pilih Prodi</option>
                                <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="kurikulum" class="form-label">Kurikulum</label>
                            <select class="form-select" aria-label="Default select example" name="kurikulum" id="kurikulum"
                                v-model="currentRecord.kurikulum" required :disabled="currentRecord.prodi == ''">
                                <option value="" selected disabled>- Pilih Kurikulum</option>
                                <option v-for="list in kurikulumList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_edit', 'close_modal_edit')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>

                    </div>


                </div>

            </div>
        </div>
    </form>

    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete"></button>
                    <button type="button " class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_delete', 'close_modal_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_delete', 'close_modal_delete')">Tidak, tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, base_url, menu_akses_cek, CloseLoading, ShowLoading, ErrorConnectionTimeOut, AlertBottom, DateNow } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";

export default {
    data() {
        return {
            //data
            url: 'semester',
            api: "",

            //pages
            limit: "10",
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,

            //filter
            // kurikulumList: [],
            tahunAjaranList: [],
            // prodiList: [],
            // kurikulumFilter: "",
            tahunAjaranFilter: "",
            // prodiFilter: "",

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,


            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            records: [],
            newRecord: { nama: "", tahun_ajaran: "", prodi: "", kurikulum: "" },
            currentRecord: {},

            //load data
            isLoadData: 0,
        }
    },
    components: {
        SideNavbar,
    },

    methods: {
        DateNow,
        index() {
            this.records = []
            ShowLoading();
            let formData = new FormData();

            // if (prodi == undefined) {
            //     formData.append("prodi", this.prodiFilter);
            // } else {
            //     formData.append("prodi", prodi);
            // }
            formData.append("previous_id", '');
            formData.append("last_id", '');
            formData.append("limit", this.limit);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            // formData.append("kurikulum", this.kurikulumFilter);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    this.tahunAjaranList = response.data.tahun_ajaran;
                    if (response.data.status == true) {
                        // this.kurikulumList = response.data.kurikulum;


                        // this.prodiList = response.data.prodi;
                        if (response.data.data != undefined) {
                            if (this.isLoadData != 0) {
                                this.records = response.data.data[1];
                            } else {
                                this.isLoadData = 1;
                            }
                            this.totalPages = response.data.data[0][0].number_page;
                            this.previousLastId = response.data.data[1][0].id;

                            let last_index = Object.keys(response.data.data[1]).length - 1;
                            this.NextLastId = response.data.data[1][last_index].id;

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        index_next_page(param1, param2) {
            if (this.totalPages <= this.numberPage) {
                return true
            }

            ShowLoading();
            let formData = new FormData();
            formData.append("previous_id", "");
            formData.append("last_id", param2);
            formData.append("limit", this.limit);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            // formData.append("kurikulum", this.kurikulumFilter);
            // formData.append("prodi", this.prodiFilter);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.records = response.data.data[1];
                        this.totalPages = response.data.data[0][0].number_page;
                        this.previousLastId = response.data.data[1][0].id;

                        let last_index = Object.keys(response.data.data[1]).length - 1;
                        this.NextLastId = response.data.data[1][last_index].id;

                        this.numberPage = this.numberPage + 1;
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        index_before_page() {

            if (this.numberPage == 1) {
                return true
            }

            ShowLoading();
            let formData = new FormData();
            formData.append("url", "ra_admin_verifikasi_page");
            formData.append("previous_id", this.previousLastId);
            formData.append("last_id", "");
            formData.append("limit", this.limit);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            // formData.append("kurikulum", this.kurikulumFilter);
            // formData.append("prodi", this.prodiFilter);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.records = response.data.data[1];
                        this.totalPages = response.data.data[0][0].number_page;
                        this.previousLastId = response.data.data[1][0].id;

                        let last_index = Object.keys(response.data.data[1]).length - 1;
                        this.NextLastId = response.data.data[1][last_index].id;

                        this.numberPage = this.numberPage - 1;

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        insert() {
            ShowLoading();
            const formData = new FormData();


            Object.entries(this.newRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });

            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_insert";
            } else {
                this.api = base_url + this.url + "_insert";
            }

            console.log('asdas');
            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response.data)
                    if (response.data.status == true) {


                        this.records.unshift(response.data.data);

                        // Clear the form inputs
                        this.newRecord = { nama: "", prodi: "", kurikulum: "", tahun_ajaran: "" };
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_tambah', 'close_modal_tambah')
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.showModal('modal_edit');

            this.setRecordwithId('tahun_ajaran', this.tahunAjaranList, 'tahun_ajaran', 'id');
            // const id_prodi = this.setRecordwithId('prodi', this.prodiList, 'nama', 'id');
            // this.setRecordwithId('kurikulum', this.kurikulumList, 'nama', 'id');
            // this.index(id_prodi);


        },


        update() {
            ShowLoading();
            const formData = new FormData();

            Object.entries(this.currentRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });
            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_update";
            } else {
                this.api = base_url + this.url + "_update";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response);
                    if (response.data.status == true) {
                        const index = this.records.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.currentRecord = {}
                        // this.$set(this.records, index, response.data.data);
                        this.records.splice(index, 1, response.data.data);

                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_edit', 'close_modal_edit');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd,) {
            if (this.currentRecord[field] != null || arraylist.length != 0) {
                const index = arraylist.findIndex((record) => record[fieldNameArray] == this.currentRecord[field]);
                this.currentRecord[field] = arraylist[index][valueWantAdd];
                return arraylist[index].id;
            } else {
                this.currentRecord[field] = "";
            }
        },
        deleteData() {

            ShowLoading();
            const formData = new FormData();
            formData.append("id", this.temporaryId);
            console.log(this.temporaryId)
            formData.append("url", "ra_rekomendasi_delete");
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_delete";
            } else {
                this.api = base_url + this.url + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)

                .then((response) => {
                    console.log(response);
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.records.findIndex((record) => record.id === this.temporaryId);
                        this.records.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_delete', 'close_modal_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        deleteRecord(id) {

            this.temporaryId = id;
            console.log(this.temporaryId)
            this.showModal('modal_delete');
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        }
    },

    mounted() {
        this.newRecord.tanggal_mulai = DateNow();
        this.newRecord.tanggal_selesai = DateNow();
    },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }

        //akses
        this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');
        this.delete_akses = menu_akses_cek(this.url, 'delete');

        this.index();

    }



}
</script>