<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                Mahasiswa
            </div>
            <div class="d-flex flex-column gap-2 mt-2 justify-content-center">
                <div class="d-flex">
                    <div class="me-0 d-flex justify-content-start gap-2">
                        <div class="">
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="prodiFilter" @change="index()" required>
                                <option value="" selected disabled>- Pilih prodi</option>
                                <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="">
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="tahunAjaranFilter" @change="index()" required>
                                <option value="" selected disabled>- Pilih Tahun ajaran</option>
                                <option v-for="list in tahunAjaranList" :key="list.tahun_ajaran" :value="list.id">
                                    {{ list.tahun_ajaran }}
                                </option>
                            </select>
                        </div>
                        <div class="">
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="jalurFilter" @change="index()" required>
                                <option value="" selected disabled>- Pilih Jalur</option>
                                <option v-for="list in jalurList" :key="list.nama" :value="list.value">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <!-- <div class="col-6 text-end ">
                        <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 " style="border-radius: 5px;"
                            data-bs-toggle="modal" data-bs-target="#modal_tambah" @click="showModal('modal_tambah')">
                            <img src="@/assets/icon/data_plus.png" alt="" width="15">
                            Tambah Mahasiswa
                        </button>

                    </div> -->

                    </div>
                    <div class="d-flex justify-content-end align-items-center flex-fill me-2 gap-2">
                        <div>
                            <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                                style="border-radius: 5px;" @click="showModal2('modal_laporan_mahasiswa')">
                                <!-- <img src="@/assets/icon/data_plus.png" alt="" width="15"> -->
                                Laporan Kehadiran Mahasiswa
                            </button>
                        </div>
                        <div>
                            <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                                style="border-radius: 5px;" @click="showModal2('modal_laporan_nilai_mahasiswa')">
                                <!-- <img src="@/assets/icon/data_plus.png" alt="" width="15"> -->
                                Laporan Nilai
                            </button>
                        </div>
                        <div>
                            <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                                style="border-radius: 5px;" @click="showModal2('modal_laporan_ipk_mahasiswa')">
                                <!-- <img src="@/assets/icon/data_plus.png" alt="" width="15"> -->
                                Laporan IPK
                            </button>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-start gap-2">
                    <div class=" d-flex align-items-center justify-content-center">
                        <div class="d-flex justify-content-center align-items-center">
                            <div>
                                <i class='bx bx-chevron-left fs-7' @click="index_before_page()"
                                    style="cursor: pointer;"></i>
                            </div>
                            <div class="fs-7">
                                {{ numberPage }}/{{ totalPages }}
                            </div>
                            <div>
                                <i class='bx bx-chevron-right fs-7' @click="index_next_page(previousLastId, NextLastId)"
                                    style="cursor: pointer;"></i>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <input type="text" v-model="query" @change="index()" class="form-control"
                            placeholder="Cari berdasarkan nama, nim">
                    </div>

                </div>



                <div class="border mt-1 rounded-3 me-2 font-custom-1 mb-5">
                    <table class="table mb-0 bg-transparent table-borderless " style="">
                        <thead>
                            <tr>
                                <th scope="col" class="bg-transparent fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7">NIM </th>
                                <th scope="col" class="font-custom-1 fs-7">Nama </th>
                                <th scope="col" class="font-custom-1 fs-7">Prodi </th>
                                <th scope="col" class="font-custom-1 fs-7">Tahun Ajaran</th>
                                <th scope="col" class="font-custom-1 fs-7">Status Akademik</th>
                                <th scope="col" class="bg-transparent  fs-7">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in records" :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ record.nim_mahasiswa }}</td>
                                <td>{{ record.nama_lengkap }}</td>
                                <td>{{ record.prodi }}</td>
                                <td>{{ record.tahun_ajaran }}</td>
                                <td>{{ record.status_akademik }}</td>
                                <!-- <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <button class="btn btn-primary btn-sm fs-8" data-bs-toggle="modal"
                                            @click="setkrsId(record.id, 0)" data-bs-target="#modal_get_krs"><i
                                                class='bx bxs-file'></i></button>
                                        <button class="btn btn-secondary btn-sm fs-8" v-on:click="viewRecord(record)"
                                            @click="showModal2('modal_view')"><i class='bx bxs-show fs-8'></i></button>
                                    </div>
                                </td> -->
                                <div class="btn-group w-100 ">
                                    <button type="button" class="btn btn-sm px-1 py-1 border dropdown-toggle "
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        Aksi
                                    </button>
                                    <ul class="aksi-button dropdown-menu">
                                        <li><button class="dropdown-item  fs-8 aksi-item-primary" data-bs-toggle="modal"
                                                data-bs-target="#modal_get_krs" @click="setkrsId(record.id, 0)">
                                                <i class='bx bxs-file me-2'></i> KRS</button>
                                        </li>
                                        <li><button class="dropdown-item  fs-8 aksi-item-light"
                                                v-on:click="viewRecord(record)" @click="showModal2('modal_view')">
                                                <i class='bx bxs-show  me -2'></i> Mahasiswa</button>
                                        </li>

                                        <li><button class="dropdown-item fs-8 aksi-item-info"
                                                @click="showModal2('modal_absensi')"
                                                v-on:click="editRecord(record); id_mahasiswa_absensi = record.id; get_absensi()"
                                                data-bs-toggle="modal" data-bs-target="#modal_absensi">
                                                <i class='bx bxs-bar-chart-alt-2  me -2'></i> Absensi</button>
                                        </li>
                                        <li><button class="dropdown-item fs-8 aksi-item-success"
                                                @click="editRecord(record); showModal2('modal_mata_kuliah_nilai')"
                                                v-on:click="id_mahasiswa_absensi = record.id; nilaiMataKuliahGet()">
                                                <i class='bx bxs-book-content me-2'></i> Nilai</button>
                                        </li>
                                        <li><button class="dropdown-item fs-8 aksi-item-red"
                                                @click="showModal2('modal_transkrip_akademik')"
                                                v-on:click="editRecord(record); id_mahasiswa_absensi = record.id; transkripAkademikGet()">
                                                <i class='bx bx-book'></i> Transkrip Akademik</button>
                                        </li>
                                    </ul>
                                </div>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>


        </div>

    </div>
    <form @submit.prevent="insert()">
        <div class="modal fade" id="modal_tambah" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_tambah">

            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah data mahasiswa</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_tambah"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_tambah', 'close_modal_tambah')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="mama" class="form-label">NIM Mahasiswa</label>
                            <input type="text" class="form-control" v-model="newRecord.nim_mahasiswa" required />
                        </div>
                        <div class="mb-3">
                            <label for="mama" class="form-label">Nama</label>
                            <input type="text" class="form-control" v-model="newRecord.nama_lengkap"
                                @keypress="CharOnly" required />
                        </div>
                        <div class="mb-3">
                            <label for="fakultas" class="form-label">Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="fakultas"
                                id="fakultas" v-model="newRecord.tahun_ajaran" required>
                                <option value="" selected disabled>- Pilih Tahun ajaran</option>
                                <option v-for="list in tahunAjaranList" :key="list.tahun_ajaran" :value="list.id">
                                    {{ list.tahun_ajaran }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="status_akademik" class="form-label">Status Akademik</label>
                            <select class="form-select" aria-label="Default select example" name="status_akademik"
                                id="status_akademik" v-model="newRecord.status_akademik" required>
                                <option value="" selected disabled>- Pilih Status Akademik</option>
                                <option v-for="list in statusAkademikList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="prodi" class="form-label">Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="newRecord.prodi" required>
                                <option value="" selected disabled>- Pilih Prodi</option>
                                <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="nisn_sekolah" class="form-label">NISN sekolah</label>
                            <input type="text" class="form-control" v-model="newRecord.nisn_sekolah"
                                @keypress="NumberOnly" required />
                        </div>
                        <div class="mb-3">
                            <label for="warga_negara" class="form-label">Kewarganegaraan</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="newRecord.warga_negara" required>
                                <option value="" selected disabled>- Pilih Warga negara</option>
                                <option v-for="list in wargaNegaraList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="mama" class="form-label">No Induk Kependudukan (NIK)</label>
                            <input type="text" class="form-control" v-model="newRecord.no_ktp" maxlength="16"
                                @keypress="NumberOnly" required />
                        </div>

                        <div class="mb-3">
                            <label for="mama" class="form-label">No Kartu Kelurga KK</label>
                            <input type="text" class="form-control" v-model="newRecord.no_kk" maxlength="16"
                                @keypress="NumberOnly" required />
                        </div>
                        <div class="mb-3">
                            <label for="tempat_lahir" class="form-label">Tempat lahir</label>
                            <input type="text" class="form-control" v-model="newRecord.tempat_lahir"
                                @keypress="CharOnly" />
                        </div>
                        <div class="mb-3">
                            <label for="tanggal_lahir" class="form-label">Tanggal lahir</label>
                            <input type="date" class="form-control" :max="tanggal_maksimum" :min="tanggal_minimum"
                                v-model="newRecord.tanggal_lahir" />
                        </div>
                        <div class="mb-3">
                            <label for="jenis_kelamin" class="form-label">Jenis kelamin</label>
                            <input type="text" class="form-control" v-model="newRecord.jenis_kelamin" />
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Alamat</label>
                            <input type="text" class="form-control" v-model="newRecord.alamat_siswa" />
                        </div>
                        <div class="mb-3">
                            <label for="provinsi" class="form-label">Provinsi</label>
                            <v-select v-model="provinsi" :options="provinsiList" placeholder="Pilih Provinsi"
                                :searchable="true" ref="provinsi" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kabupaten" class="form-label">Kabupaten / Kota</label>
                            <v-select v-model="kota" :options="kotaList" placeholder="Pilih Kota" :searchable="true"
                                ref="kota" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kecamatan" class="form-label">Kecamatan</label>
                            <v-select v-model="kecamatan" :options="kecamatanList" placeholder="Pilih Kecamatan"
                                :searchable="true" ref="kecamatan" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kelurahan" class="form-label">Kelurahan</label>
                            <v-select v-model="kelurahan" :options="kelurahanList" placeholder="Pilih Kelurahan"
                                :searchable="true" ref="kelurahan" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kodepos" class="form-label">Kode Pos</label>
                            <input type="text" class="form-control" v-model="newRecord.kodepos" />
                        </div>
                        <div class="mb-3">
                            <label for="telepon_siswa" class="form-label">No Telepon </label>
                            <input type="text" class="form-control" v-model="newRecord.telepon_siswa" />
                        </div>
                        <div class="mb-3">
                            <label for="whatsapp" class="form-label">whatsapp </label>
                            <input type="text" class="form-control" v-model="newRecord.whatsapp" />
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input type="text" class="form-control" v-model="newRecord.email" />
                        </div>
                        <div class="mb-3">
                            <label for="jumlah_bersaudara" class="form-label">Jumlah bersaudara </label>
                            <input type="text" class="form-control" v-model="newRecord.jumlah_bersaudara" />
                        </div>

                        <div class="mb-3">
                            <label for="anak_ke" class="form-label">Anak ke-</label>
                            <input type="text" class="form-control" v-model="newRecord.anak_ke" />
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label">Agama</label>
                            <input type="text" class="form-control" v-model="newRecord.agama" />
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label">Hobi</label>
                            <input type="text" class="form-control" v-model="newRecord.hobi" />
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label">Nama Ayah Kandung</label>
                            <input type="text" class="form-control" v-model="newRecord.nama_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ayah" class="form-label">No Hp Ayah</label>
                            <input type="text" class="form-control" v-model="newRecord.no_hp_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ayah" class="form-label">Pekerjaan Ayah</label>
                            <input type="text" class="form-control" v-model="newRecord.pekerjaan_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ayah" class="form-label">Pendidikan terakhir ayah</label>
                            <input type="text" class="form-control" v-model="newRecord.pendidikan_terakhir_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ayah" class="form-label">Pendapatan / Bulan ayah</label>
                            <input type="text" class="form-control" v-model="newRecord.penghasilan_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label">Nama Ibu Kandung</label>
                            <input type="text" class="form-control" v-model="newRecord.nama_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">No Hp Ibu</label>
                            <input type="text" class="form-control" v-model="newRecord.no_hp_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Pekerjaan Ibu</label>
                            <input type="text" class="form-control" v-model="newRecord.pekerjaan_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Pendidikan terakhir Ibu</label>
                            <input type="text" class="form-control" v-model="newRecord.pendidikan_terakhir_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Pendapatan / Bulan Ibu</label>
                            <input type="text" class="form-control" v-model="newRecord.penghasilan_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Alamat Ortu</label>
                            <input type="text" class="form-control" v-model="newRecord.alamat_ortu" />
                        </div>
                        <div class="mb-3">
                            <label for="provinsi_ortu" class="form-label">Provinsi</label>
                            <v-select v-model="provinsi_ortu" :options="provinsiList" placeholder="Pilih Provinsi"
                                :searchable="true" ref="provinsi_ortu" class="form-control custom-select"></v-select>
                        </div>

                        <div class="mb-3">
                            <label for="kota_ortu" class="form-label">Kota</label>
                            <v-select v-model="kota_ortu" :options="kotaList_ortu" placeholder="Pilih Kota/Kabupaten"
                                :searchable="true" ref="kota_ortu" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kecamatan_ortu" class="form-label">Kecamatan</label>
                            <v-select v-model="kecamatan_ortu" :options="kecamatanList_ortu"
                                placeholder="Pilih Kecamatan" :searchable="true" ref="kecamatan_ortu"
                                class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kelurahan_ortu" class="form-label">Kelurahan</label>
                            <v-select v-model="kelurahan_ortu" :options="kelurahanList_ortu"
                                placeholder="Pilih Kelurahan" :searchable="true" ref="kelurahan_ortu"
                                class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Kodepos</label>
                            <input type="text" class="form-control" v-model="newRecord.kodepos_ortu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Nama Sekolah</label>
                            <input type="text" class="form-control" v-model="newRecord.nama_sekolah" />
                        </div>
                        <div class="mb-3">
                            <label for="provinsi_sekolah" class="form-label">Provinsi Sekolah</label>
                            <v-select v-model="provinsi_sekolah" :options="provinsiList_sekolah"
                                placeholder="Pilih Provinsi" :searchable="true" ref="provinsi_sekolah"
                                class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kota_sekolah" class="form-label">Kota Sekolah</label>
                            <v-select v-model="kota_sekolah" :options="kotaList_sekolah" placeholder="Pilih Kota"
                                :searchable="true" ref="kota_sekolah" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="jurusan sekolah" class="form-label">Jurusan Sekolah</label>
                            <input type="text" class="form-control" v-model="newRecord.jurusan_sekolah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Waktu Kuliah</label>
                            <input type="text" class="form-control" v-model="newRecord.waktu_kuliah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Ukuran jas akademis</label>
                            <input type="text" class="form-control" v-model="newRecord.ukuran_jas_akademis" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_tambah', 'close_modal_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan</button>
                    </div>

                </div>

            </div>

        </div>
    </form>
    <form @submit.prevent="update">
        <div class="modal fade" id="modal_edit" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_edit">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Data mahasiswa</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_edit"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_edit', 'close_modal_edit')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="mama" class="form-label">NIM Mahasiswa</label>
                            <input type="text" class="form-control" v-model="currentRecord.nim_mahasiswa"
                                @keypress="CharOnly" required />
                        </div>
                        <div class="mb-3">
                            <label for="mama" class="form-label">Nama</label>
                            <input type="text" class="form-control" v-model="currentRecord.nama_lengkap"
                                @keypress="CharOnly" required />
                        </div>
                        <div class="mb-3">
                            <label for="fakultas" class="form-label">Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="fakultas"
                                id="fakultas" v-model="currentRecord.tahun_ajaran" required>
                                <option value="" selected disabled>- Pilih Tahun ajaran</option>
                                <option v-for="list in tahunAjaranList" :key="list.tahun_ajaran" :value="list.id">
                                    {{ list.tahun_ajaran }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="status_akademik" class="form-label">Status Akademik</label>
                            <select class="form-select" aria-label="Default select example" name="status_akademik"
                                id="status_akademik" v-model="currentRecord.status_akademik" required>
                                <option value="" selected disabled>- Pilih Status Akademik</option>
                                <option v-for="list in statusAkademikList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="prodi" class="form-label">Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="currentRecord.prodi" required>
                                <option value="" selected disabled>- Pilih Prodi</option>
                                <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="nisn_sekolah" class="form-label">NISN sekolah</label>
                            <input type="text" class="form-control" v-model="currentRecord.nisn_sekolah" required />
                        </div>
                        <div class="mb-3">
                            <label for="warga_negara" class="form-label">Kewarganegaraan</label>
                            <input type="text" class="form-control" v-model="currentRecord.warga_negara" required />
                        </div>
                        <div class="mb-3">
                            <label for="mama" class="form-label">No Induk Kependudukan (NIK)</label>
                            <input type="text" class="form-control" v-model="currentRecord.no_ktp" required />
                        </div>

                        <div class="mb-3">
                            <label for="mama" class="form-label">No Kartu Kelurga KK</label>
                            <input type="text" class="form-control" v-model="currentRecord.no_kk" />
                        </div>
                        <div class="mb-3">
                            <label for="tempat_lahir" class="form-label">Tempat lahir</label>
                            <input type="text" class="form-control" v-model="currentRecord.tempat_lahir" />
                        </div>
                        <div class="mb-3">
                            <label for="tanggal_lahir" class="form-label">Tanggal lahir</label>
                            <input type="text" class="form-control" v-model="currentRecord.tanggal_lahir" />
                        </div>
                        <div class="mb-3">
                            <label for="jenis_kelamin" class="form-label">Jenis kelamin</label>
                            <input type="text" class="form-control" v-model="currentRecord.jenis_kelamin" />
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Alamat</label>
                            <input type="text" class="form-control" v-model="currentRecord.alamat_siswa" />
                        </div>
                        <div class="mb-3">
                            <label for="provinsi_update" class="form-label">Provinsi</label>
                            <v-select v-model="provinsi_update" :options="provinsiList" placeholder="Pilih Provinsi"
                                :searchable="true" ref="provinsi_update" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kabupaten" class="form-label">Kabupaten / Kota</label>
                            <v-select v-model="kota_update" :options="kotaList" placeholder="Pilih Kota"
                                :searchable="true" ref="kota" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kecamatan" class="form-label">Kecamatan</label>
                            <v-select v-model="kecamatan_update" :options="kecamatanList" placeholder="Pilih Kecamatan"
                                :searchable="true" ref="kecamatan" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kelurahan" class="form-label">Kelurahan</label>
                            <v-select v-model="kelurahan_update" :options="kelurahanList" placeholder="Pilih Kelurahan"
                                :searchable="true" ref="kelurahan" class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kodepos" class="form-label">Kode Pos</label>
                            <input type="text" class="form-control" v-model="currentRecord.kodepos" />
                        </div>
                        <div class="mb-3">
                            <label for="telepon_siswa" class="form-label">No Telepon </label>
                            <input type="text" class="form-control" v-model="currentRecord.telepon_siswa" />
                        </div>
                        <div class="mb-3">
                            <label for="whatsapp" class="form-label">whatsapp </label>
                            <input type="text" class="form-control" v-model="currentRecord.whatsapp" />
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input type="text" class="form-control" v-model="currentRecord.email" />
                        </div>
                        <div class="mb-3">
                            <label for="jumlah_bersaudara" class="form-label">Jumlah bersaudara </label>
                            <input type="text" class="form-control" v-model="currentRecord.jumlah_bersaudara" />
                        </div>

                        <div class="mb-3">
                            <label for="anak_ke" class="form-label">Anak ke-</label>
                            <input type="text" class="form-control" v-model="currentRecord.anak_ke" />
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label">Agama</label>
                            <input type="text" class="form-control" v-model="currentRecord.agama" />
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label">Hobi</label>
                            <input type="text" class="form-control" v-model="currentRecord.hobi" />
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label">Nama Ayah Kandung</label>
                            <input type="text" class="form-control" v-model="currentRecord.nama_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ayah" class="form-label">No Hp Ayah</label>
                            <input type="text" class="form-control" v-model="currentRecord.no_hp_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ayah" class="form-label">Pekerjaan Ayah</label>
                            <input type="text" class="form-control" v-model="currentRecord.pekerjaan_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ayah" class="form-label">Pendidikan terakhir ayah</label>
                            <input type="text" class="form-control" v-model="currentRecord.pendidikan_terakhir_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ayah" class="form-label">Pendapatan / Bulan ayah</label>
                            <input type="text" class="form-control" v-model="currentRecord.penghasilan_ayah" />
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label">Nama Ibu Kandung</label>
                            <input type="text" class="form-control" v-model="currentRecord.nama_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">No Hp Ibu</label>
                            <input type="text" class="form-control" v-model="currentRecord.no_hp_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Pekerjaan Ibu</label>
                            <input type="text" class="form-control" v-model="currentRecord.pekerjaan_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Pendidikan terakhir Ibu</label>
                            <input type="text" class="form-control" v-model="currentRecord.pendidikan_terakhir_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Pendapatan / Bulan Ibu</label>
                            <input type="text" class="form-control" v-model="currentRecord.penghasilan_ibu" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Alamat Ortu</label>
                            <input type="text" class="form-control" v-model="currentRecord.alamat_ortu" />
                        </div>
                        <div class="mb-3">
                            <label for="provinsi_ortu_update" class="form-label">Provinsi</label>
                            <v-select v-model="provinsi_ortu_update" :options="provinsiList_ortu"
                                placeholder="Pilih Provinsi" :searchable="true" ref="provinsi_ortu_update"
                                class="form-control custom-select"></v-select>
                        </div>

                        <div class="mb-3">
                            <label for="kota_ortu_update" class="form-label">Kota</label>
                            <v-select v-model="kota_ortu_update" :options="kotaList_ortu"
                                placeholder="Pilih Kota/Kabupaten" :searchable="true" ref="kota_ortu_update"
                                class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kecamatan_ortu_update" class="form-label">Kecamatan</label>
                            <v-select v-model="kecamatan_ortu_update" :options="kecamatanList_ortu"
                                placeholder="Pilih Kecamatan" :searchable="true" ref="kecamatan_ortu_update"
                                class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kelurahan_ortu_update" class="form-label">Kelurahan</label>
                            <v-select v-model="kelurahan_ortu_update" :options="kelurahanList_ortu"
                                placeholder="Pilih Kelurahan" :searchable="true" ref="kelurahan_ortu_update"
                                class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Kodepos</label>
                            <input type="text" class="form-control" v-model="currentRecord.kodepos_ortu" />
                        </div>

                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Nama Sekolah</label>
                            <input type="text" class="form-control" v-model="currentRecord.nama_sekolah" />
                        </div>
                        <div class="mb-3">
                            <label for="provinsi_sekolah_update" class="form-label">Provinsi Sekolah</label>
                            <v-select v-model="provinsi_sekolah_update" :options="provinsiList_sekolah"
                                placeholder="Pilih Provinsi" :searchable="true" ref="provinsi_sekolah_update"
                                class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="kota_sekolah_update" class="form-label">Kota Sekolah</label>
                            <v-select v-model="kota_sekolah_update" :options="kotaList_sekolah" placeholder="Pilih Kota"
                                :searchable="true" ref="kota_sekolah_update"
                                class="form-control custom-select"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="jurusan sekolah" class="form-label">Jurusan Sekolah</label>
                            <input type="text" class="form-control" v-model="currentRecord.jurusan_sekolah" />
                        </div>

                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Waktu Kuliah</label>
                            <input type="text" class="form-control" v-model="currentRecord.waktu_kuliah" />
                        </div>
                        <div class="mb-3">
                            <label for="no_hp_ibu" class="form-label">Ukuran jas akademis</label>
                            <input type="text" class="form-control" v-model="currentRecord.ukuran_jas_akademis" />
                        </div>
                        <div class="mb-3">
                            <label for="jenis_penelitian " class="form-label"> Foto Pas</label>
                            <div class="d-flex align-items-center gap-2">
                                <div class="">
                                    <img :src="currentRecord.foto_pas" alt=""
                                        style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                        class=" mb-2 border border-3" @click="openInNewTab(currentRecord.foto_pas)">
                                </div>
                                <div class="w-100">
                                    <input type="file" accept="image/png, image/jpeg" class="form-control"
                                        ref="mahasiswa" />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="foto ktp" class="form-label"> Foto Cover Rapor</label>
                            <div class="d-flex align-items-center gap-2">
                                <div class="">
                                    <img :src="currentRecord.foto_cover_rapor" alt=""
                                        style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                        class=" mb-2 border border-3"
                                        @click="openInNewTab(currentRecord.foto_cover_rapor)">
                                </div>
                                <div class="w-100">
                                    <input type="file" accept="image/png, image/jpeg" class="form-control"
                                        ref="mahasiswa" />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="foto ktp" class="form-label"> Foto Cover Rapor</label>
                            <div class="d-flex align-items-center gap-2">
                                <div class="">
                                    <img :src="currentRecord.foto_cover_rapor" alt=""
                                        style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                        class=" mb-2 border border-3"
                                        @click="openInNewTab(currentRecord.foto_cover_rapor)">
                                </div>
                                <div class="w-100">
                                    <input type="file" accept="image/png, image/jpeg" class="form-control"
                                        ref="mahasiswa" />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="foto ktp" class="form-label"> Foto Data diri Rapor</label>
                            <div class="d-flex align-items-center gap-2">
                                <div class="">
                                    <img :src="currentRecord.foto_data_diri_rapor" alt=""
                                        style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                        class=" mb-2 border border-3"
                                        @click="openInNewTab(currentRecord.foto_data_diri_rapor)">
                                </div>
                                <div class="w-100">
                                    <input type="file" accept="image/png, image/jpeg" class="form-control"
                                        ref="mahasiswa" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_edit', 'close_modal_edit')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete"></button>
                    <button type="button " class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_delete', 'close_modal_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_delete', 'close_modal_delete')">Tidak, tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modal_get_krs" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_get_krs">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">KRS Mahasiswa</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_get_krs"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_get_krs', 'close_modal_get_krs')">
                    </button>
                </div>

                <div class="modal-body">

                    <div class="d-flex gap-2 justify-content-start">
                        <div>Pilih Semester : </div>
                        <div v-for="(record) in semesterRecords" :key="record.id" :ref="record.id" class="d-flex">
                            <!-- <th scope="row" class="bg-transparent ">{{ index + 1 }}</th> -->
                            <button class="btn btn-primary btn-sm fs-7"
                                @click="setkrsId(krsId, record.counter_semester)">{{
                                    record.counter_semester
                                }}</button>
                        </div>
                    </div>
                    <div class="row" v-if="counter_semester != ''">
                        <div class="col-6">
                            <table>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Dosen Wali </td>
                                        <td>:</td>
                                        <td>&nbsp; {{ dosen_wali }}</td>
                                    </tr>
                                    <tr>
                                        <td>SKS yang telah diambil </td>
                                        <td>:</td>
                                        <td>&nbsp;{{ jumlah_sks_diambil }}</td>
                                    </tr>
                                    <tr>
                                        <td>SkS yang boleh diambil </td>
                                        <td>:</td>
                                        <td>&nbsp;{{ jumlah_sks_diambil }}</td>
                                    </tr>
                                    <tr>
                                        <td>Kelas </td>
                                        <td>:</td>
                                        <td>&nbsp;{{ kelas }}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="col-6">
                            <table>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>SKS kumulatif </td>
                                        <td>:</td>
                                        <td>&nbsp;{{ jumlah_sks_diambil }}</td>
                                    </tr>
                                    <tr>
                                        <td>Tahun Akademik </td>
                                        <td>:</td>
                                        <td>&nbsp;{{ tahun_akademik }}</td>
                                    </tr>
                                    <tr>
                                        <td>Semester </td>
                                        <td>:</td>
                                        <td>&nbsp;{{ counter_semester }} ({{ tipe_semester }})</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                    <div class="border mt-1 rounded-3 me-2 font-custom-1" v-if="counter_semester != ''">
                        <table class="table mb-0 bg-transparent table-borderless " style="">
                            <thead>
                                <tr>
                                    <th scope="col" class="bg-transparent fs-7">No</th>
                                    <th scope="col" class="font-custom-1 fs-7">Kode mata kuliah </th>
                                    <th scope="col" class="font-custom-1 fs-7">Mata Kuliah </th>

                                    <th scope="col" class="font-custom-1 fs-7">sks </th>

                                </tr>

                            </thead>
                            <tbody>
                                <tr v-for="(record, index) in krsRecords" :key="record.id" :ref="record.id"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td>{{ record.kode }}</td>
                                    <td>{{ record.mata_kuliah }}</td>
                                    <td>{{ record.sks }}</td>
                                    <td class="bg-transparent">
                                        <!-- <div class="d-flex gap-2"> -->
                                        <!-- <button class="btn btn-danger btn-sm fs-8" data-bs-toggle="modal"
                                                data-bs-target="#modal_delete" v-on:click="deleteRecord(record.id)"><i
                                                    class='bx bxs-trash-alt fs-8'></i></button> -->
                                        <!-- </div> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>






                </div>
                <!-- <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_edit', 'close_modal_edit')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>
                    </div> -->
            </div>
        </div>
    </div>

    <div class="modal fade" id="modal_view" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_view">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">View Data mahasiswa
                    </h5>
                    <button hidden type="button" ref="close_modal_view"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_view')">
                    </button>
                </div>

                <div class="modal-body">
                    <div id="pdf-content" class=" pdf-content container  position-relative" ref="pdf_content">
                        <div class=" position-absolute d-flex align-items-end rounded-bottom"
                            style="height: 300px; background-color: #003970; border-radius: 0 0 30px 30px!important; top:-20px;">
                            <div>
                                <img :src="currentRecord.foto_jas" alt=""
                                    style="width: 2in; height: 2in; object-fit: cover; object-position: top; cursor: pointer;"
                                    class="rounded-circle mb-4 mx-3 " @click="source_foto = currentRecord.foto_pas"
                                    v-on:click="showModal2('lihat_foto')">
                            </div>
                        </div>

                        <div class="text-end pt-3">
                            <img src="@/assets/img/logo_st_bhinneka.png" alt="" class="" width="170">
                        </div>

                        <div class="row">
                            <div class="col-3 text-center">
                            </div>
                            <div class="col-9 text-start" style="color: #003970; ">
                                <div class="fs-1  fw-bold" style="letter-spacing: 2px;">
                                    {{ currentRecord.nama_lengkap }}
                                </div>
                                <div class="fs-4 fw-bold">
                                    {{ currentRecord.prodi_pt }}
                                </div>
                                <div class="fs-5 ps-2">
                                    <div class="row ps-2 mb-2">
                                        <div class="col-4 row ps-2 align-items-center">
                                            <div class="fw-bold col-3" style="font-size: 15px">
                                                NIM
                                            </div>
                                            <div class=" fs-6 col-9">
                                                {{
                                                    currentRecord.nim_mahasiswa
                                                }}
                                            </div>
                                        </div>

                                        <div class="col-8  d-flex gap-3 align-items-center justify-content-start">
                                            <div class="fw-bold fs-6 d-flex justify-content-start align-items-center">
                                                <i class='bx bxl-gmail'></i>
                                            </div>
                                            <div class="ps-2" style="font-size: 15px;">
                                                {{ currentRecord.email }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ps-2 mb-2">
                                        <div class="col-4 row ps-2 align-items-center">
                                            <div class="fw-bold col-3" style="font-size: 15px">
                                                <i class='bx bxs-user-badge me-2'></i>
                                            </div>
                                            <div class=" fs-6 col-9">
                                                <span v-if="currentRecord.jalur == 'jalur_kuliah'">Mandiri</span>
                                                <span v-else-if="currentRecord.jalur == 'KIP'">KIP</span>
                                                <span
                                                    v-else-if="currentRecord.jalur == 'scholarship'">Scholarship</span>
                                            </div>
                                        </div>
                                        <!-- <div class="col-4 row ps-2 align-items-center">
                                            <div class="col-3  fw-bold " style="font-size: 15px">
                                                NIK
                                            </div>
                                            <div class="col-9 fs-6">
                                                {{ currentRecord.no_ktp }}
                                            </div>
                                        </div>
                                        <div class="col-5 row align-items-center">
                                            <div class="fw-bold col-1" style="font-size: 15px">
                                                KK
                                            </div>
                                            <div class=" col-10 fs-6 ps-3">
                                                {{ currentRecord.no_kk }}
                                            </div>
                                        </div> -->
                                        <div class="col-5 row align-items-center">
                                            <div class="fw-bold col-1" style="font-size: 15px">
                                                Wali
                                            </div>
                                            <div class="ps-4 col-11 fs-6 ">
                                                {{ currentRecord.dosen_wali }}
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row ps-2 mb-2">
                                        <div class="col-4 row ps-2 align-items-center">
                                            <div class="fw-bold col-3">
                                                <i class='bx bxs-phone'></i>
                                            </div>
                                            <div class=" col-9" style="font-size: 15px;">
                                                {{
                                                    currentRecord.telepon_siswa
                                                }}
                                            </div>
                                        </div>
                                        <div class="col-5 row align-items-center">
                                            <div class="col-1 fw-bold  ">
                                                <i class='bx bxl-whatsapp'></i>
                                            </div>
                                            <div class="ps-4 col-11" style="font-size: 15px;">
                                                {{ currentRecord.whatsapp }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ps-2 mb-2">
                                        <div class="col-4 row ps-2 align-items-center">
                                            <div class="fw-bold col-3">
                                                <i class='bx bx-door-open'></i>
                                            </div>
                                            <div class=" col-9" style="font-size: 15px;">
                                                Kelas {{ currentRecord.kelas
                                                }}
                                            </div>
                                        </div>

                                        <div class="col-5 row align-items-center">
                                            <div class="col-1 fw-bold  ">
                                                <i class='bx bx-calendar-week'></i>
                                            </div>
                                            <div class="ps-4 col-11" style="font-size: 15px;">
                                                Semester {{
                                                    currentRecord.semester }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ps-2 mb-2">
                                        <div class="col-4 row ps-2 align-items-center">
                                            <div class="fw-bold col-3">
                                                <i class='bx bxs-graduation'></i>
                                            </div>
                                            <div class=" col-9" style="font-size: 15px;">
                                                Kelas {{ currentRecord.status_akademik
                                                }}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div class="mt-3">
                            <div class="pt-5">
                                <ul class="nav nav-tabs   rounded justify-content-between"
                                    style="background-color: #003970;">
                                    <li class="nav-item " @click="setActiveTab('identitas_diri')"
                                        style="cursor:pointer;">
                                        <a class="nav-link text-light fw-bold" id="identitas_diri"
                                            ref="identitas_diri">Identitas
                                            Diri</a>
                                    </li>
                                    <li class="nav-item" @click="setActiveTab('data_keluarga')" style="cursor:pointer;">
                                        <a class="nav-link text-light fw-bold" id="data_keluarga"
                                            ref="data_keluarga">Data
                                            keluarga</a>
                                    </li>
                                    <li class="nav-item" @click="setActiveTab('data_sekolah')" style="cursor:pointer;">
                                        <a class="nav-link text-light fw-bold" id="data_sekolah" ref="data_sekolah">Data
                                            Sekolah</a>
                                    </li>
                                    <li class="nav-item" @click="setActiveTab('pilihan')" style="cursor:pointer;">
                                        <a class="nav-link text-light fw-bold" id="pilihan" ref="pilihan">Pilihan</a>
                                    </li>
                                    <li class="nav-item" @click="setActiveTab('dokumen')" style="cursor:pointer;">
                                        <a class="nav-link text-light fw-bold" id="dokumen" ref="dokumen">Dokumen</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="this.pageActive == 'identitas_diri'" class="border px-4 mb-4">
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Warga negara
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.warga_negara }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Tempat lahir
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.tempat_lahir }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Tanggal lahir
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.tanggal_lahir }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Jenis kelamin
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.jenis_kelamin }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Alamat tempat tinggal
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.alamat_siswa }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Provinsi
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.provinsi }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kota
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kota }}
                                </div>
                            </div>

                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kecamatan
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kecamatan }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kelurahan
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kelurahan }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kode pos
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kodepos }}
                                </div>
                            </div>

                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Agama
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.agama }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Hobi
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.hobi }}
                                </div>
                            </div>

                        </div>
                        <div v-if="this.pageActive == 'data_keluarga'" class="border px-4 mb-4">
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Jumlah Saudara
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.jumlah_bersaudara }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Anak Ke
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.anak_ke }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Nama Ayah Kandung
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.nama_ayah }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    No Hp Ayah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.no_hp_ayah }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Pekerjaan Ayah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.pekerjaan_ayah }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Pendidikan terakhir ayah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{
                                        currentRecord.pendidikan_terakhir_ayah
                                    }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Pendapatan Bulan Ayah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{
                                        rupiah(currentRecord.penghasilan_ayah)
                                    }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Nama Ibu Kandung
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.nama_ibu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    No Hp Ibu
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.no_hp_ibu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Pekerjaan Ibu
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.pekerjaan_ibu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Pendidikan terakhir ibu
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.pendidikan_terakhir_ibu
                                    }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Pendapatan Bulan Ibu
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ rupiah(currentRecord.penghasilan_ibu)
                                    }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Alamat Orang Tua
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.alamat_ortu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Provinsi Orang Tua
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.provinsi_ortu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kota Orang Tua
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kota_ortu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kecamatan Orang Tua
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kecamatan_ortu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kelurahan Orang Tua
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kelurahan_ortu }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kode Pos Orang tua
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kodepos_ortu }}
                                </div>
                            </div>

                        </div>
                        <div v-if="this.pageActive == 'data_sekolah'" class="border px-4 mb-4">
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Nama Sekolah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.nama_sekolah }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Provinsi Sekolah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.provinsi_sekolah }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Kota Sekolah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.kota_sekolah }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Jurusan Sekolah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.jurusan_sekolah }}
                                </div>
                            </div>
                        </div>
                        <div v-if="this.pageActive == 'pilihan'" class="border px-4 mb-4">
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Program Studi yang diminati
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.prodi_pt }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Waktu kuliah
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.waktu_kuliah }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Ukuran jas akademis
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.ukuran_jas_akademis }}
                                </div>
                            </div>
                            <div class="row mt-2 mb-2 align-items-center">
                                <div class="col-2 ">
                                    Tahun Ajaran
                                </div>
                                <div class="col-10 border rounded py-1 px-1">
                                    {{ currentRecord.tahun_ajaran }}
                                </div>
                            </div>

                        </div>
                        <div v-if="this.pageActive == 'dokumen'"
                            class="border px-2 py-2 mb-4 d-flex justify-content-between">
                            <div>
                                <div class="text-center">
                                    <!-- <img :src="currentRecord.foto_cover_rapor" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto = currentRecord.foto_cover_rapor; posisi_foto = 1; nama_foto = 'Foto Cover Rapor'"
                                        v-on:click="showModal2('lihat_foto2')">
                                     -->
                                    <div style="width: 1.5in; height: 2.5in; object-fit: cover;"
                                        class="d-flex justify-content-center align-items-center">
                                        <span><i class='bx bxs-check-circle text-success fs-1'></i></span>
                                    </div>

                                </div>
                                <div class="text-center">
                                    Foto Cover Rapor

                                </div>
                            </div>
                            <div>
                                <div class="">
                                    <!-- <img :src="currentRecord.foto_kartu_keluarga" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto = currentRecord.foto_kartu_keluarga; posisi_foto = 2; nama_foto = 'Foto Kartu keluarga'"
                                        v-on:click="showModal2('lihat_foto2')"> -->

                                    <div style="width: 1.5in; height: 2.5in; object-fit: cover;"
                                        class="d-flex justify-content-center align-items-center">
                                        <span v-if="currentRecord.st_ck_kk == 3 || currentRecord.st_ck_kk == '3'"><i
                                                class='bx bxs-check-circle text-success fs-1'></i></span>
                                        <span
                                            v-if="[2, 1].includes(currentRecord.st_ck_kk) || ['2', '1'].includes(currentRecord.st_ck_kk)">
                                            <i class='bx bxs-x-circle text-danger fs-1'></i>
                                        </span>
                                    </div>
                                </div>
                                <div class=" text-center">
                                    Foto Kartu keluarga
                                </div>
                            </div>
                            <div>
                                <div class="">
                                    <!-- <img :src="currentRecord.foto_data_diri_rapor" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto = currentRecord.foto_data_diri_rapor; posisi_foto = 3; nama_foto = 'Foto Data Diri Rapor'"
                                        v-on:click="showModal2('lihat_foto2')"> -->
                                    <div style="width: 1.5in; height: 2.5in; object-fit: cover;"
                                        class="d-flex justify-content-center align-items-center">
                                        <span><i class='bx bxs-check-circle text-success fs-1'></i></span>
                                    </div>
                                </div>
                                <div class="text-center">
                                    Foto Data Diri Rapor
                                </div>
                            </div>
                            <div>
                                <div class="">
                                    <!-- <img :src="currentRecord.foto_rapor_kelas_xii_ganjil_genap" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto = currentRecord.foto_rapor_kelas_xii_ganjil_genap; posisi_foto = 4; nama_foto = 'Foto Rapor Kelas 12 Ganjil/Genap'"
                                        v-on:click="showModal2('lihat_foto2')"> -->
                                    <div style="width: 1.5in; height: 2.5in; object-fit: cover;"
                                        class="d-flex justify-content-center align-items-center">
                                        <span><i class='bx bxs-check-circle text-success fs-1'></i></span>
                                    </div>
                                </div>
                                <div class="text-center">
                                    Foto Rapor Kelas 12 <br> Ganjil/Genap
                                </div>
                            </div>
                            <div>
                                <div class="">
                                    <!-- <img :src="currentRecord.foto_skl_skhun" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto = currentRecord.foto_skl_skhun; posisi_foto = 5; nama_foto = 'SKL/SKHUN'"
                                        v-on:click="showModal2('lihat_foto2')"> -->
                                    <div style="width: 1.5in; height: 2.5in; object-fit: cover;"
                                        class="d-flex justify-content-center align-items-center">
                                        <span><i class='bx bxs-check-circle text-success fs-1'></i></span>
                                    </div>
                                </div>
                                <div class="text-center">
                                    SKL/SKHUN
                                </div>
                            </div>
                            <div>
                                <div class="">
                                    <!-- <img :src="currentRecord.foto_ijazah" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto = currentRecord.foto_ijazah; posisi_foto = 6; nama_foto = 'Foto Ijazah'"
                                        v-on:click="showModal2('lihat_foto2')"> -->
                                    <div style="width: 1.5in; height: 2.5in; object-fit: cover;"
                                        class="d-flex justify-content-center align-items-center">
                                        <span
                                            v-if="currentRecord.st_ck_ijizah == 3 || currentRecord.st_ck_ijizah == '3'"><i
                                                class='bx bxs-check-circle text-success fs-1'></i></span>
                                        <span
                                            v-if="[2, 1].includes(currentRecord.st_ck_ijizah) || ['2', '1'].includes(currentRecord.st_ck_ijizah)">
                                            <i class='bx bxs-x-circle text-danger fs-1'></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="text-center">
                                    Foto Ijazah
                                </div>
                            </div>
                            <div>
                                <div class="">
                                    <!-- <img :src="currentRecord.foto_jas" alt=""
                                        style="width: 1.5in; height: 2.5in; object-fit: cover; cursor: pointer;"
                                        @click="source_foto = currentRecord.foto_jas; posisi_foto = 7; nama_foto = 'Foto Almameter'"
                                        v-on:click="showModal2('lihat_foto2')"> -->
                                    <div style="width: 1.5in; height: 2.5in; object-fit: cover;"
                                        class="d-flex justify-content-center align-items-center">
                                        <span><i class='bx bxs-check-circle text-success fs-1'></i></span>
                                    </div>
                                </div>
                                <div class="text-center">
                                    Foto Almameter
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideModal2('modal_view')">Tutup</button>
                    <!-- <button type="submit" class="btn bg-green-custom text-light">
                        Simpan </button> -->
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade bg-custom-gray" id="lihat_foto" data-bs-backdrop="static" role="dialog" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true" ref="lihat_foto">
        <div class="text-end position-absolute end-0 me-4 mt-2 " style="z-index: 9000!important;">
            <span class=" d-flex align-items-center" style="cursor: pointer;" v-on:click="hideModal2('lihat_foto')"><i
                    class='bx bx-x fs-1 text-danger fw-bold'></i>
            </span>
        </div>
        <div class="modal-dialog modal-xl modal-dialog-centered ">
            <div class="mb-3 w-100 text-center ">
                <img :src="source_foto" alt="" style="max-width: 100%; max-height: 80vh;">
            </div>
        </div>
    </div>

    <div class="modal fade bg-custom-gray" id="lihat_foto2" data-bs-backdrop="static" data-bs-keyboard="false"
        tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="lihat_foto2">
        <div class="text-end position-absolute end-0 me-4 mt-2 " style="z-index: 9000!important;">
            <span class=" d-flex align-items-center" style="cursor: pointer;" v-on:click="hideModal2('lihat_foto2')"><i
                    class='bx bx-x fs-1 text-danger fw-bold'></i>
            </span>
        </div>
        <div class="modal-dialog modal-xl modal-dialog-centered  ">
            <div class="mb-3 w-100 text-center ">
                <img :src="source_foto" alt="" style="max-width: 100%; max-height: 80vh;">
            </div>
        </div>
        <div class="px-3 py-3 fixed-bottom d-flex justify-content-between">
            <button class="btn btn-danger " @click="beforePhoto()">
                <i class='bx bx-left-arrow-alt'></i>
            </button>
            <div class="text-light">
                {{ nama_foto }}
            </div>
            <button class="btn btn-danger " @click="nextPhoto()">
                <i class='bx bx-right-arrow-alt'></i>
            </button>

        </div>
    </div>

    <div class="modal fade" id="modal_absensi" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_absensi">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Absensi Mahasiswa</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_absensi"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_absensi', 'close_modal_absensi')">
                    </button>
                </div>

                <div class="modal-body">

                    <div class="d-flex gap-2 justify-content-start ">

                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="id_tahun_ajaran_absensi" @change="get_absensi()" required>
                            <option value="" selected disabled>- Pilih Tahun ajaran</option>
                            <option v-for="list in tahun_ajaran_list_absensi" :key="list.tahun_ajaran" :value="list.id">
                                {{ list.tahun_ajaran }}
                            </option>
                        </select>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="id_semester_absensi" @change="get_absensi()" required
                            :disabled="id_tahun_ajaran_absensi == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semester_list_absensi" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>

                    <div class="border mt-1 rounded-3 me-2 font-custom-1">
                        <table class="table mb-0 bg-transparent table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col" class="bg-transparent fs-7">No</th>
                                    <th scope="col" class="font-custom-1 fs-7">NIM </th>
                                    <th scope="col" class="font-custom-1 fs-7">Nama Lengkap </th>
                                    <th scope="col" class="font-custom-1 fs-7">kode Matkul</th>
                                    <th scope="col" class="font-custom-1 fs-7">Mata Kuliah</th>
                                    <th scope="col" class="font-custom-1 fs-7">tipe</th>
                                    <th scope="col" class="font-custom-1 fs-7">M1</th>
                                    <th scope="col" class="font-custom-1 fs-7">M2</th>
                                    <th scope="col" class="font-custom-1 fs-7">M3</th>
                                    <th scope="col" class="font-custom-1 fs-7">M4</th>
                                    <th scope="col" class="font-custom-1 fs-7">M5</th>
                                    <th scope="col" class="font-custom-1 fs-7">M6</th>
                                    <th scope="col" class="font-custom-1 fs-7">M7</th>
                                    <th scope="col" class="font-custom-1 fs-7">M8</th>
                                    <th scope="col" class="font-custom-1 fs-7">M9</th>
                                    <th scope="col" class="font-custom-1 fs-7">M10</th>
                                    <th scope="col" class="font-custom-1 fs-7">M11</th>
                                    <th scope="col" class="font-custom-1 fs-7">M12</th>
                                    <th scope="col" class="font-custom-1 fs-7">M13</th>
                                    <th scope="col" class="font-custom-1 fs-7">M14</th>
                                    <th scope="col" class="font-custom-1 fs-7">M15</th>
                                    <th scope="col" class="font-custom-1 fs-7">M16</th>
                                    <th scope="col" class="font-custom-1 fs-7">jumlah kehadiran</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr v-for="(record, index) in record_absensi" :key="record.id" :ref="record.id"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">
                                        {{ index + 1 }}</th>
                                    <td>{{ record.nim_mahasiswa }}</td>
                                    <td>{{ record.nama_lengkap }}</td>
                                    <td>{{ record.kode }}</td>
                                    <td>{{ record.nama }}</td>
                                    <td>{{ record.tipe }}</td>
                                    <td>{{ record.M1 }}</td>
                                    <td>{{ record.M2 }}</td>
                                    <td>{{ record.M3 }}</td>
                                    <td>{{ record.M4 }}</td>
                                    <td>{{ record.M5 }}</td>
                                    <td>{{ record.M6 }}</td>
                                    <td>{{ record.M7 }}</td>
                                    <td>{{ record.M8 }}</td>
                                    <td>{{ record.M9 }}</td>
                                    <td>{{ record.M10 }}</td>
                                    <td>{{ record.M11 }}</td>
                                    <td>{{ record.M12 }}</td>
                                    <td>{{ record.M13 }}</td>
                                    <td>{{ record.M14 }}</td>
                                    <td>{{ record.M15 }}</td>
                                    <td>{{ record.M16 }}</td>
                                    <td>{{ record.jumlah_kehadiran }}</td>
                                    <td class="bg-transparent">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>



            </div>
        </div>
    </div>

    <div class="modal fade" id="modal_mata_kuliah_nilai" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_mata_kuliah_nilai">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Nilai {{ currentRecord.nama_lengkap }}</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_mata_kuliah_nilai"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_mata_kuliah_nilai')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="d-flex gap-2 justify-content-start ">

                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="id_tahun_ajaran_absensi" @change="nilaiMataKuliahGet()" required>
                            <option value="" selected disabled>- Pilih Tahun ajaran</option>
                            <option v-for="list in tahun_ajaran_list_absensi" :key="list.tahun_ajaran" :value="list.id">
                                {{ list.tahun_ajaran }}
                            </option>
                        </select>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="id_semester_absensi" @change="nilaiMataKuliahGet()" required
                            :disabled="id_tahun_ajaran_absensi == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semester_list_absensi" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="border mt-1 rounded-3  font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;">
                        <table class="table mb-0 bg-transparent table-borderless" style="">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Nama Mata Kuliah</th>
                                    <th>status</th>
                                    <!-- <th>Finalisasi UTS</th>
                                    <th>Finalisasi UAS</th>
                                    <th>Finalisasi Tugas</th> -->
                                    <th>Tugas</th>
                                    <th>Nilai UTS</th>
                                    <th>Nilai UAS</th>
                                    <th>Nilai Indeks</th>
                                    <th>Nilai Huruf</th>
                                    <!-- <th>Nilai UTS (Susulan)</th>
                                    <th>Nilai UAS (Susulan)</th> -->
                                    <!-- <th>Aksi</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( record, index ) in mataKuliahNilaiRecords " :key="record.id"
                                    :ref="record.id" class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td>{{ record.nama }}</td>
                                    <td>{{ record.status_input_nilai }}</td>
                                    <!-- <td :class="{ 'text-success': record.status_nilai_uts == 'FINAL' }">{{
                                                    record.status_nilai_uts }}</td>
                                    <td>{{ record.status_nilai_uas }}</td>
                                    <td>{{ record.status_nilai_tugas }}</td> -->
                                    <td>
                                        <span>{{
                                            record.tugas }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.uts }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.uas }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.nilai_indeks }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.nilai_huruf }}</span>
                                    </td>
                                    <!-- <td><span v-if="record.susulan_uts == '-1'">-</span> <span
                                            v-if="record.susulan_uts != '-1'">{{
                                                record.susulan_uts }}</span></td>
                                    <td><span v-if="record.susulan_uas == '-1'">-</span> <span
                                            v-if="record.susulan_uas != '-1'">{{
                                                record.susulan_uas }}</span></td> -->
                                    <!-- <td class="bg-transparent">
                                        <div class="d-flex gap-2" v-if="record.status_input_nilai == 'nilai diinput'">
                                            <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                                @click="editRecordNilai(record);"><i
                                                    class='bx bxs-message-square-edit fs-8'></i></button>
                                        </div>
                                    </td> -->
                                </tr>
                                <tr v-if="mataKuliahNilaiRecords.length > 0">
                                    <td>
                                    </td>
                                    <td>
                                        <span class="fw-bold">IP</span>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                        <span>{{ ipk_kumulatif }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_mata_kuliah_nilai')">Tutup</button>
                    <!-- <button type="submit" class="btn bg-green-custom text-light">
                        Simpan </button> -->
                </div>
            </div>

        </div>
    </div>


    <div class="modal fade" id="modal_laporan_mahasiswa" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_mahasiswa">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Kehadiran Mahasiswa</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_mahasiswa')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">tahun ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.tahun_ajaran" :value="list.id">
                                {{ list.tahun_ajaran }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="prodi" class="form-label">Prodi</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih prodi</option>
                            <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="semester" class="form-label">Semester</label>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="index()" required
                            :disabled="prodiFilter == '' || tahunAjaranFilter == ''">
                            <option value="" selected disabled>- Pilih semester</option>
                            <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="kelas" class="form-label">Stambuk</label>
                        <select class="form-select" aria-label="Default select example" name="stambuk" id="stambuk"
                            v-model="stambukFilter" @change="index()" required :disabled="prodiFilter == ''">
                            <option value="" selected disabled>- Pilih Stambuk</option>
                            <option v-for="list in stambukList" :key="list.tahun_ajaran" :value="list.id">
                                {{ list.tahun_ajaran }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="kelas" class="form-label">Kelas</label>
                        <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                            v-model="kelasFilter" @change="index()" required :disabled="prodiFilter == ''">
                            <option value="" selected disabled>- Pilih kelas</option>
                            <option v-for="list in kelasList" :key="list.nama" :value="list.id">
                                {{ list.nama }} ({{ list.waktu_kuliah }})
                            </option>
                        </select>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_mahasiswa')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateExcelKehadiranMahasiswa()">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_laporan_nilai_mahasiswa" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_nilai_mahasiswa">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Nilai Mahasiswa</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_nilai_mahasiswa')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">tahun ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.tahun_ajaran" :value="list.id">
                                {{ list.tahun_ajaran }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="prodi" class="form-label">Prodi</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih prodi</option>
                            <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="semester" class="form-label">Semester</label>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="index()" required
                            :disabled="prodiFilter == '' || tahunAjaranFilter == ''">
                            <option value="" selected disabled>- Pilih semester</option>
                            <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="kelas" class="form-label">Stambuk</label>
                        <select class="form-select" aria-label="Default select example" name="stambuk" id="stambuk"
                            v-model="stambukFilter" @change="index()" required :disabled="prodiFilter == ''">
                            <option value="" selected disabled>- Pilih Stambuk</option>
                            <option v-for="list in stambukList" :key="list.tahun_ajaran" :value="list.id">
                                {{ list.tahun_ajaran }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="kelas" class="form-label">Kelas</label>
                        <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                            v-model="kelasFilter" @change="index()" required :disabled="prodiFilter == ''">
                            <option value="" selected disabled>- Pilih kelas</option>
                            <option v-for="list in kelasList" :key="list.nama" :value="list.id">
                                {{ list.nama }} ({{ list.waktu_kuliah }})
                            </option>
                        </select>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_nilai_mahasiswa')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light" @click="generateExcelNilaiMahasiswa()">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_laporan_ipk_mahasiswa" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_ipk_mahasiswa">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan IPK Mahasiswa</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_ipk_mahasiswa')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">tahun ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.tahun_ajaran" :value="list.id">
                                {{ list.tahun_ajaran }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="semester" class="form-label">Semester</label>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="index()" required :disabled="tahunAjaranFilter == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>

                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_ipk_mahasiswa')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light" @click="generateExcelIPKMahasiswa()">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>
    <div class="modal fade" id="modal_transkrip_akademik" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_transkrip_akademik">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Trankrip Akademik</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_transkrip_akademik"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_transkrip_akademik')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="border mt-1 rounded-3  font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;"
                        v-if="transkripAkademikRecords.length != 0">
                        <table class="table mb-0 bg-transparent table-borderless" style="">
                            <tbody>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Nama</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[8][0].nama_lengkap }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">NIM</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[8][0].nim_mahasiswa }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Tempat & Tanggal Lahir</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[8][0].tempat_lahir }} & {{
                                            transkripAkademikRecords[8][0].tanggal_lahir }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Program Pendidikan</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[8][0].jenjang_studi }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Fakultas / Program Studi</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[8][0].fakultas }} / {{
                                            transkripAkademikRecords[8][0].prodi }}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div class="border mt-1 rounded-3  font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;">
                        <table class="table mb-0 bg-transparent table-borderless" style="">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Kode Mata Kuliah</th>
                                    <th>Mata Kuliah</th>
                                    <th>H</th>

                                    <th>A</th>
                                    <th>K</th>
                                    <th>M</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( record, index ) in transkripAkademikRecords[0] " :key="record.id"
                                    :ref="record.id" class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td>{{ record.kode }}</td>
                                    <td>{{ record.mata_kuliah }}</td>
                                    <!-- <td :class="{ 'text-success': record.status_nilai_uts == 'FINAL' }">{{
                                                    record.status_nilai_uts }}</td>
                                    <td>{{ record.status_nilai_uas }}</td>
                                    <td>{{ record.status_nilai_tugas }}</td> -->
                                    <td>
                                        <span>{{
                                            record.nilai_huruf }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.nilai_mutu }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.sks }}</span>
                                    </td>
                                    <td>
                                        <span>{{
                                            record.nilai_mutu_2 }}</span>
                                    </td>
                                </tr>
                                <tr v-if="transkripAkademikRecords.length > 0">
                                    <td>
                                    </td>
                                    <td colspan="2">
                                        <span>H = Huruf, A = Angka, K = Kredit, M = Mutu</span>
                                    </td>

                                    <td colspan="2">Total
                                    </td>
                                    <td>{{ transkripAkademikRecords[2][0].jumlah_sks }}
                                    </td>
                                    <td>{{ transkripAkademikRecords[2][0].jumlah_nilai_mutu_2 }}
                                    </td>


                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="border mt-1 rounded-3  font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;"
                        v-if="transkripAkademikRecords.length != 0">
                        <table class="table mb-0 bg-transparent table-borderless" style="">
                            <tbody>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Jumlah SKS Lulus</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[2][0].jumlah_sks_lulus }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Jumlah SKS Nilai D</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[2][0].jumlah_nilai_d }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Jumlah SKS Nilai E</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[2][0].jumlah_nilai_e }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Jumlah SKS praktikum yang gagal</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[2][0].jumlah_sks_gagal }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">Jumlah SKS yang belum diambil </td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[2][0].jumlah_sks_belum_diambil }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">IPK Jika Semua Lulus (Min. Nilai C)</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[2][0].jumlah_ipk_lulus.toFixed(2) }}
                                    </td>
                                </tr>
                                <tr class="border-bottom ">
                                    <td scope="row" class="bg-transparent ">IPK Sementara</td>
                                    <td scope="row" class="bg-transparent ">:</td>
                                    <td scope="row" class="bg-transparent ">
                                        {{ transkripAkademikRecords[7][0].nilai_kumulatif.toFixed(2) }}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_transkrip_akademik')">Tutup</button>
                    <!-- <button type="submit" class="btn bg-green-custom text-light">
                        Simpan </button> -->
                </div>
            </div>

        </div>
    </div>
</template>


<style scoped>
.aksi-item-danger.dropdown-item:hover {
    color: white !important;
    background-color: #dc3545 !important;
}

.aksi-item-primary.dropdown-item:hover {
    color: white !important;
    background-color: #007bff !important;
}

.aksi-item-warning.dropdown-item:hover {
    color: white !important;
    background-color: #f0ad4e !important;
}

.aksi-item-success.dropdown-item:hover {
    color: white !important;
    background-color: #5cb85c !important;
}

.aksi-item-gray.dropdown-item:hover {
    color: white !important;
    background-color: #292b2c !important;
}

.aksi-item-white.dropdown-item:hover {
    color: #dc3545 !important;
    background-color: white;
}

.aksi-item-light.dropdown-item:hover {
    color: white !important;
    background-color: #6c757d !important;
}

.aksi-item-info.dropdown-item:hover {
    color: white !important;
    background-color: #5bc0de !important;
}

.aksi-item-red.dropdown-item:hover {
    color: white !important;
    background-color: #dc3545 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #003970 !important;
    border-radius: 50px;

}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    /* border-color: #003970 !important; */
    border: none !important;
}


.custom-select .vs__dropdown-toggle,
.custom-select .vs__open-indicator {
    border: none;
}

.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, base_url, CloseLoading, ShowLoading, menu_akses_cek, ErrorConnectionTimeOut, AlertBottom, base_url_wilayah_indonesia, CharOnly, NumberOnly, wargaNegaraList, statusAkademikList, openInNewTab, rupiah } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";
import XLSX from "xlsx/dist/xlsx.full.min.js";


import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
    data() {
        return {
            //data
            url: 'mahasiswa_wali',
            api: "",
            ipk_kumulatif: "",

            //krs
            prodiKrs: "",
            semesterKrs: "",
            tahunAjarankrs: "",
            krsId: "",
            krsRecords: [],
            semesterRecords: [],
            dosen_wali: "",
            jumlah_sks_diambil: "",
            tahun_akademik: "",
            counter_semester: "",
            tipe_semester: "",
            kelas: "",


            prodiList: [],
            wargaNegaraList: [],
            statusAkademikList: [],
            semesterList: [],
            provinsiList: [],
            kotaList: [],
            kecamatanList: [],
            kelurahanList: [],
            tahunAjaranList: [],
            jalurList: [],
            kelasList: [],
            stambukList: [],

            kotaList_ortu: [],
            provinsiList_ortu: [],
            kecamatanList_ortu: [],
            kelurahanList_ortu: [],

            kotaList_sekolah: [],
            provinsiList_sekolah: [],

            provinsi: "",
            kota: "",
            kecamatan: "",
            kelurahan: "",

            provinsi_update: "",
            kota_update: "",
            kecamatan_update: "",
            kelurahan_update: "",

            provinsi_ortu: "",
            kota_ortu: "",
            kecamatan_ortu: "",
            kelurahan_ortu: "",

            provinsi_ortu_update: "",
            kota_ortu_update: "",
            kecamatan_ortu_update: "",
            kelurahan_ortu_update: "",

            provinsi_sekolah: "",
            kota_sekolah: "",

            provinsi_sekolah_update: "",
            kota_sekolah_update: "",

            //pages
            limit: "10",
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,

            //format
            tanggal_minimum: "",
            tanggal_hari_ini: "",
            tanggal_maksimum: "",

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            records: [],
            newRecord: {
                foto_pas: "",
                nama_lengkap: "",
                nisn_sekolah: "",
                warga_negara: "WNI",
                no_ktp: "",
                no_kk: "",
                tempat_lahir: "",
                tanggal_lahir: "",
                jenis_kelamin: "Pria",
                alamat_siswa: "",

                kota: "",
                provinsi: "",
                kecamatan: "",
                kelurahan: "",

                kodepos: "",
                telepon_siswa: "",
                whatsapp: "",
                jumlah_bersaudara: "",
                anak_ke: "",
                agama: "",
                hobi: "",
                nama_ayah: "",
                no_hp_ayah: "",
                pekerjaan_ayah: "",
                pendidikan_terakhir_ayah: "",
                penghasilan_ayah: "",
                nama_ibu_kandung: "",
                no_hp_ibu: "",
                pekerjaan_ibu: "",
                pendidikan_terakhir_ibu: "",
                penghasilan_ibu: "",
                alamat_ortu: "",
                kota_ortu: "",
                provinsi_ortu: "",
                kecamatan_ortu: "",
                kelurahan_ortu: "",
                kodepos_ortu: "",

                nama_sekolah: "",
                kota_sekolah: "",
                provinsi_sekolah: "",
                jurusan_sekolah: "",

                prodi_pt: "",
                waktu_kuliah: "",
                ukuran_jas_akademis: "",

                foto_kartu_keluarga: "",
                foto_cover_rapor: "",
                foto_data_diri_rapor: "",
                foto_rapor_kelas_xii_ganjil_genap: "",
                foto_skl_skhun: "",
                foto_ijazah: "",
                email: "",
                prodi: "",

                status_akademik: "",
            },
            currentRecord: {},

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            //filter
            prodiFilter: "",
            tahunAjaranFilter: "",
            jalurFilter: "",
            semesterFilter: "",
            stambukFilter: "",
            kelasFilter: "",

            //query 
            query: "",

            pageActive: "",
            source_foto: "",
            posisi_foto: "",
            nama_foto: "",

            //transkrip akademik
            transkripAkademikRecords: [],

            //absensi 
            id_mahasiswa_absensi: "",
            id_profiles_absensi: "",
            id_tahun_ajaran_absensi: "",
            id_semester_absensi: "",

            tahun_ajaran_list_absensi: [],
            semester_list_absensi: [],
            record_absensi: [],

            mataKuliahNilaiRecords: []
        }
    },
    components: {
        SideNavbar, 'v-select': vSelect
    },
    watch: {
        'provinsi': function () {
            if (this.provinsi == null) {
                this.newRecord.provinsi = "";
            } else {
                this.newRecord.provinsi = this.provinsi.nama;
                this.kabupaten_index(this.provinsi.value, 'siswa');
                this.kota = null;
                this.kecamatan = null;
                this.kelurahan = null;
            }

        },
        'kota': function () {
            if (this.kota == null) {
                this.newRecord.kota = "";
            } else {
                this.newRecord.kota = this.kota.nama;
                this.kecamatan_index(this.kota.value, 'siswa');
                this.kecamatan = null;
                this.kelurahan = null;
            }
        },
        'kecamatan': function () {

            if (this.kecamatan == null) {
                this.newRecord.kecamatan = "";
            } else {

                this.newRecord.kecamatan = this.kecamatan.nama;
                this.kelurahan_index(this.kecamatan.value, 'siswa');
                this.kelurahan = null;
            }
        },
        'kelurahan': function () {

            if (this.kelurahan == null) {
                this.newRecord.kelurahan = "";
            } else {

                this.newRecord.kelurahan = this.kelurahan.nama;
            }

        },
        'provinsi_update': function () {
            if (this.provinsi_update == null) {
                this.currentRecord.provinsi = "";
            } else {
                this.currentRecord.provinsi = this.provinsi_update.nama;
                this.kabupaten_index(this.provinsi_update.value, 'siswa');
                this.kota_update = null;
                this.kecamatan_update = null;
                this.kelurahan_update = null;
            }

        },
        'kota_update': function () {
            if (this.kota_update == null) {
                this.currentRecord.kota = "";
            } else {
                this.currentRecord.kota = this.kota_update.nama;
                this.kecamatan_index(this.kota_update.value, 'siswa');
                this.kecamatan_update = null;
                this.kelurahan_update = null;
            }
        },
        'kecamatan_update': function () {

            if (this.kecamatan_update == null) {
                this.currentRecord.kecamatan = "";
            } else {

                this.currentRecord.kecamatan = this.kecamatan_update.nama;
                this.kelurahan_index(this.kecamatan_update.value, 'siswa');
                this.kelurahan_update = null;
            }
        },
        'kelurahan_update': function () {

            if (this.kelurahan_update == null) {
                this.currentRecord.kelurahan = "";
            } else {

                this.currentRecord.kelurahan = this.kelurahan_update.nama;
            }

        },
        'provinsi_ortu': function () {
            if (this.provinsi_ortu == null) {
                this.newRecord.provinsi_ortu = "";
            } else {
                this.newRecord.provinsi_ortu = this.provinsi_ortu.nama;
                this.kabupaten_index(this.provinsi_ortu.value, 'ortu');
                this.kota_ortu = null;
                this.kecamatan_ortu = null;
                this.kelurahan_ortu = null;
            }

        },
        'kota_ortu': function () {
            if (this.kota_ortu == null) {
                this.newRecord.kota_ortu = "";
            } else {
                this.newRecord.kota_ortu = this.kota_ortu.nama;
                this.kecamatan_index(this.kota_ortu.value, 'ortu');
                this.kecamatan_ortu = null;
                this.kelurahan_ortu = null;
            }
        },
        'kecamatan_ortu': function () {

            if (this.kecamatan_ortu == null) {
                this.newRecord.kecamatan_ortu = "";
            } else {

                this.newRecord.kecamatan_ortu = this.kecamatan_ortu.nama;
                this.kelurahan_index(this.kecamatan_ortu.value, 'ortu');
                this.kelurahan_ortu = null;
            }

        },
        'kelurahan_ortu': function () {
            if (this.kelurahan_ortu == null) {
                this.newRecord.kelurahan_ortu = "";
            } else {
                this.newRecord.kelurahan_ortu = this.kelurahan_ortu.nama;
            }

        },
        'provinsi_ortu_update': function () {
            if (this.provinsi_ortu_update == null) {
                this.currentRecord.provinsi_ortu = "";
            } else {
                this.currentRecord.provinsi_ortu = this.provinsi_ortu_update.nama;
                this.kabupaten_index(this.provinsi_ortu_update.value, 'ortu');
                this.kota_ortu_update = null;
                this.kecamatan_ortu_update = null;
                this.kelurahan_ortu_update = null;
            }

        },
        'kota_ortu_update': function () {
            if (this.kota_ortu_update == null) {
                this.currentRecord.kota_ortu = "";
            } else {
                console.log(this.kota_ortu_update);
                this.currentRecord.kota_ortu = this.kota_ortu.nama;
                this.kecamatan_index(this.kota_ortu_update.value, 'ortu');
                this.kecamatan_ortu_update = null;
                this.kelurahan_ortu_update = null;
            }
        },
        'kecamatan_ortu_update': function () {

            if (this.kecamatan_ortu_update == null) {
                this.currentRecord.kecamatan_ortu_update = "";
            } else {

                this.currentRecord.kecamatan_ortu_update = this.kecamatan_ortu_update.nama;
                this.kelurahan_index(this.kecamatan_ortu_update.value, 'ortu');
                this.kelurahan_ortu_update = null;
            }

        },
        'kelurahan_ortu_update': function () {
            if (this.kelurahan_ortu_update == null) {
                this.currentRecord.kelurahan_ortu_update = "";
            } else {
                this.currentRecord.kelurahan_ortu_update = this.kelurahan_ortu_update.nama;
            }

        },
        'provinsi_sekolah': function () {
            if (this.provinsi_sekolah == null) {
                this.newRecord.provinsi_sekolah = "";
            } else {
                this.newRecord.provinsi_sekolah = this.provinsi_sekolah.nama;
                this.kabupaten_index(this.provinsi_sekolah.value, 'sekolah');
                this.kota_sekolah = null;
            }

        },
        'kota_sekolah': function () {
            if (this.kota_sekolah == null) {
                this.newRecord.kota_sekolah = "";
            } else {
                this.newRecord.kota_sekolah = this.kota_sekolah.nama;
            }
        },
        'provinsi_sekolah_update': function () {
            if (this.provinsi_sekolah_update == null) {
                this.currentRecord.provinsi_sekolah = "";
            } else {
                this.currentRecord.provinsi_sekolah = this.provinsi_sekolah_update.nama;
                this.kabupaten_index(this.provinsi_sekolah_update.value, 'sekolah');
                this.kota_sekolah_update = null;
            }

        },
        'kota_sekolah_update': function () {
            if (this.kota_sekolah_update == null) {
                this.currentRecord.kota_sekolah = "";
            } else {
                this.currentRecord.kota_sekolah = this.kota_sekolah_update.nama;
            }
        },


    },

    methods: {
        //data
        CharOnly,
        NumberOnly,
        openInNewTab,
        rupiah,
        addNumberToObjects(objArray) {
            for (let i = 0; i < objArray.length; i++) {
                const newObj = { No: i + 1, ...objArray[i] };
                objArray[i] = newObj;
            }
            return objArray;
        },
        generateExcelNilaiMahasiswa() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("semester", this.semesterFilter);
            formData.append("kelas", this.kelasFilter);

            api = base_url + 'generate_laporan_nilai_mahasiswa';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Nilai Mahasiswa');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Nilai Mahasiswa' + ".xlsx");
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateExcelIPKMahasiswa() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("semester", this.semesterFilter);

            api = base_url + 'generate_laporan_ipk_wali_mahasiswa';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Nilai Mahasiswa');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Nilai Mahasiswa' + ".xlsx");
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateExcelKehadiranMahasiswa() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("semester", this.semesterFilter);
            formData.append("kelas", this.kelasFilter);

            api = base_url + 'generate_laporan_kehadiran_mahasiswa';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Kehadiran Mahasiswa');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Kehadiran Mahasiswa' + ".xlsx");
                        CloseLoading();

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        nilaiMataKuliahGet() {
            this.mataKuliahNilaiRecords = [];

            ShowLoading();
            let formData = new FormData();

            formData.append("tahun_ajaran", this.id_tahun_ajaran_absensi);
            formData.append("semester", this.id_semester_absensi);
            formData.append("mahasiswa", this.id_mahasiswa_absensi);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'nilai_wali_get';
            } else {
                this.api = base_url + 'nilai_wali_get';
            }


            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.tahun_ajaran_list_absensi = response.data.tahun_ajaran;
                        this.semester_list_absensi = response.data.semester;
                        if (response.data.data != undefined) {
                            this.mataKuliahNilaiRecords = response.data.data[0];
                            this.ipk_kumulatif = response.data.data[3][0].nilai_kumulatif;
                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        this.hideModal2('modal_mata_kuliah_nilai');
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        transkripAkademikGet() {

            this.mataKuliahNilaiRecords = [];

            ShowLoading();
            let formData = new FormData();
            formData.append("mahasiswa", this.id_mahasiswa_absensi);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'transkrip_akademik_get';
            } else {
                this.api = base_url + 'transkrip_akademik_get';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        console.log(response.data);
                        if (response.data.data != undefined) {
                            this.transkripAkademikRecords = response.data.data;

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        this.hideModal2('modal_mata_kuliah_nilai');
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        get_absensi() {
            ShowLoading();
            let formData = new FormData();

            formData.append("mahasiswa", this.id_mahasiswa_absensi);
            formData.append("tahun_ajaran", this.id_tahun_ajaran_absensi);
            formData.append("semester", this.id_semester_absensi);

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'mahasiswa_get_absensi';
            } else {
                this.api = base_url + 'mahasiswa_get_absensi';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        this.tahun_ajaran_list_absensi = response.data.tahun_ajaran;
                        this.semester_list_absensi = response.data.semester;
                        if (response.data.data != undefined) {
                            this.record_absensi = response.data.data[0];
                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    AlertPopup("error", "", "mohon maaf terjadi kesalahan silahkan coba lagi", 1500, false);
                    ErrorConnectionTimeOut(error);
                });

        },
        provinsi_index() {
            axios
                .post(base_url_wilayah_indonesia + "provinsi_index", '')
                .then((response) => {
                    if (response.data.status == true) {
                        // AlertPopup("success", response.data.message, "", 1500, false);
                        this.provinsiList = response.data.data.map((item) => ({
                            nama: item.nama,
                            value: item.id,
                            label: item.nama,
                        }));

                        this.provinsiList_ortu = response.data.data.map((item) => ({
                            nama: item.nama,
                            value: item.id,
                            label: item.nama,
                        }));
                        this.provinsiList_sekolah = response.data.data.map((item) => ({
                            nama: item.nama,
                            value: item.id,
                            label: item.nama,
                        }));
                    }
                    if (response.data.status == false) {
                        // AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        kabupaten_index(id, jenis, update_kota, update_kecamatan, update_kelurahan) {

            if (update_kota == undefined) {
                const formData = new FormData();
                formData.append("id", id);
                axios
                    .post(base_url_wilayah_indonesia + "kabupaten_index", formData)
                    .then((response) => {
                        if (response.data.status == true) {
                            if (jenis == "ortu") {
                                this.kotaList_ortu = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));
                            }
                            if (jenis == "siswa") {
                                if (response.data.data == null) {
                                    //asd
                                } else {
                                    this.kotaList = response.data.data.map((item) => ({
                                        nama: item.nama,
                                        value: item.id,
                                        label: item.nama,
                                    }));
                                }

                            }
                            if (jenis == "sekolah") {
                                this.kotaList_sekolah = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));
                            }
                        }
                        if (response.data.status == false) {
                            //as
                        }
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                    });

            } else {

                const formData = new FormData();
                formData.append("id", id);
                axios
                    .post(base_url_wilayah_indonesia + "kabupaten_index", formData)
                    .then((response) => {
                        if (response.data.status == true) {
                            if (jenis == "ortu") {
                                this.kotaList_ortu = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));
                                const indexKota = this.kotaList_ortu.findIndex(
                                    (record) => record.nama === update_kota
                                )
                                this.kecamatan_index(this.kotaList_ortu[indexKota].value, 'ortu', update_kecamatan, update_kelurahan);
                                this.kota_ortu_update = update_kota;
                            }
                            if (jenis == "siswa") {

                                this.kotaList = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));

                                const indexKota = this.kotaList.findIndex(
                                    (record) => record.nama === update_kota
                                )
                                this.kecamatan_index(this.kotaList[indexKota].value, 'siswa', update_kecamatan, update_kelurahan);
                                this.kota_update = update_kota;

                            }
                            if (jenis == "sekolah") {
                                this.kotaList_sekolah = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));
                                const indexKota = this.kotaList_sekolah.findIndex(
                                    (record) => record.nama === update_kota
                                )
                                this.kecamatan_index(this.kotaList_sekolah[indexKota].value, 'sekolah');
                                this.kota_sekolah_update = update_kota;
                            }
                        }
                        if (response.data.status == false) {
                            //as
                        }
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                    });
            }
        },
        kecamatan_index(id, jenis, update_kecamatan, update_kelurahan) {
            if (update_kecamatan == undefined) {
                const formData = new FormData();
                formData.append("id", id);
                axios
                    .post(base_url_wilayah_indonesia + "kecamatan_index", formData)
                    .then((response) => {
                        if (response.data.status == true) {

                            if (jenis == "ortu") {
                                this.kecamatanList_ortu = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));
                            }
                            if (jenis == "siswa") {
                                // this.kecamatanList = response.data.data;
                                if (response.data.data != null) {
                                    this.kecamatanList = response.data.data.map((item) => ({
                                        nama: item.nama,
                                        value: item.id,
                                        label: item.nama,
                                    }));
                                }

                            }
                        }
                        if (response.data.status == false) {
                            //
                        }
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                    });
            }
            else {
                const formData = new FormData();
                formData.append("id", id);
                axios
                    .post(base_url_wilayah_indonesia + "kecamatan_index", formData)
                    .then((response) => {
                        if (response.data.status == true) {

                            if (jenis == "ortu") {
                                this.kecamatanList_ortu = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));
                                const indexkecamatan = this.kecamatanList_ortu.findIndex(
                                    (record) => record.nama === update_kecamatan
                                )
                                this.kelurahan_index(this.kecamatanList_ortu[indexkecamatan].value, 'ortu', update_kelurahan);
                                this.kecamatan_ortu_update = update_kecamatan;
                            }
                            if (jenis == "siswa") {
                                // this.kecamatanList = response.data.data;
                                this.kecamatanList = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));

                                const indexkecamatan = this.kecamatanList.findIndex(
                                    (record) => record.nama === update_kecamatan
                                )
                                this.kelurahan_index(this.kecamatanList[indexkecamatan].value, 'siswa', update_kelurahan);
                                this.kecamatan_update = update_kecamatan;
                            }
                        }
                        if (response.data.status == false) {
                            //
                        }
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                    });
            }

        },
        kelurahan_index(id, jenis, update_kelurahan) {
            if (update_kelurahan == undefined) {
                const formData = new FormData();
                formData.append("id", id);
                axios
                    .post(base_url_wilayah_indonesia + "kelurahan_index", formData)
                    .then((response) => {
                        if (response.data.status == true) {
                            if (jenis == "ortu") {
                                this.kelurahanList_ortu = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));
                            }
                            if (jenis == "siswa") {
                                if (response.data.data != null) {
                                    this.kelurahanList = response.data.data.map((item) => ({
                                        nama: item.nama,
                                        value: item.id,
                                        label: item.nama,
                                    }));
                                }

                            }

                        }
                        if (response.data.status == false) {
                            //
                        }
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                    });
            } else {
                const formData = new FormData();
                formData.append("id", id);
                axios
                    .post(base_url_wilayah_indonesia + "kelurahan_index", formData)
                    .then((response) => {
                        if (response.data.status == true) {

                            if (jenis == "ortu") {
                                this.kelurahanList_ortu = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));
                                this.kelurahan_ortu_update = update_kelurahan;
                            }
                            if (jenis == "siswa") {
                                this.kelurahanList = response.data.data.map((item) => ({
                                    nama: item.nama,
                                    value: item.id,
                                    label: item.nama,
                                }));

                                this.kelurahan_update = update_kelurahan;
                            }

                        }
                        if (response.data.status == false) {
                            //
                        }
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                    });
            }
        },
        setkrsId(id, semester) {
            this.krsId = 0;
            if (id != undefined) {
                this.krsId = id;
            }

            this.krsRecords = [];
            this.tahun_akademik = "";
            this.tipe_semester = "";
            this.counter_semester = "";
            this.kelas = "";
            this.getKrs(semester);
        },
        getKrs(semester) {
            this.dosen_wali = "";
            this.jumlah_sks_diambil = "";
            ShowLoading();
            let formData = new FormData();

            formData.append("mahasiswa", this.krsId);

            formData.append("semester", semester);

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'get_krs';
            } else {
                this.api = base_url + 'get_krs';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {

                        if (response.data.data != undefined) {
                            if (semester == 0) {
                                this.semesterRecords = response.data.data[0];
                                console.log(response.data.data[0]);
                            } else {
                                this.krsRecords = response.data.data[0];
                                this.dosen_wali = response.data.dosen_wali.dosen_wali;
                                this.jumlah_sks_diambil = response.data.jumlah_sks_diambil[0].jumlah_sks_diambil;
                                this.kelas = response.data.kelas[0].kelas;
                                this.tahun_akademik = response.data.tahun_akademik[0].tahun_akademik;
                                this.counter_semester = response.data.counter_semester[0].counter_semester;
                                this.tipe_semester = response.data.tipe_semester[0].tipe_semester;
                            }


                            // this.totalPages = response.data.data[0][0].number_page;
                            // this.previousLastId = response.data.data[1].id;
                            // this.previousLastIdOld = response.data.data[1].id;
                            // let last_index = Object.keys(response.data.data[0]).pop();

                            // this.NextLastId = response.data.data[1][last_index].id;
                            // this.NextLastIdOld = response.data.data[1][last_index].id;
                            // this.arrayLastIdOld.push(response.data.data[1][last_index].id);
                            // this.arrayPreviousIdOld.push(response.data.data[1][0].id);

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    AlertPopup("error", "", "mohon maaf terjadi kesalahan silahkan coba lagi", 1500, false);
                    ErrorConnectionTimeOut(error);
                });

        },

        index() {
            this.records = [];
            ShowLoading();
            let formData = new FormData();

            formData.append("previous_id", '');
            formData.append("last_id", '');
            formData.append("limit", this.limit);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("stambuk", this.stambukFilter);
            formData.append("kelas", this.kelasFilter);
            formData.append("jalur", this.jalurFilter);
            formData.append("query", this.query);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        this.prodiList = response.data.prodi;
                        this.tahunAjaranList = response.data.tahun_ajaran;
                        this.jalurList = response.data.jalur;
                        this.semesterList = response.data.semester;
                        this.stambukList = response.data.stambuk;
                        this.kelasList = response.data.kelas;

                        if (response.data.data != undefined) {
                            this.records = response.data.data[1];

                            this.totalPages = response.data.data[0][0].number_page;
                            this.previousLastId = response.data.data[1][0].id;

                            let last_index = Object.keys(response.data.data[1]).length - 1;
                            this.NextLastId = response.data.data[1][last_index].id;

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    AlertPopup("error", "", "mohon maaf terjadi kesalahan silahkan coba lagi", 1500, false);
                    ErrorConnectionTimeOut(error);
                });

        },

        index_next_page(param1, param2) {


            ShowLoading();
            let formData = new FormData();
            formData.append("last_id", param2);
            formData.append("limit", this.limit);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("jalur", this.jalurFilter);
            formData.append("query", this.query);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {


                        this.records = response.data.data[1];

                        this.totalPages = response.data.data[0][0].number_page;
                        this.previousLastId = response.data.data[1][0].id;

                        let last_index = Object.keys(response.data.data[1]).length - 1;
                        this.NextLastId = response.data.data[1][last_index].id;

                        this.numberPage = this.numberPage + 1;

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }


                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        index_before_page() {


            if (this.numberPage == 1) {
                return true
            }


            ShowLoading();
            let formData = new FormData();
            formData.append("previous_id", this.previousLastId);
            formData.append("last_id", "0");
            formData.append("limit", this.limit);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("jalur", this.jalurFilter);
            formData.append("query", this.query);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.records = response.data.data[1];
                        this.totalPages = response.data.data[0][0].number_page;

                        this.totalPages = response.data.data[0][0].number_page;
                        this.previousLastId = response.data.data[1][0].id;

                        let last_index = Object.keys(response.data.data[1]).length - 1;
                        this.NextLastId = response.data.data[1][last_index].id;

                        this.numberPage = this.numberPage - 1;
                        CloseLoading();

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        insert() {

            ShowLoading();

            const formData = new FormData();
            this.newRecord['prodi_pt'] = 'test';

            Object.entries(this.newRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });
            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_insert";
            } else {
                this.api = base_url + this.url + "_insert";
            }


            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status == true) {

                        this.records.unshift(response.data.data);
                        // Clear the form inputs
                        this.newRecord = {

                        };
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_tambah', 'close_modal_tambah')
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    AlertPopup("error", "", "Mohon maaf terjadi kesalahan", 1500, false);
                    ErrorConnectionTimeOut(error);
                });

            // reset provinsi siswa
            this.provinsi = "";
            this.kota = "";
            this.kelurahan = "";
            this.kecamatan = "";
        },

        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);

            //
            this.setRecordwithId('prodi', this.prodiList, 'nama', 'id');
            this.setRecordwithId('tahun_ajaran', this.tahunAjaranList, 'tahun_ajaran', 'id');

            this.showModal('modal_edit');

            //load provinsi dsb

            // if (this.currentRecord.provinsi != "") {
            //     const index = this.provinsiList.findIndex((record) => record.nama == this.currentRecord.provinsi);
            //     this.provinsi_update = this.currentRecord.provinsi;
            //     this.kabupaten_index(this.provinsiList[index].value, 'siswa', this.currentRecord.kota, this.currentRecord.kecamatan, this.currentRecord.kelurahan);
            // }

            // if (this.currentRecord.provinsi_ortu != "") {
            //     this.provinsi_ortu_update = this.currentRecord.provinsi_ortu;
            //     const index_ortu = this.provinsiList_ortu.findIndex((record) => record.nama == this.currentRecord.provinsi_ortu);
            //     this.kabupaten_index(this.provinsiList[index_ortu].value, 'ortu', this.currentRecord.kota_ortu, this.currentRecord.kecamatan_ortu, this.currentRecord.kelurahan_ortu);
            // }

            // if (this.currentRecord.provinsi_sekolah != "") {
            //     this.provinsi_sekolah_update = this.currentRecord.provinsi_sekolah;
            //     const index_sekolah = this.provinsiList_sekolah.findIndex((record) => record.nama == this.currentRecord.provinsi_sekolah);
            //     this.kabupaten_index(this.provinsiList_sekolah[index_sekolah].value, 'sekolah', this.currentRecord.kota_sekolah);
            // }



        },

        viewRecord(record) {
            this.currentRecord = Object.assign({}, record);
            this.setActiveTab('identitas_diri');
        },
        setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd) {
            console.log(this.tahunAjaranList);
            console.log(this.currentRecord[field]);
            if (this.currentRecord[field] != null) {
                const index = arraylist.findIndex((record) => record[fieldNameArray] == this.currentRecord[field]);
                this.currentRecord[field] = arraylist[index][valueWantAdd];
            } else {
                this.currentRecord[field] = "";
            }
        },

        update() {

            this.currentRecord.provinsi = typeof this.provinsi_update === 'object' ? this.provinsi_update.nama : this.provinsi_update;
            this.currentRecord.kota = typeof this.kota_update === 'object' ? this.kota_update.nama : this.kota_update;
            this.currentRecord.kecamatan = typeof this.kecamatan_update === 'object' ? this.kecamatan_update.nama : this.kecamatan_update;
            this.currentRecord.kelurahan = typeof this.kelurahan_update === 'object' ? this.kelurahan_update.nama : this.kelurahan_update;

            this.currentRecord.provinsi_ortu = typeof this.provinsi_ortu_update === 'object' ? this.provinsi_ortu_update.nama : this.provinsi_ortu_update;
            this.currentRecord.kota_ortu = typeof this.kota_ortu_update === 'object' ? this.kota_ortu_update.nama : this.kota_ortu_update;
            this.currentRecord.kecamatan_ortu = typeof this.kecamatan_ortu_update === 'object' ? this.kecamatan_ortu_update.nama : this.kecamatan_ortu_update;
            this.currentRecord.kelurahan_ortu = typeof this.kelurahan_ortu_update === 'object' ? this.kelurahan_ortu_update.nama_update : this.kelurahan_ortu_update;


            this.currentRecord.provinsi_sekolah = typeof this.provinsi_sekolah_update === 'object'
                ? this.provinsi_sekolah_update.nama
                : this.provinsi_sekolah_update;

            this.currentRecord.kota_sekolah = typeof this.kota_sekolah_update === 'object'
                ? this.kota_sekolah_update.nama
                : this.kota_sekolah_update;



            console.log(this.currentRecord.provinsi);
            console.log(this.currentRecord.kota);

            ShowLoading();
            const formData = new FormData();

            Object.entries(this.currentRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });
            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_update";
            } else {
                this.api = base_url + this.url + "_update";
            }


            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.records.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.currentRecord = {}
                        // this.$set(this.records, index, response.data.data);
                        this.records.splice(index, 1, response.data.data);

                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_edit', 'close_modal_edit');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        deleteData() {

            ShowLoading();
            const formData = new FormData();
            formData.append("id", this.temporaryId);
            console.log(this.temporaryId)
            formData.append("url", "ra_rekomendasi_delete");
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_delete";
            } else {
                this.api = base_url + this.url + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)

                .then((response) => {
                    console.log(response);
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.records.findIndex((record) => record.id === this.temporaryId);
                        this.records.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_delete', 'close_modal_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },


        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        deleteRecord(id) {

            this.temporaryId = id;
            console.log(this.temporaryId)
            this.showModal('modal_delete');
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        },
        showModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')

            setTimeout(() => {
                this.$refs[modal_name].classList.remove('show');
                this.$refs[modal_name].style.display = 'none';
            }, 150)
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },

        setActiveTab(id) {
            const activeElements = document.querySelectorAll(".active");

            // Remove "active" class from all elements
            activeElements.forEach(element => {
                element.classList.remove("active");
            });

            const myElement = this.$refs[id];
            myElement.classList.add("active");
            this.pageActive = id;

        },
        nextPhoto() {
            this.posisi_foto += 1;

            if (this.posisi_foto == 1) {
                this.source_foto = this.currentRecord.foto_cover_rapor;
                this.nama_foto = "Foto Cover Rapor";
            }

            if (this.posisi_foto == 2) {
                this.source_foto = this.currentRecord.foto_kartu_keluarga;
                this.nama_foto = "Foto Kartu Keluarga";
            }

            if (this.posisi_foto == 3) {
                this.source_foto = this.currentRecord.foto_data_diri_rapor;
                this.nama_foto = "Foto Data Diri Rapor";
            }

            if (this.posisi_foto == 4) {
                this.source_foto = this.currentRecord.foto_rapor_kelas_xii_ganjil_genap;
                this.nama_foto = "Foto Rapor Kelas 12 Ganjil / Genap"

            }
            if (this.posisi_foto == 5) {
                this.source_foto = this.currentRecord.foto_skl_skhun;
                this.nama_foto = "SKL / SKHUN";

            }
            if (this.posisi_foto == 6) {
                this.source_foto = this.currentRecord.foto_ijazah;
                this.nama_foto = "Foto Ijazah";

            }
            if (this.posisi_foto == 7) {
                this.source_foto = this.currentRecord.foto_jas;
                this.nama_foto = "Foto Almameter";

            }

        },
        beforePhoto() {
            this.posisi_foto -= 1;

            if (this.posisi_foto == 1) {
                this.source_foto = this.currentRecord.foto_cover_rapor;
                this.nama_foto = "Foto Cover Rapor";
            }

            if (this.posisi_foto == 2) {
                this.source_foto = this.currentRecord.foto_kartu_keluarga;
                this.nama_foto = "Foto Kartu Keluarga";
            }

            if (this.posisi_foto == 3) {
                this.source_foto = this.currentRecord.foto_data_diri_rapor;
                this.nama_foto = "Foto Data Diri Rapor";
            }
            if (this.posisi_foto == 4) {
                this.source_foto = this.currentRecord.foto_rapor_kelas_xii_ganjil_genap;
                this.nama_foto = "Foto Rapor Kelas 12 Ganjil / Genap"

            }
            if (this.posisi_foto == 5) {
                this.source_foto = this.currentRecord.foto_skl_skhun;
                this.nama_foto = "SKL / SKHUN";

            }
            if (this.posisi_foto == 6) {
                this.source_foto = this.currentRecord.foto_ijazah;
                this.nama_foto = "Foto Ijazah";

            }
            if (this.posisi_foto == 7) {
                this.source_foto = this.currentRecord.foto_jas;
                this.nama_foto = "Foto Almameter";

            }


        },

    },

    mounted() {
        this.wargaNegaraList = wargaNegaraList;
        this.statusAkademikList = statusAkademikList;


        const dateNow = new Date();
        const year15 = new Date(dateNow.getFullYear() - 17, dateNow.getMonth(), dateNow.getDate());
        const year45 = new Date(dateNow.getFullYear() - 45, dateNow.getMonth(), dateNow.getDate());

        this.tanggal_minimum = year45.toISOString().slice(0, 10);
        this.newRecord.tanggal_lahir = year15.toISOString().slice(0, 10);
        this.tanggal_maksimum = year15.toISOString().slice(0, 10);



        //provinsi
        this.provinsi_index();

        //set v-select
        this.$nextTick(() => {
            const dropdownToggle = document.querySelectorAll('.vs__dropdown-toggle');
            const searchInput = document.querySelectorAll('.vs__search');
            const focusedSearchInput = document.querySelectorAll('.vs__search:focus');
            const selectedOption = document.querySelectorAll('.vs__selected');

            dropdownToggle.forEach((toggle) => {
                toggle.style.border = 'none';
            });

            searchInput.forEach((input) => {
                input.style.fontSize = 'inherit';
                input.style.padding = '0';
            });

            focusedSearchInput.forEach((input) => {
                input.style.fontSize = 'inherit';
                input.style.padding = '0';
            });

            selectedOption.forEach((option) => {
                option.style.margin = '4px 0 0';
                option.style.padding = '0';
            });


        });

    },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
        //akses
        this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');
        this.delete_akses = menu_akses_cek(this.url, 'delete');

        this.index();

    }



}
</script>