<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar "
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="sticky-top bg-white">
                <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                    Jadwal Mengawas
                </div>
                <div class="row mt-2 mb-2 me-0  bg-white">
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                            id="tahuna_ajaran" v-model="tahunAjaranFilter" @change="pageActive = 1; offset = 0; index();">
                            <option value="" selected disabled>- Pilih Tahun ajaran</option>
                            <option v-for=" list  in  tahunAjaranList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="pageActive = 1; offset = 0; index();" required>
                            <option value="" selected disabled>- Pilih prodi</option>
                            <option v-for=" list  in  prodiList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="kurikulum" id="kurikulum"
                            v-model="kurikulumFilter" @change="pageActive = 1; offset = 0; index();" required
                            :disabled="prodiFilter == ''">
                            <option value="" selected disabled>- Pilih kurikulum
                            </option>
                            <option v-for="list in kurikulumList" :key="list.id" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="pageActive = 1; offset = 0; index()" required
                            :disabled="kurikulumFilter == ''">
                            <option value="" selected>- Pilih Semester</option>
                            <option v-for=" list  in  semesterList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <!-- <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="hariFilter" @change="pageActive = 1; offset = 0; index()" required
                            :disabled="semesterFilter == ''">
                            <option value="" selected>- Pilih Hari</option>
                            <option v-for=" list  in  hariJadwalList " :key="list.nama" :value="list.nama">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div> -->
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="tipeFilter" @change="pageActive = 1; offset = 0; index()" required
                            :disabled="semesterFilter == ''">
                            <option value="" selected disabled>- Pilih Tipe</option>
                            <!-- <option value="semua">Semua</option> -->
                            <option value="uts">UTS</option>
                            <option value="uas">UAS</option>
                            <option value="susulan_uts">Susulan (UTS)</option>
                            <option value="susulan_uas">Susulan (UAS)</option>
                        </select>
                    </div>
                    <!-- <div class="col-2 text-end ">
                        <button v-if="create_akses == true" class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                            style="border-radius: 5px;" data-bs-toggle="modal" data-bs-target="#modal_tambah"
                            @click="showModal('modal_tambah'); newRecord.jam_mulai = HourNow()">
                            <img src="@/assets/icon/data_plus.png" alt="" width="15">
                            Tambah Jadwal Ujian
                        </button>

                    </div> -->

                    <div class="d-flex align-items-start justify-content-start mt-2 gap-2" v-if="paginglist.length != 0">
                        <div>
                            <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                                v-model="limit" @change="pageActive = 1; offset = 0; index()" required>
                                <option v-for=" list  in  limitList " :key="list.value" :value="list.value" @change=index()>
                                    {{ list.value }}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center  align-items-center">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination" ref="pagi">
                                    <li class="page-item">
                                        <span class="page-link"
                                            @click="if (pageActive != 1) { pageActive--; offset = offset - limit; index('previous') };"
                                            aria-label="Previous" style="cursor:pointer;">
                                            <span aria-hidden="true">&laquo;</span>
                                        </span>
                                    </li>
                                    <li v-for=" list  in  paginglist " :key="list.nama" :value="list.value"
                                        class="page-item"
                                        :class="{ 'active': list.nama == pageActive, 'd-none': list.show == false }"><span
                                            class="page-link text-black "
                                            @click="if (list.disabled != true) pageActive = list.nama; offset = list.value; index()"
                                            style="cursor: pointer;">{{
                                                        list.nama }}</span></li>
                                    <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                                    <li class="page-item">
                                        <span class="page-link"
                                            @click="if (pageActive != totalPages) { pageActive++; offset = offset + limit; index('next') };"
                                            aria-label="Next" style="cursor:pointer;">
                                            <span aria-hidden="true">&raquo;</span>
                                        </span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="">
                            <input type="text" v-model="query" @change="pageActive = 1; offset = 0; index()"
                                class="form-control" placeholder="Cari dosen pengajar, mata kuliah, kelas ">
                        </div>
                    </div>





                </div>
            </div>

            <div class="d-flex flex-column gap-2  ">
                <!-- <div class="fs-8 fw-bold font-custom-2 font-gray-custom">
                        >Desktop/Pengguna
                    </div> -->
                <div class="border  rounded-3 me-2 font-custom-1 mb-5 "
                    style="overflow-x:scroll; z-index: 0;  overflow-y: scroll; max-height: 65vh!important; ">
                    <table class="table mb-0 bg-transparent table-borderless" style="">
                        <thead class="sticky-top " style="top: 0px;">
                            <tr>
                                <th scope="col" class="fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7">Tanggal</th>
                                <th scope="col" class="font-custom-1 fs-7"
                                    v-if="tipeFilter != 'susulan_uts' && tipeFilter != 'susulan_uas'">Kelas</th>
                                <th scope="col" class="font-custom-1 fs-7">Kurikulum</th>
                                <th scope="col" class="font-custom-1 fs-7">Mata kuliah</th>
                                <th scope="col" class="font-custom-1 fs-7">Jam</th>
                                <th scope="col" class="font-custom-1 fs-7">Ruangan</th>
                                <th scope="col" class="font-custom-1 fs-7">Tipe </th>
                                <th scope="col" class="font-custom-1 fs-7">Jenis Ujian</th>
                                <th scope="col" class="font-custom-1 fs-7">Dosen Pengawas</th>
                                <th scope="col" class="font-custom-1 fs-7">Dosen Pengawas 2</th>
                                <th scope="col" class="font-custom-1 fs-7">Tahun Ajaran</th>
                                <!-- <th scope="col" class=" fs-7">Aksi</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="( record, index ) in  records " :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ DateFormat(record.tanggal) }}</td>
                                <td v-if="tipeFilter != 'susulan_uts' && tipeFilter != 'susulan_uas'">{{ record.kelas }}
                                </td>
                                <td>{{ record.kurikulum }}</td>
                                <td>{{ record.mata_kuliah }}</td>
                                <td>{{ record.jam_mulai }} - <br>{{ record.jam_selesai }}</td>
                                <td>{{ record.ruangan }}</td>
                                <td><span v-if="record.tipe == 'uts'">
                                        UTS
                                    </span>
                                    <span v-if="record.tipe == 'uas'">
                                        UAS
                                    </span>
                                    <span v-if="record.tipe == 'susulan_uts'">
                                        Susulan (UTS)
                                    </span>
                                    <span v-if="record.tipe == 'susulan_uas'">
                                        Susulan (UAS)
                                    </span>
                                </td>
                                <td>{{ record.tipe_ujian }}</td>
                                <td>{{ record.dosen_pengawas }}</td>
                                <td>{{ record.dosen_pengawas_2 }}</td>
                                <td>{{ record.tahun_ajaran }}</td>
                                <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <!-- <button class="btn btn-primary btn-sm fs-8" v-on:click="editRecord(record)"
                                            data-bs-toggle="modal" data-bs-target="#modal_berita_acara"><i
                                                class='bx bxs-file fs-8'></i></button> -->
                                        <!-- <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                            @click="editRecord(record);" data-bs-toggle="modal"
                                            data-bs-target="#modal_edit"><i class='bx bx-list-ul fs-8'></i></button>
                                        <button v-if="delete_akses == true" class="btn btn-danger btn-sm fs-8"
                                            data-bs-toggle="modal" data-bs-target="#modal_delete"
                                            v-on:click="deleteRecord(record.id)"><i
                                                class='bx bxs-trash-alt fs-8'></i></button> -->
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <form @submit.prevent="insert()">
        <div class="modal fade" id="modal_tambah" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
            aria-hidden="true" ref="modal_tambah">
            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah data jadwal Ujian</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_tambah"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_tambah', 'close_modal_tambah')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="tahun_ajaran" class="form-label">Tahun ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahuna_ajaran" v-model="tahunAjaranFilter"
                                @change="pageActive = 1; offset = 0; index();">
                                <option value="" selected disabled>- Pilih Tahun ajaran</option>
                                <option v-for=" list  in  tahunAjaranList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="prodiFilter" @change="pageActive = 1; offset = 0; index();" required
                                :disabled="tahunAjaranFilter == ''">
                                <option value="" selected disabled>- Pilih Prodi
                                </option>
                                <option v-for="list in prodiList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="kurikulum" class="form-label">Kurikulum</label>
                            <select class="form-select" aria-label="Default select example" name="kurikulum" id="kurikulum"
                                v-model="kurikulumFilter" @change="pageActive = 1; offset = 0; index();" required
                                :disabled="tahunAjaranFilter == ''">
                                <option value="" selected disabled>- Pilih kurikulum
                                </option>
                                <option v-for="list in kurikulumList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Kelas</label>
                            <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                                v-model="newRecord.kelas" :disabled="prodiFilter == ''" required>
                                <option value="" selected disabled>- Pilih kelas</option>
                                <option v-for="list in kelasList" :key="list.nama" :value="list.id">
                                    {{ list.nama }} ({{ list.waktu_kuliah }})
                                </option>
                            </select>
                        </div>
                        <!-- <div class="mb-3">
                            <label for="dosen" class="form-label">Pilih Tahun ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahun_ajaran" @change="index(newRecord.prodi, newRecord.tahun_ajaran)"
                                v-model="newRecord.tahun_ajaran" required>
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for="list in tahunAjaranList" :key="list.id" :value="list.id">
                                    {{ list.tahun_ajaran }}
                                </option>
                            </select>
                        </div> -->

                        <!-- <div class="mb-3">
                            <label for="dosen" class="form-label">Pilih Semester</label>
                            <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                                v-model="newRecord.semester"
                                @change="index(newRecord.prodi, newRecord.tahun_ajaran, newRecord.kurikulum, newRecord.semester)"
                                :disabled="newRecord.kurikulum == ''" required>
                                <option value="" selected disabled>- Pilih Semester</option>
                                <option v-for="list in semesterList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Semester</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="semesterFilter" @change="pageActive = 1; offset = 0; index();"
                                :disabled="prodiFilter == ''" required>
                                <option value="" selected disabled>- Pilih semester</option>
                                <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="dosen" class="form-label">Jam Mulai</label>
                            <input type="time" class="form-control" v-model="newRecord.jam_mulai" placeholder="09:00"
                                required />
                            <!-- <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                id="mata_kuliah" v-model="newRecord.jam" required>
                                <option value="" selected disabled>- Pilih Jam Kuliah</option>
                                <option v-for="list in jamList" :key="list.id" :value="list.id">
                                    {{ list.kode }} ({{ list.jam_mulai }}-{{ list.jam_selesai }})
                                </option>
                            </select> -->
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Jam Selesai</label>
                            <input type="time" class="form-control" v-model="newRecord.jam_selesai" placeholder="09:00"
                                required />
                            <!-- <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                id="mata_kuliah" v-model="newRecord.jam" required>
                                <option value="" selected disabled>- Pilih Jam Kuliah</option>
                                <option v-for="list in jamList" :key="list.id" :value="list.id">
                                    {{ list.kode }} ({{ list.jam_mulai }}-{{ list.jam_selesai }})
                                </option>
                            </select> -->
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">mata Kuliah </label>
                            <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                id="mata_kuliah" v-model="newRecord.mata_kuliah" required>
                                <option value="" selected disabled>- Pilih Mata Kuliah</option>
                                <option v-for="list in mataKuliahList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Tipe </label>
                            <select class="form-select" aria-label="Default select example" name="tipe" id="tipe"
                                v-model="newRecord.tipe" required :disabled="newRecord.mata_kuliah == ''">
                                <option value="" selected disabled>- Pilih Tipe Ujan</option>
                                <option value="UTS">UTS</option>
                                <option value="UAS">UAS</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="hari" class="form-label">Hari</label>
                            <select class="form-select" aria-label="Default select example" name="hari" id="hari"
                                v-model="newRecord.hari" required>
                                <option value="" selected disabled>- Pilih hari</option>
                                <option v-for="list in hariList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Dosen Pengawas</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="newRecord.dosen_pengawas" required>
                                <option value="" selected disabled>- Pilih dosen</option>
                                <option v-for="list in dosenList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Ruangan</label>
                            <select class="form-select" aria-label="Default select example" name="ruangan" id="ruangan"
                                v-model="newRecord.ruangan" required>
                                <option value="" selected disabled>- Pilih ruangan</option>
                                <option v-for="list in ruanganList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_tambah', 'close_modal_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <form @submit.prevent="update">
        <div class="modal fade" id="modal_edit" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
            aria-hidden="true" ref="modal_edit">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Edit data jadwal kuliah</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_edit"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_edit', 'close_modal_edit')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="tahun_ajaran" class="form-label">Tahun ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahuna_ajaran" v-model="currentRecord.id_tahun_ajaran"
                                @change="pageActive = 1; offset = 0; index();">
                                <option value="" selected disabled>- Pilih Tahun ajaran</option>
                                <option v-for=" list  in  tahunAjaranList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="currentRecord.id_prodi" @change="pageActive = 1; offset = 0; index();" required
                                :disabled="tahunAjaranFilter == ''">
                                <option value="" selected disabled>- Pilih Prodi
                                </option>
                                <option v-for="list in prodiList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="kurikulum" class="form-label">Kurikulum</label>
                            <select class="form-select" aria-label="Default select example" name="kurikulum" id="kurikulum"
                                v-model="currentRecord.id_kurikulum" @change="pageActive = 1; offset = 0; index();" required
                                :disabled="tahunAjaranFilter == ''">
                                <option value="" selected disabled>- Pilih kurikulum
                                </option>
                                <option v-for="list in kurikulumList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Kelas</label>
                            <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                                v-model="currentRecord.id_kelas" :disabled="prodiFilter == ''" required>
                                <option value="" selected disabled>- Pilih kelas</option>
                                <option v-for="list in kelasList" :key="list.nama" :value="list.id">
                                    {{ list.nama }} ({{ list.waktu_kuliah }})
                                </option>
                            </select>
                        </div>
                        <!-- <div class="mb-3">
                            <label for="dosen" class="form-label">Pilih Tahun ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahun_ajaran" @change="index(newRecord.prodi, newRecord.tahun_ajaran)"
                                v-model="newRecord.tahun_ajaran" required>
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for="list in tahunAjaranList" :key="list.id" :value="list.id">
                                    {{ list.tahun_ajaran }}
                                </option>
                            </select>
                        </div> -->

                        <!-- <div class="mb-3">
                            <label for="dosen" class="form-label">Pilih Semester</label>
                            <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                                v-model="newRecord.semester"
                                @change="index(newRecord.prodi, newRecord.tahun_ajaran, newRecord.kurikulum, newRecord.semester)"
                                :disabled="newRecord.kurikulum == ''" required>
                                <option value="" selected disabled>- Pilih Semester</option>
                                <option v-for="list in semesterList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Semester</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="currentRecord.id_semester" @change="pageActive = 1; offset = 0; index();"
                                :disabled="prodiFilter == ''" required>
                                <option value="" selected disabled>- Pilih semester</option>
                                <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="dosen" class="form-label">Jam Mulai</label>
                            <input type="time" class="form-control" v-model="currentRecord.jam_mulai" placeholder="09:00"
                                required />
                            <!-- <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                id="mata_kuliah" v-model="newRecord.jam" required>
                                <option value="" selected disabled>- Pilih Jam Kuliah</option>
                                <option v-for="list in jamList" :key="list.id" :value="list.id">
                                    {{ list.kode }} ({{ list.jam_mulai }}-{{ list.jam_selesai }})
                                </option>
                            </select> -->
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Jam Selesai</label>
                            <input type="time" class="form-control" v-model="currentRecord.jam_selesai" placeholder="09:00"
                                required />
                            <!-- <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                id="mata_kuliah" v-model="newRecord.jam" required>
                                <option value="" selected disabled>- Pilih Jam Kuliah</option>
                                <option v-for="list in jamList" :key="list.id" :value="list.id">
                                    {{ list.kode }} ({{ list.jam_mulai }}-{{ list.jam_selesai }})
                                </option>
                            </select> -->
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">mata Kuliah </label>
                            <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                id="mata_kuliah" v-model="currentRecord.id_mata_kuliah" required>
                                <option value="" selected disabled>- Pilih Mata Kuliah</option>
                                <option v-for="list in mataKuliahList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Tipe </label>
                            <select class="form-select" aria-label="Default select example" name="tipe" id="tipe"
                                v-model="currentRecord.tipe" required :disabled="newRecord.mata_kuliah == ''">
                                <option value="" selected disabled>- Pilih Tipe Ujan</option>
                                <option value="UTS">UTS</option>
                                <option value="UAS">UAS</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="hari" class="form-label">Hari</label>
                            <select class="form-select" aria-label="Default select example" name="hari" id="hari"
                                v-model="currentRecord.hari" required>
                                <option value="" selected disabled>- Pilih hari</option>
                                <option v-for="list in hariList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Dosen Pengawas</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="currentRecord.id_dosen_pengawas" required>
                                <option value="" selected disabled>- Pilih dosen</option>
                                <option v-for="list in dosenList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Ruangan</label>
                            <select class="form-select" aria-label="Default select example" name="ruangan" id="ruangan"
                                v-model="currentRecord.id_ruangan" required>
                                <option value="" selected disabled>- Pilih ruangan</option>
                                <option v-for="list in ruanganList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_edit', 'close_modal_edit')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_delete', 'close_modal_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_delete', 'close_modal_delete')">Tidak, tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.pagination {
    --bs-pagination-color: black !important;
}

.active>.page-link,
.page-link.active {
    background-color: #0D9044 !important;
    border-color: #0D9044 !important;
    color: white !important;
}


.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}

::-webkit-scrollbar {
    width: 0.6rem;
    border-radius: 0.5rem;
    background-color: rgba(63, 63, 63, 0.656);
    height: 0.8rem;

}

::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.473);
    border-radius: 0.5rem;
    width: 0.3rem;
}

::-webkit-scrollbar-thumb:hover {
    width: 0.3rem;
    background-color: rgba(255, 255, 255, 0.797);
}

.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import {
    AlertPopup, hariList, base_url, DateFormat, CloseLoading, ShowLoading, menu_akses_cek, ErrorConnectionTimeOut,
    //  AlertBottom,
    CharAndNumberOnly, DateNow, HourNow
} from "@/assets/javascript/function";

import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";

export default {
    data() {
        return {
            //data
            url: 'jadwal_ujian',
            url2: 'jadwal_mengawas',
            api: "",

            //pages
            limit: 5,
            offset: 0,
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,
            currentPageActive: 1,
            pageActive: 1,

            prodiList: [],
            waktuKuliahList: [],
            dosenWaliList: [],
            semesterList: [],
            stambukList: [],
            tahunAjaranList: [],
            hariJadwalList: [],
            mataKuliahList: [],
            kurikulumList: [],
            dosenList: [],
            ruanganList: [],

            limitList: [{
                value: 5
            }, {
                value: 10
            }, {
                value: 25
            }
            ],

            prodiFilter: "",
            semesterFilter: "",
            tahunAjaranFilter: "",
            hariFilter: "",
            kurikulumFilter: "",
            tipeFilter: "",
            query: "",


            PertemuanList: [
                {
                    nama: 1,
                },
                {
                    nama: 2,
                },
                {
                    nama: 3,
                },
                {
                    nama: 4,
                },
                {
                    nama: 5,
                },
                {
                    nama: 6,
                },
                {
                    nama: 7,
                },
                {
                    nama: 8,
                },
                {
                    nama: 9,
                },
                {
                    nama: 10,
                },
                {
                    nama: 11,
                },
                {
                    nama: 12,
                },
                {
                    nama: 13,
                },
                {
                    nama: 14,
                },
                {
                    nama: 15,
                },
                {
                    nama: 16,
                },
            ],
            JenisPertemuanList: [
                {
                    nama: 'luring',
                },
                {
                    nama: 'daring',
                },
            ],

            //data
            records: [],
            newRecord: {},
            currentRecordBeritaAcara: { pertemuan: "", jenis_pertemuan: "" },
            beritaAcaraRecords: [],
            currentRecord: [],
            mahasiswaRecords: [],
            attendanceRecords: [],
            penggantiList: [],
            paginglist: [],
            isLoad: 0,

            //modal berita acara
            mata_kuliah_dipilih: "",
            id_jadwal_dipilih: "",
            id_dosen_dipilih: "",
            id_profiles_dipilih: "",
            batas_page_awal: 0,
            batas_page_akhir: 10,

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            temporaryStatusDosen: "",

            finalisasi: "",
            setujui: "",
            tipe: "",

            //
            kelasList: "",
        }
    },
    components: {
        SideNavbar,
    },
    computed: {
        returnValueStatusDosen() {
            return this.currentRecordBeritaAcara.status_dosen ? 'TIDAK' : 'HADIR';
        }
    },

    methods: {
        capitalizeWords(str) {
            return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        },
        CharAndNumberOnly,
        DateFormat,
        DateNow,
        HourNow,
        setPertemuan(pertemuan) {
            if (pertemuan == 0) {
                return "";
            } else {
                return pertemuan;
            }
        },


        async index(status) {

            this.paginglist = [];
            ShowLoading();
            this.records = [];


            let formData = new FormData();

            formData.append("limit", this.limit);
            formData.append("offset", this.offset);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("semester", this.semesterFilter);
            formData.append("hari", this.hariFilter);
            formData.append("query", this.query);
            formData.append("kurikulum", this.kurikulumFilter);
            formData.append("tipe", this.tipeFilter);


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.prodiList = response.data.prodi;
                        this.semesterList = response.data.semester;
                        this.tahunAjaranList = response.data.tahun_ajaran;
                        this.kelasList = response.data.kelas;
                        this.mataKuliahList = response.data.mata_kuliah;
                        this.kurikulumList = response.data.kurikulum;
                        this.dosenList = response.data.dosen;
                        this.ruanganList = response.data.ruangan;


                        if (response.data.data != undefined && this.tipeFilter != "") {
                            this.records = response.data.data[1];
                            this.totalPages = response.data.data[0][0].number_page;
                            // let object_2 = [];
                            let limit = this.limit;

                            this.pageActive;
                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limit;
                                }

                                if (i <= this.batas_page_akhir && i > this.batas_page_awal) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);
                            }


                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhir] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActive - 1].key) {

                                    page_list_array[this.batas_page_awal].show = false;
                                    page_list_array[this.batas_page_akhir].show = true;

                                    this.batas_page_awal++;
                                    this.batas_page_akhir++;

                                } else if (this.pageActive != 1) {

                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActive - 1].key) {
                                        this.batas_page_awal--;
                                        this.batas_page_akhir--;

                                        page_list_array[this.batas_page_awal].show = true;
                                        page_list_array[this.batas_page_akhir].show = false;

                                    }


                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActive - 1].key) && page_list_array[this.batas_page_akhir] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActive != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    this.paginglist.push(page_list_array[i]);
                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPages,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }
                                // console.log(page_list_array[index_last_true]);
                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglist.push(pemisahPages);
                                    this.paginglist.push(totalPages);
                                }

                            }


                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            console.log(this.currentRecord);

            // this.setRecordwithId('tahun_ajaran', this.tahunAjaranList, 'nama', 'id');
            // this.setRecordwithId('dosen_pengawas', this.dosenList, 'nama', 'id');
            // this.setRecordwithId('prodi', this.prodiList, 'nama', 'id');
            // this.setRecordwithId('kurikulum', this.kurikulumList, 'nama', 'id');
            // this.setRecordwithId('kelas', this.kelasList, 'nama', 'id');

            // this.setRecordwithId('mata_kuliah', this.mataKuliahList, 'nama', 'id');
            // this.setRecordwithId('ruangan', this.ruanganList, 'nama', 'id');





            // this.setRecordwithId('semester', this.semesterList, 'nama', 'id');
            // const index = this.semesterList.findIndex((record) => record.id == this.currentRecord.tipe_semester_id);

            // this.currentRecord['semester'] = this.semesterList[index].id;
            // this.selectedSemester = { id: this.semesterList[index].id, id_tahun_ajaran: this.semesterList[index].id_tahun_ajaran };


            // this.currentRecord.jumlah_dana = this.rupiahInput(this.currentRecord.jumlah_dana);
            this.showModal('modal_edit');

        },
        setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd) {
            console.log(this.currentRecord[field]);
            console.log(arraylist);
            console.log(this.currentRecord[field] == arraylist[0])

            if (this.currentRecord[field] != null) {
                const index = arraylist.findIndex((record) => record[fieldNameArray] == this.currentRecord[field]);
                console.log(index);
                this.currentRecord[field] = arraylist[index][valueWantAdd];
            } else {
                this.currentRecord[field] = "";
            }
        },
        // deleteData() {
        //     ShowLoading();
        //     const formData = new FormData();
        //     //get kehadiran siswa
        //     this.attendanceRecords = delete this.mahasiswaRecords.nama_lengkap;
        //     this.attendanceRecords = delete this.mahasiswaRecords.nim_mahasiswa;
        //     this.attendanceRecords = this.mahasiswaRecords.map(employee => {
        //         const { ...record } = employee;
        //         delete record.nama_lengkap;
        //         delete record.nim_mahasiswa;
        //         return record;
        //     });

        //     formData.append("id", this.temporaryId);
        //     formData.append("status_dosen", this.temporaryStatusDosen);
        //     formData.append("id_jadwal", this.id_jadwal_dipilih);
        //     formData.append('kehadiran_siswa', JSON.stringify(this.attendanceRecords));

        //     if (process.env.NODE_ENV === "development") {
        //         this.api = base_url + this.url + "_delete";
        //     } else {
        //         this.api = base_url + this.url + "_delete";
        //     }
        //     // Delete a record from the API
        //     axios
        //         .post(this.api, formData, this.config)
        //         .then((response) => {
        //             if (response.data.status == true) {
        //                 // Remove the record from the records list
        //                 const index = this.records.findIndex((record) => record.id === this.temporaryId);
        //                 this.records.splice(index, 1);
        //                 CloseLoading();
        //                 AlertBottom(response.data.message);
        //                 this.hideModal('modal_delete', 'close_modal_delete');
        //             } else {
        //                 AlertPopup("error", "", response.data.message, 1500, false);
        //             }
        //         })
        //         .catch((error) => {
        //             ErrorConnectionTimeOut(error);
        //         });

        // },
        // insert() {

        //     ShowLoading();
        //     const formData = new FormData();


        //     Object.entries(this.newRecord).forEach(([key, value]) => {
        //         formData.append(key, value);
        //     });
        //     formData.append('tahun_ajaran', this.tahunAjaranFilter);
        //     formData.append('semester', this.semesterFilter);
        //     formData.append('kurikulum', this.kurikulumFilter);
        //     formData.append('prodi', this.prodiFilter);

        //     // for (let [key, value] of formData.entries()) {
        //     //     console.log(`${key}: ${value}`);
        //     // }

        //     if (process.env.NODE_ENV === "development") {
        //         this.api = base_url + this.url + "_insert";
        //     } else {
        //         this.api = base_url + this.url + "_insert";
        //     }

        //     // Add a new record to the API
        //     axios
        //         .post(this.api, formData, this.config)
        //         .then((response) => {
        //             if (response.data.status == true) {

        //                 this.records.unshift(response.data.data);

        //                 // Clear the form inputs
        //                 this.newRecord = {
        //                 };
        //                 CloseLoading();
        //                 AlertBottom(response.data.message);
        //                 this.hideModal('modal_tambah', 'close_modal_tambah')
        //             }
        //             if (response.data.status == false) {
        //                 AlertPopup("error", "", response.data.message, 1500, false);
        //             }
        //         })
        //         .catch((error) => {
        //             ErrorConnectionTimeOut(error);
        //         });
        // },
        // update() {

        //     ShowLoading();
        //     const formData = new FormData();


        //     Object.entries(this.currentRecord).forEach(([key, value]) => {
        //         // const arrayCurrentRecord = ["tahun_ajaran", "dosen_pengawas", "mata_kuliah", "prodi", "kurikulum", "kelas", "ruangan", "semester"]
        //         // let check = false;
        //         // for (let element of arrayCurrentRecord) {
        //         //     if (key.indexOf(element) !== -1) {

        //         //         check = true;
        //         //         break;
        //         //     }
        //         // }
        //         // if (check == true) {
        //         //     formData.append(key.substring(3), value);
        //         // } else {
        //         // formData.append(key, value);
        //         // }
        //         formData.append(key, value);
        //     });

        //     for (let [key, value] of formData.entries()) {
        //         console.log(`${key}: ${value}`);
        //     }


        //     if (process.env.NODE_ENV === "development") {
        //         this.api = base_url + this.url + "_update";
        //     } else {
        //         this.api = base_url + this.url + "_update";
        //     }

        //     // Add a new record to the API
        //     axios
        //         .post(this.api, formData, this.config)
        //         .then((response) => {

        //             if (response.data.status == true) {
        //                 const index = this.records.findIndex(
        //                     (record) => record.id == response.data.data.id
        //                 );
        //                 this.currentRecord = {}
        //                 // this.$set(this.records, index, response.data.data);
        //                 this.records.splice(index, 1, response.data.data);

        //                 CloseLoading();
        //                 AlertBottom(response.data.message);
        //                 this.hideModal('modal_edit', 'close_modal_edit');
        //             }
        //             if (response.data.status == false) {
        //                 AlertPopup("error", "", response.data.message, 1500, false);
        //             }
        //         })
        //         .catch((error) => {
        //             ErrorConnectionTimeOut(error);
        //         });
        // },

        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },

        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        showModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';


            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')

            setTimeout(() => {
                this.$refs[modal_name].classList.remove('show');
                this.$refs[modal_name].style.display = 'none';
            }, 150)
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        deleteRecord(id, status_dosen) {

            this.temporaryId = id;
            this.temporaryStatusDosen = status_dosen;
        },

        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        }
    },

    // mounted() {

    // },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
        //akses
        this.create_akses = menu_akses_cek(this.url2, 'create');
        this.update_akses = menu_akses_cek(this.url2, 'update');
        this.delete_akses = menu_akses_cek(this.url2, 'delete');

        this.index();
        this.hariJadwalList = hariList;
        this.hariList = hariList;
        this.tipe = localStorage.getItem('tipe_user');

    }



}
</script>